import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  height = '100%',
  className = 'is-arrow',
  viewBox = '0 0 24 24'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path fill={fill} d='M23.857,12.4844748 C23.874,12.4544748 23.9,12.4314748 23.914,12.4004748 C23.967,12.2774748 24,12.1434748 24,12.0004748 C24,11.8584748 23.967,11.7234748 23.914,11.6004748 C23.9,11.5694748 23.874,11.5464748 23.857,11.5164748 C23.807,11.4284748 23.753,11.3444748 23.68,11.2754748 C23.673,11.2694748 23.67,11.2594748 23.664,11.2534748 L14.664,3.25347477 C14.251,2.88547477 13.619,2.92247477 13.253,3.33647477 C12.885,3.74947477 12.922,4.38047477 13.336,4.74847477 L20.37,11.0004748 L1,11.0004748 C0.447,11.0004748 0,11.4484748 0,12.0004748 C0,12.5524748 0.447,13.0004748 1,13.0004748 L20.37,13.0004748 L13.336,19.2534748 C12.922,19.6194748 12.885,20.2524748 13.253,20.6644748 C13.45,20.8874748 13.724,21.0004748 14,21.0004748 C14.236,21.0004748 14.473,20.9174748 14.664,20.7474748 L23.664,12.7484748 C23.67,12.7414748 23.673,12.7324748 23.68,12.7254748 C23.753,12.6564748 23.807,12.5724748 23.857,12.4844748' />
  </svg>
)

export default SVG
