import React from "react";
import { useTranslation } from "react-i18next";

import StickerList from "./StickerList";
import AvatarList from "./AvatarList";

import {
  STICKERS_ID,
  AVATAR_ID
} from "contexts/EventComponentsContext/componentsKeys";

function ComponentShowcase(props) {
  const { components } = props;
  const stickers = components.filter((comp) => comp.develop_id === STICKERS_ID);
  const avatars = components.filter((comp) => comp.develop_id === AVATAR_ID);
  const { t } = useTranslation(["watch"]);

  return (
    <>
      {avatars.map((avatarComp, idx) => {
        return (
          <div className="c-upgrade__block" key={idx}>
            <h4 className="c-upgrade__subtitle">{t("upgrade.exclusive_avatar")}</h4>
            {/*<strong>{avatarComp.name}</strong>*/}
            <AvatarList properties={avatarComp.properties} />
          </div>
        );
      })}
      {stickers.map((stickerComp, idx) => {
        return (
          <div className="c-upgrade__block" key={idx}>
            <h4 className="c-upgrade__subtitle">{t("upgrade.unique_stickers")}</h4>
            {/*<strong>{stickerComp.name}</strong>*/}
            <StickerList properties={stickerComp.properties} />
          </div>
        );
      })}      
    </>
  );
}

export default ComponentShowcase;
