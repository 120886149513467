import React from "react";
import { useTranslation } from 'react-i18next';

import { pollContainerStates } from "consts/pollContainerStates";
import { useAdminPollContext } from "contexts/adminPoll";

import CurrentPoll from "../CurrentPoll";
import EmptyPoll from "../EmptyPoll";
import FormPoll from "../FormPoll";
import AdminPollList from "../AdminPollList";
import Loading from "components/shared/Loading";

function AdminRouterPoll(props) {
  const { t } = useTranslation(['watch']);
  const { adminPollNavigation, setAdminPollNavigation } = useAdminPollContext();

  function handleCreateNavigate(event) {
    event.preventDefault();
    setAdminPollNavigation(pollContainerStates.CREATE);
  }

  return (
    <>
      <div className="c-poll">
        <div className="c-livestream__header">
          <h6>{t("livestream.polls")}</h6>
          {adminPollNavigation === pollContainerStates.LIST && (
            <a
              href="/"
              onClick={handleCreateNavigate}
              className="o-button--sm o-button--green"
            >
              {t("livestream.create_poll")}
            </a>
          )}
        </div>

        <ul className="c-poll__container">
          {adminPollNavigation === pollContainerStates.EMPTY && <EmptyPoll />}

          {adminPollNavigation === pollContainerStates.LIVE && <CurrentPoll />}

          {adminPollNavigation === pollContainerStates.LIST && <AdminPollList />}

          {adminPollNavigation === pollContainerStates.CREATE && <FormPoll />}

          {adminPollNavigation === pollContainerStates.LOADING && (
            <Loading isSmall={true} />
          )}
        </ul>

        {/* HIDE IF FORM IS ACTIVE */}
        {adminPollNavigation === pollContainerStates.LIST && (
          <div className="c-poll__cta">
            <a
              href="/"
              onClick={handleCreateNavigate}
              className="o-button--lg o-button--green"
            >
              {t ("livestream.create_poll")}
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default AdminRouterPoll;
