import React from "react";
import Iframe from "react-iframe";

function InboundNeerme() {
 return (
    <>
      <Iframe url="https://patricia027275.typeform.com/to/l2bWLAdQ"
      width="100%"
      height="100%"
      frameborder="0"
      className="inbound-iframe"
      display="initial"
      position="relative"
      />
    </>
  );
}

export default InboundNeerme