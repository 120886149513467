import {
  CREATE_XSOLLA_TOKEN_REQUESTED,
  CREATE_XSOLLA_TOKEN_FULFILLED,
  CREATE_XSOLLA_TOKEN_REJECTED,
  RESET_XSOLLA_TOKEN,
} from "./types";

import { createXsollaToken as createXsollaTokenAPI } from "api/events";

export function createXsollaToken(data, dispatch) {
  dispatch(createXsollaTokenRequested());
  createXsollaTokenAPI(data)
    .then((response) => {
      const object = response.data;
      dispatch(createXsollaTokenFulfilled(object.token));
    })
    .catch((error) => {
      dispatch(createXsollaTokenRejected(error));
    });
}

function createXsollaTokenRequested() {
  return {
    type: CREATE_XSOLLA_TOKEN_REQUESTED,
    xsollaToken: {
      loading: true,
      success: false,
      error: false,
      data: null,
    },
  };
}
function createXsollaTokenFulfilled(data) {
  return {
    type: CREATE_XSOLLA_TOKEN_FULFILLED,
    xsollaToken: {
      loading: false,
      success: true,
      error: false,
      data,
    },
  };
}
function createXsollaTokenRejected(error) {
  return {
    type: CREATE_XSOLLA_TOKEN_REJECTED,
    xsollaToken: {
      loading: false,
      success: false,
      error,
      data: null,
    },
  };
}
export function resetXsollaToken() {
  return {
    type: RESET_XSOLLA_TOKEN,
  };
}
