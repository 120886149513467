import React from 'react';

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  height = '100%',
  className = 'is-user',
  viewBox = '0 0 24 24'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`c-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

    <path fill={fill} d='M14.5004,13.5 C19.7384,13.5 24.0004,17.762 24.0004,23 C24.0004,23.553 23.5524,24 23.0004,24 L1.0004,24 C0.4474,24 0.0004,23.553 0.0004,23 C0.0004,17.762 4.2614,13.5 9.5004,13.5 L14.5004,13.5 Z M14.5004,15.5 L9.5004,15.5 C5.7034,15.5 2.5564,18.336 2.0664,22 L21.9334,22 C21.4434,18.336 18.2964,15.5 14.5004,15.5 Z M12,0 C15.032,0 17.5,2.467 17.5,5.5 C17.5,8.533 15.032,11 12,11 C8.967,11 6.5,8.533 6.5,5.5 C6.5,2.467 8.967,0 12,0 Z M12,2 C10.07,2 8.5,3.57 8.5,5.5 C8.5,7.43 10.07,9 12,9 C13.93,9 15.5,7.43 15.5,5.5 C15.5,3.57 13.93,2 12,2 Z'/>
  </svg>
)

export default SVG
