import {
  CREATE_XSOLLA_TOKEN_REQUESTED,
  CREATE_XSOLLA_TOKEN_FULFILLED,
  CREATE_XSOLLA_TOKEN_REJECTED,
  RESET_XSOLLA_TOKEN,
} from "./types";

export const initialXsollaToken = {
  loading: false,
  success: false,
  error: false,
  data: null,
};

function reducer(state, action) {
  switch (action.type) {
    case CREATE_XSOLLA_TOKEN_REQUESTED: {
      const { xsollaToken } = action;
      return { ...state, ...xsollaToken };
    }
    case CREATE_XSOLLA_TOKEN_FULFILLED: {
      const { xsollaToken } = action;
      return { ...state, ...xsollaToken };
    }
    case CREATE_XSOLLA_TOKEN_REJECTED: {
      const { xsollaToken } = action;
      return { ...state, ...xsollaToken };
    }
    case RESET_XSOLLA_TOKEN: {
      const { xsollaToken } = initialXsollaToken;
      return { ...state, ...xsollaToken };
    }

    default:
      return state;
  }
}

export default reducer;
