import React from "react";

import useQuery from "hooks/useQuery";

import { useWatchEventContext } from "contexts/WatchEventContext";

import VideoJsPlayer from "../PlayerComponents/VideoJsPlayer";
import PlyrPlayer from "../PlayerComponents/PlyrPlayer";
import TheoPlayer from "../PlayerComponents/TheoPlayer";
import FlowPlayer from "../PlayerComponents/FlowPlayer";
import JwPlayer from "../PlayerComponents/JwPlayer";

const FLOWPLAYER = "flow";
const VIDEOJS = "videojs";
const THEO = "theo";
const JWPLAYER = "jw";
const PLYR = "plyr";

const bunnyVid = "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8";

function LivePlayer(props) {
  const query = useQuery();
  const player = query.get("player");
  const bunny = query.get("test");

  const { eventData } = useWatchEventContext();
  const { event_outputlink, output_link, event_banner } = eventData;

  const outputFromData = event_outputlink || output_link;
  const videoSource = bunny === "bunny" ? bunnyVid : outputFromData;

  const playerProps = {
    src: videoSource,
    banner: event_banner,
  };

  switch (player) {
    case FLOWPLAYER:
      return <FlowPlayer {...playerProps} />;
    case VIDEOJS:
      return <VideoJsPlayer {...playerProps} />;
    case THEO:
      return <TheoPlayer {...playerProps} />;
    case JWPLAYER:
      return <JwPlayer {...playerProps} />;
    case PLYR:
      return <PlyrPlayer {...playerProps} />;

    default:
      return <VideoJsPlayer {...playerProps} />;
  }
}

export default LivePlayer;
