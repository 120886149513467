import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function MultipleConnections(props) {
  const { t } = useTranslation();

  return (
    <>
      <div className="c-banned__wrapper">
        <div className="c-banned">
          <span role="img" aria-label="prohibited">
            📡
          </span>
          <h1>{t ("multiple_connections.title")}</h1>
          <p>{t ("multiple_connections.description")}</p>

          <Link to="/" className="o-button--lg o-button--green">
            {t ("multiple_connections.cta")}
          </Link>
        </div>
      </div>
    </>
  );
}

export default MultipleConnections;
