import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";

import HeaderStyled from "components/layouts/styled/HeaderStyled";
// import Overlay from "../Overlay/Overlay";
import NeermeLogo from "../../shared/icon/neerme_logo";
import NeermeIcon from "../../shared/icon/neerme_icon";
import HelpIcon from "../../shared/icon/help";
import HamburgerIcon from "../../shared/icon/hamburger";
import HomeIcon from "../../shared/icon/home";
import Menu from "../Menu";
import LevelUpBadge from "./LevelUpBadge";

import { OverlayContext } from "../../../contexts/overlayMenu";

function HeaderWatch(props) {
  const { t, i18n } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const { headerCenterAd, headerAd, header } = themeContext;
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [showOverlay, setShowOverlay] = useState(false);

  function toggleOverlay() {
    setShowOverlay(!showOverlay);
  }

  const overlayContext = {
    showOverlay,
    toggleOverlay: toggleOverlay,
  };

  const { stylePage, helpBlank } = props;

  return (
    <OverlayContext.Provider value={overlayContext}>
      <HeaderStyled className={`c-header ${stylePage}`}>
        {header.logo.active ? (
          <a
            className="c-header__custom-logo"
            href={header.logo?.link ? header.logo?.link : "/"}
            target={header.logo?.link ? "_blank" : "_self"}
            rel="noopener noreferrer"
            onClick={
              header.logo?.link
                ? () => {}
                : (event) => {
                    event.preventDefault();
                  }
            }
          >
            <img src={header.logo.src} alt="Logo" />
          </a>
        ) : (
          <Link className="c-header__logo" to="/">
            <NeermeLogo width={100} />
            <NeermeIcon width={32} />
          </Link>
        )}

        <ul className="c-header__links is-first">
          <li className="c-header__single is-desktop">
            <Link to="/" className="c-header__icon">
              <HomeIcon width={16} />
              {t("menu.home")}
            </Link>
          </li>
          <li className="c-header__single is-desktop">
            <Link
              target={helpBlank && "_blank"}
              className="c-header__icon"
              to="/help"
            >
              <HelpIcon width={16} />
              <span>{t("menu.help")}</span>
            </Link>
          </li>
          {/*{authToken ? (
						<>
							<li className="c-header__single is-desktop">
								<Link className="c-header__icon" to="/">
									<StreamIcon width={16} />
									<span>{t("menu.lives")}</span>
								</Link>
							</li>
							<li className="c-header__single is-desktop">
								<a className="c-header__icon" href="/" onClick={handleLogout}>
									<LogoutIcon width={16} />
									{t("menu.logout")}
								</a>
							</li>
							<li className="c-header__single is-desktop">
								<a href="/">
									<figure className="c-header__avatar">
										<UserIconFilled width={24} />
										<img src="https://home-statics.boletia.com/uploads/stickers/la-cotorrisa-1.png" alt="" width="85%" />
									</figure>
								</a>
							</li>
						</>
					) : (
						<li className="c-header__single is-desktop">
							<Link className="c-header__icon" to="/login">
								<UserIcon width={16} />
								<span>{t("menu.login")}</span>
							</Link>
						</li>
					)}*/}
        </ul>
        <ul className="c-header__links">
          <li className="c-header__single is-language is-desktop">
            <button onClick={() => changeLanguage("en")}>EN</button>
            <span>|</span>
            <button onClick={() => changeLanguage("es")}>ES</button>
          </li>
          <li className="c-header__single">
            <LevelUpBadge />
          </li>

          {/*<li className="c-header__single">
						<a
							className="c-header__ad"
							href="https://bit.ly/AFHSPelea"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								width="120"
								height="32"
								src="https://home-statics.boletia.com/uploads/sopitas_live.png"
								alt="Sopitas"
							/>
						</a>
					</li>*/}
          <li className="c-header__single is-mobile">
            <label htmlFor="mobile-menu" className="c-header__icon">
              <HamburgerIcon width={16} />
            </label>
          </li>
          {headerAd.active && (
            <li className="c-header__single">
              <a
                className="c-header__ad"
                href={headerAd.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  width="120"
                  height="32"
                  src={headerAd.src}
                  alt={headerAd.alt}
                />
              </a>
            </li>
          )}
        </ul>
        {headerCenterAd && headerCenterAd.active && (
          <a
            className="c-header__custom-logo-center"
            href={headerCenterAd.link ? headerCenterAd.link : "/"}
            target={headerCenterAd.link ? "_blank" : "_self"}
            rel="noopener noreferrer"
            onClick={
              headerCenterAd.link
                ? () => {}
                : (event) => {
                    event.preventDefault();
                  }
            }
          >
            <img src={headerCenterAd.src} alt={headerCenterAd.alt} />
          </a>
        )}
      </HeaderStyled>
      {/* <Overlay /> */}
      <Menu />
    </OverlayContext.Provider>
  );
}

export default HeaderWatch;
