import { useState, useEffect } from "react";

function useMultiScripts(scripts) {
  const [state, setState] = useState({
    scriptsAdded: scripts.length,
    scriptsLoadded: 0,
  });

  function loadScript(script) {
    return new Promise((resolve) => {
      function onScriptLoad() {
        setState((data) => ({
          ...data,
          scriptsLoadded: data.scriptsLoadded + 1,
        }));
        resolve();
      }

      const scriptElement = document.createElement("script");
      scriptElement.src = script;

      scriptElement.addEventListener("load", onScriptLoad);

      document.body.appendChild(scriptElement);
    });
  }
  useEffect(() => {
    scripts.forEach(async (script) => {
      await loadScript(script);
    });
  }, [scripts]);

  return [state.scriptsLoadded === state.scriptsAdded, state.scriptsLoadded];
}

export default useMultiScripts;
