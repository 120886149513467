import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { useAuth } from "contexts/authContext";
import { getUserEvents } from "api/events";
import { useTranslation } from 'react-i18next';

import MonthsAbEs from "consts/monthsAbbES";

import Footer from "components/layouts/Footer";
import Header from "components/layouts/Header";

import Loading from "components/shared/Loading";
import StreamIcon from "components/shared/icon/stream";

import LiveCard from "./LiveCard";

function Events() {
  const { t } = useTranslation();
  const { authToken } = useAuth();

  const [livestreams, setLivestreams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUserEvents(authToken).then((request) => {
      setLivestreams(request.data.events);
      setIsLoading(false);
    });
  }, [authToken]);

  const eventsLength = livestreams.length;

  function getDayFromData(date) {
    return date.split("-")[2];
  }
  function getMonthFromData(date) {
    return MonthsAbEs[parseInt(date.split("-")[1]) - 1];
  }

  function StreamList(props) {
    const { livestreams } = props;
    const listItems = livestreams.map((live, index) => {
      return (
        <li key={index}>
          <LiveCard
            isOwner={false}
            src={live.event_banner}
            start_day={getDayFromData(live.event_start_date)}
            start_month={getMonthFromData(live.event_start_date)}
            time_start={live.event_start_time}
            name={live.event_name}
            is_live={live.is_time_to_access}
            subdomain={live.event_subdomain}
          />
        </li>
      );
    });
    return <ul className="c-events__carousel">{listItems}</ul>;
  }

  function EmptyState(props) {
    return (
      <div className="c-error">
        <h1 className="c-error__title">
          {t ("events.error1")}
        </h1>
        <p>
          {t ("events.error2")}
          <a
            href="https://boletia.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            boletia.com
          </a>
          {t ("events.error3")}
        </p>
      </div>
    );
  }

  return (
    <div className="c-layout">
      <Helmet>
        <title>{t ("meta.events")}</title>
      </Helmet>
      <Header stylePage={"is-gradient"} />
      <section className="c-events__container">
        <div className="c-events">
          <div className="c-events__info">
            <StreamIcon width={40} />
            <div className="c-events__title">
              <h1>{t ("events.title")}</h1>
              <p>{t ("events.text_1")} <Link to="/streaming-help">{t ("events.text_2")}</Link></p>
            </div>
            
          </div>

          {isLoading ? (
            <Loading />
          ) : eventsLength > 0 ? (
            <StreamList livestreams={livestreams} />
          ) : (
            <EmptyState />
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Events;
