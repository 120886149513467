import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollTrigger from '@terwanerik/scrolltrigger';
import Carousel from "./Carousel";
import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";
import ArrowIcon from '../../shared/icon/arrow'

function Home(props) {
  const { t } = useTranslation();
  useEffect(()=>{
    const trigger = new ScrollTrigger({
      trigger: {
        // If the trigger should just work one time
        once: true,
        offset: {
          element: {
            x: 0,
            y: (trigger, rect, direction) => {
              return 0.1
            }
          },
          viewport: {
            x: 0,
            y: (trigger, frame, direction) => {
              return trigger.visible ? 0 : 0.1
            }
          }
        }
      }
    })
    trigger.add('[data-slideleft]')
           .add('[data-slideright]')
           .add('[data-slidebottom]')
  },[])
  
  return (
    <>
      <Header stylePage={"is-gradient"} />
      <div className="c-landing c-home">
        <section className="c-landing-hero c-home-hero">
          <div className="c-landing-hero__wrapper c-home-hero__wrapper">
            <h1 className="c-home-hero__title c-landing-hero__title">{t("home.title")}</h1>
            <p className="c-home-hero__text">{t("home.hero_desc")}</p>
            <Link to="/request-demo" className="o-button--lg o-button--green">{t("home.main_cta")}</Link>
          </div>
        </section>
        <section className="c-home-video">
          <div className="c-home-video__triangle"></div>
          <div className="c-home-video__wrapper">
            <video 
              muted 
              loop 
              autoPlay 
              playsInline
              poster="https://boletia-v2.s3.amazonaws.com/uploads/neerme/InsiteAdmin.jpg"
            >
              <source
                src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/InsiteAdmin.webm"
                type="video/webm"
              />
              <source
                src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/InsiteAdminLow.mp4"
                type="video/mp4"
              />
              <source
                src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/InsiteAdmin.mp4"
                type="video/mp4"
              />
          </video>
          </div>
        </section>
        <section className="c-landing-slides">
          <article className="c-landing-slides__single">
            <div className="c-landing-slides__video" data-slideright>
              <video 
                muted 
                loop 
                autoPlay 
                playsInline
                poster="https://boletia-v2.s3.amazonaws.com/uploads/neerme/CharlesAns.jpg"
              >
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/CharlesAns.webm"
                  type="video/webm"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/CharlesAnsLow.mp4"
                  type="video/mp4"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/CharlesAns.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="c-landing-slides__content" data-slideleft>
              <span className="c-landing-slides__emoji" role="img" aria-label="fireworks">🎇</span>
              <h1 className="c-home__title">{t("home.why.title1")}</h1>
              <p className="c-home__text">{t("home.why.desc1")}</p>
              <p className="c-home__text">{t("home.why.desc2")}</p>
              <ul className="c-landing-slides__list">
                <li>{t("home.why.list1")}</li>
                <li>{t("home.why.list2")}</li>
                <li>{t("home.why.list3")}</li>
              </ul>
              <Link to="/why-neerme" className="o-button--lg o-button--purple">{t("home.why.cta")}</Link>
            </div>
          </article>
        </section>
        <section className="c-home-carousel" data-slidebottom>
          <h1 className="c-home-carousel__title c-home__title" data-slidebottom>{t("home.carousel.title1")}<br />{t("home.carousel.title2")}</h1>
          <Carousel />
        </section>
        <section className="c-home-types">
          <div className="c-home-types__wrapper" data-slidebottom>
            <h1 className="c-home-types__title c-home__title">{t("home.types.title")}</h1>
            <p className="c-home__text">{t("home.types.desc")}</p>
            <Link to="/request-demo" className="o-button--lg o-button--purple">
              {t("home.main_cta")}
            </Link>
          </div>
          <ul className="c-home-types__events">
            <li data-slideleft><span role="img" aria-label="Tailored Shows">🎙</span> {t("home.types.tailoredshows")}</li>
            <li data-slideleft><span role="img" aria-label="Live Podcasts">🎧</span> {t("home.types.livepodcasts")}</li>
            <li data-slideleft><span role="img" aria-label="Late-night shows">🌃</span> {t("home.types.latenightshows")}</li>
            <li data-slideleft><span role="img" aria-label="Music Premieres">🎸</span> {t("home.types.musicpremieres")}</li>
            <li data-slideleft><span role="img" aria-label="Digital Touring">🚗</span> {t("home.types.digitaltouring")}</li>
            <li data-slideleft><span role="img" aria-label="Tailored Shows">🏆</span> {t("home.types.virtualawards")}</li>
            <li data-slideleft><span role="img" aria-label="Tailored Shows">😎</span> {t("home.types.interactiveshows")}</li>
            <li data-slideleft><span role="img" aria-label="Tailored Shows">🎭</span> {t("home.types.onlinetheater")}</li>
            <li data-slideleft><span role="img" aria-label="Tailored Shows">☄️</span> {t("home.types.concertswithatwist")}</li>
            <li data-slideleft><span role="img" aria-label="Tailored Shows">🙋‍♀️</span> {t("home.types.liveqa")}</li>
            <li data-slideleft><span role="img" aria-label="Tailored Shows">👾</span> {t("home.types.livegameshows")}</li>
            <li data-slideleft><span role="img" aria-label="Tailored Shows">👨‍🍳</span> {t("home.types.masterclasses")}</li>
            <li data-slideleft><span role="img" aria-label="Tailored Shows">💃</span> {t("home.types.interactiveplays")}</li>
            <li data-slideleft><span role="img" aria-label="Tailored Shows">🚀</span> {t("home.types.hybridshows")}</li>
            <li data-slideleft><span role="img" aria-label="Tailored Shows">💬</span> {t("home.types.meetgreet")}</li>
            <li data-slideleft><span role="img" aria-label="Tailored Shows">👨‍💻</span> {t("home.types.onlinepanels")}</li>
            <li data-slideleft><span role="img" aria-label="Storytelling sessions">📚</span> {t("home.types.storytellingsessions")}</li>
            <li data-slideleft><span role="img" aria-label="Online museums">🖼</span> {t("home.types.onlinemuseums")}</li>
            <li data-slideleft><span role="img" aria-label="Fundraisers">🎗</span> {t("home.types.fundraisers")}</li>
            <li data-slideleft><span role="img" aria-label="Interactive Stand ups">🎤</span> {t("home.types.interactivestandups")}</li>
          </ul>
        </section>
        <section className="c-landing-stories">
          <div className="c-landing-stories__wrapper" data-slideright>
            <div className="c-landing-stories__content">
              <h1 className="c-home__title">{t("home.success.title")}</h1>
              <p className="c-home__text">{t("home.success.desc")}</p>
            </div>
          </div>
          <ul className="c-landing-stories__list">
            <li className="c-landing-stories__single" data-slideleft>
              <span className="c-landing-stories__emoji" role="img" aria-label="Comedy">🤣</span>
              <h6 className="c-landing-stories__category">{t("home.success.type1")}</h6>
              <p>{t("home.success.case1")}</p>
              <Link to="/case-studies/comedy" className="c-landing-stories__link"><ArrowIcon width={12} />{t("home.success.read")}</Link>
            </li>
            <li className="c-landing-stories__single" data-slideleft>
              <span className="c-landing-stories__emoji" role="img" aria-label="Digital Content Formats">🎧</span>
              <h6 className="c-landing-stories__category">{t("home.success.type2")}</h6>
              <p>{t("home.success.case2")}</p>
              <Link to="/case-studies/digital-content" className="c-landing-stories__link"><ArrowIcon width={12} />{t("home.success.read")}</Link>
            </li>
            <li className="c-landing-stories__single" data-slideleft>
              <span className="c-landing-stories__emoji" role="img" aria-label="Theater Productions">🎭</span>
              <h6 className="c-landing-stories__category">{t("home.success.type3")}</h6>
              <p>{t("home.success.case3")}</p>
              <Link to="/case-studies/theater" className="c-landing-stories__link"><ArrowIcon width={12} />{t("home.success.read")}</Link>
            </li>
            <li className="c-landing-stories__single" data-slideleft>
              <span className="c-landing-stories__emoji" role="img" aria-label="Music">🎸</span>
              <h6 className="c-landing-stories__category">{t("home.success.type4")}</h6>
              <p>{t("home.success.case4")}</p>
              <Link to="/case-studies/music" className="c-landing-stories__link"><ArrowIcon width={12} />{t("home.success.read")}</Link>
            </li>
            <li className="c-landing-stories__single" data-slideleft>
              <span className="c-landing-stories__emoji" role="img" aria-label="Corporate Events">👩‍💻</span>
              <h6 className="c-landing-stories__category">{t("home.success.type5")}</h6>
              <p>{t("home.success.case5")}</p>
              <Link to="/case-studies/corporate" className="c-landing-stories__link"><ArrowIcon width={12} />{t("home.success.read")}</Link>
            </li>
          </ul>
        </section>
        <section className="c-home-data">
          <ul className="c-home-data__wrapper">
            <li>
              <h2>100+</h2>
              <p>{t("home.data.desc1")}</p>
            </li>
            <li>
              <h2>200+</h2>
              <p>{t("home.data.desc2")}</p>
            </li>
            <li>
              <h2>250k</h2>
              <p>{t("home.data.desc3")}</p>
            </li>
            <li>
              <h2>96</h2>
              <p>{t("home.data.desc4")}</p>
            </li>
          </ul>
        </section>
        <section className="c-landing-magnet c-home-magnet">
          <div className="c-landing-magnet__wrapper" data-slidebottom>
            <h2 className="c-landing-magnet__title">{t("home.magnet.title")}</h2>
            <p className="c-home__text">{t("home.magnet.desc")}</p>
            <Link to="/request-demo" className="o-button--purple o-button--lg">
              {t("home.main_cta")}
            </Link>
          </div>  
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Home;
