import axios from "axios";

import {
  BASE_URL,
  BASE_URL_V2,
  BASE_URL_V3,
  USER_EVENTS,
  EVENT_DETAIL,
  EVENT_LIST,
  EVENT_ACCESS_CODE_VALIDATION,
  EVENT_TOKEN_COMPONENTS,
  EVENT_TOKEN_LEVELS,
  CREATE_XSOLLA_TOKEN,
  SET_NICKNAME_BY_TOKEN,
  GET_TICKET_DATA,
  VALIDATE_CHAT_BLOCKED,
} from "../endpoints";

export async function getUserEvents(token) {
  return axios.get(`${BASE_URL}${USER_EVENTS}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getEventDetail(eventSubdomain) {
  return axios.get(`${BASE_URL_V3}${EVENT_DETAIL(eventSubdomain)}`);
}
export async function getEvents() {
  return axios.get(`${BASE_URL_V2}${EVENT_LIST}`);
}

export async function validateAccessCode(data) {
  return axios.post(`${BASE_URL_V3}${EVENT_ACCESS_CODE_VALIDATION}`, data);
}

export async function getEventTokenComponents(eventID, accessCode) {
  return axios.get(
    `${BASE_URL_V3}${EVENT_TOKEN_COMPONENTS(eventID, accessCode)}`
  );
}
export async function getEventLevels(eventID) {
  return axios.get(`${BASE_URL_V3}${EVENT_TOKEN_LEVELS(eventID)}`);
}
export async function createXsollaToken(data) {
  return axios.post(`${BASE_URL_V3}${CREATE_XSOLLA_TOKEN}`, data);
}
export async function setNicknameByToken(token, data) {
  return axios.put(`${BASE_URL_V3}${SET_NICKNAME_BY_TOKEN(token)}`, data);
}
export async function getTicketData(token) {
  return axios.get(`${BASE_URL_V3}${GET_TICKET_DATA(token)}`);
}

export async function validateTokenChatBlocked(token, eventSubdomain) {
  return axios.get(`${BASE_URL_V3}${VALIDATE_CHAT_BLOCKED(token, eventSubdomain)}`);
}
