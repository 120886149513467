import React from "react";
import { useTranslation } from "react-i18next";
import generateBoletiaURL from "utils/generateBoletiaURL";
// import Loading from "../Loading/Loading";

function EventBuy(props) {
  const { cleanDateTime, showButton, sales_subdomain, time_zone } = props;
  const { t } = useTranslation(["event_site"]);
  const { cleanStartTime } = cleanDateTime;

  return (
    <div className="c-event-page__block is-buy">
      <h4>{t("buy.title")}</h4>
      <p>
        {t("buy.description_1")} {cleanStartTime} {t("buy.description_2")}{" "}
        {time_zone}
      </p>
      {showButton && (
        <a
          href={generateBoletiaURL(sales_subdomain)}
          target="_blank"
          rel="noopener noreferrer"
          className="o-button--lg o-button--green"
        >
          {t("buy.cta")}
        </a>
      )}
    </div>
  );
}

export default EventBuy;
