import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import SendIcon from "components/shared/icon/send";

import { useChannelWebsocketContext } from "contexts/channelWebsocket";
import { useAuth } from "contexts/authContext";
import { useEventComponentsContext } from "contexts/EventComponentsContext";
import Stickers from "../Stickers";
import ChatLevelUp from "../ChatLevelUp";
import { useWatchEventContext } from "contexts/WatchEventContext";

function MessageForm(props) {
  const { t } = useTranslation(["watch"]);
  const { chatComponent } = useEventComponentsContext();
  const [errorChat, setErrorChat] = useState(false);
  const [errorChatText, setErrorChatText] = useState("");
  const { watchData, validateChatBlocked, accessCode } = useWatchEventContext();

  const inputMessage = useRef();

  const { messageHandler, showUpdateHandler } = props;

  const { stickerComponents, badgesComponents } = useEventComponentsContext();
  const { sendChatMessage } = useChannelWebsocketContext();
  const { chatUsername, chatAvatar } = useAuth();

  function sendMessage(msg) {
    const badgesIDs = badgesComponents.rest.map((comp) => comp.id).join(",");
    const usernameWithBadges = `${chatUsername}|${badgesIDs}`;
    const data = { chatUsername: usernameWithBadges, chatAvatar, msg };
    return sendChatMessage(data, messageHandler, showError);
  }

  function handleSubmit(event) {
    event.preventDefault();

    validateChatBlocked();

    if (!chatUsername) {
      showUpdateHandler(true);
      return;
    }

    const inputEl = inputMessage.current;
    const msg = inputEl.value.trim();

    if (watchData.chatBlocked) {
      inputEl.value = "";
      return;
    }

    if (!msg) return;
    if (Object.keys(stickerComponents.rawStickersDisabled).includes(msg)) {
      inputEl.value = "";
      setErrorChat(false);
      return;
    }

    if (sendMessage(msg)) {
      inputEl.value = "";
      setErrorChat(false);
    }
  }

  function clearError() {
    if (!errorChat) return;
    setErrorChat(false);
  }

  function showError(msg, msgTimeout) {
    setErrorChat(true);
    setErrorChatText(msg(msgTimeout));
  }

  function onClickInput(event) {
    if (!chatUsername) {
      showUpdateHandler(true);
    }
  }
  return (
    <form className="MessageForm c-chat__form" onSubmit={handleSubmit}>
      {errorChat && (
        <div className="c-chat__warning">
          <strong>{t("chat.warning")}</strong>
          {errorChatText}
        </div>
      )}
      <div className="c-chat__input">
        <input
          className={errorChat ? `is-warning` : ``}
          type="text"
          ref={inputMessage}
          placeholder={t("chat.input")}
          maxLength="160"
          onChange={clearError}
          onClick={onClickInput}
        />
        <div className="c-chat__button">
          <button
            type="submit"
            className="c-chat__send"
            data-tooltip={t("chat.send")}
          >
            <SendIcon width={20} />
          </button>
          {chatComponent?.properties?.show_stickers !== undefined ? (
            chatComponent?.properties?.show_stickers && (
              <Stickers
                sendMessage={sendMessage}
                showUpdateHandler={showUpdateHandler}
              />
            )
          ) : (
            <Stickers
              sendMessage={sendMessage}
              showUpdateHandler={showUpdateHandler}
            />
          )}
        </div>
      </div>
      <ChatLevelUp />
    </form>
  );
}

export default MessageForm;
