import styled from "styled-components";

const ChatStyled = styled.div`
  background: ${(props) => props.theme.chat.colors.body};
  color: ${(props) => props.theme.chat.colors.text}!important;
  .c-chat__message {
    background: ${(props) => props.theme.chatMessage.colors.body}!important;
    color: ${(props) => props.theme.chat.colors.text}!important;
  }
  .c-chat__input input {
    background: ${(props) => props.theme.chatMessage.colors.body}!important;
    color: ${(props) => props.theme.chat.colors.text}!important;
  }
  .c-livestream__header {
    background: ${(props) => props.theme.chat.header.colors.body}!important;
  }
  .MessageForm input {
    background: ${(props) => props.theme.chatMessage.colors.body}!important;
    color: ${(props) => props.theme.chat.colors.text}!important;
  }
  .MessageForm button {
    color: ${(props) => props.theme.chat.colors.text}!important;
  }
  .MessageForm.c-chat__form {
    background: ${(props) => props.theme.chat.header.colors.body}!important;
  }
  .c-empty__text {
    color: ${(props) => props.theme.chat.colors.text}!important;
  }
  .c-chat__title {
    color: ${(props) => props.theme.chat.colors.text}!important;
  }
`;
export default ChatStyled;
