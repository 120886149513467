export const defaultControls = `
<div class="plyr__controls">
    <div class="neerme__controls"></div>
    <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
    <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg>
    <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg>
    </button>
    <div class="plyr__settings">
    <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
    <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-muted"></use></svg>
    <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-volume"></use></svg>
    </button>
    <div class="plyr__volume">
    <input data-plyr="volume" type="range" min="0" max="1" step="0.05" value="1" autocomplete="off" aria-label="Volume">
    </div>
    <div class="neerme__controls_custom_settings"></div>
    <button type="button" class="plyr__control" data-plyr="fullscreen">
    <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-exit-fullscreen"></use></svg>
    <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-enter-fullscreen"></use></svg>
    <span class="plyr__tooltip icon--pressed">Salir de pantalla completa</span>
    <span class="plyr__tooltip icon--not-pressed">Pantalla completa</span>
    </button>
    </div>
    <div class="neerme__controls_end"></div>
</div>
`;
