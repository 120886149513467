import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

import { useWatchEventContext } from "contexts/WatchEventContext";

import { isObject } from "utils/dataStructures";

import reducer, { initialEventComponets } from "./store/reducer";
import { getEventByTokenComponents } from "./store/actions";

import {
  STICKERS_ID,
  AVATAR_ID,
  BADGE_ID,
  THEME_ID,
  CHAT_ID,
} from "./componentsKeys";

export function EventComponentsContextProvider(props) {
  const { children } = props;
  const { eventData, accessCode } = useWatchEventContext();
  const { is_owner: isAdmin } = eventData;

  const [stickerComponents, setStickerComponents] = useState({
    marketableDisabled: [],
    rawStickers: [],
    rawStickersDisabled: [],
    rest: [],
  });
  const [avatarComponents, setAvatarComponents] = useState({
    marketableDisabled: [],
    rawAvatars: [],
    rest: [],
  });
  const [badgesComponents, setBadgesComponents] = useState({
    rawBadges: [],
    rest: [],
  });
  const [themeComponent, setThemeComponent] = useState({});

  const [themeLoaded, setThemeLoaded] = useState(false);
  const [componentsReady, setComponentsReady] = useState(false);
  const [chatComponent, setChatComponent] = useState({});

  const [componentsState, dispatchEvent] = useReducer(
    reducer,
    initialEventComponets
  );

  useEffect(() => {
    if (componentsState.eventComponents.success) {
      setStickerComponents(getStickers());
      setAvatarComponents(getAvatars());
      setBadgesComponents(getBadges());
      setThemeComponent(getTheme());
      setChatComponent(getChat());
      setComponentsReady(componentsState.eventComponents.success);
    }

    // eslint-disable-next-line
  }, [componentsState.eventComponents.success]);

  useEffect(() => {
    loadComponents();
    // eslint-disable-next-line
  }, []);

  function loadComponents() {
    getEventByTokenComponents(
      { eventID: eventData.event_id, accessCode },
      dispatchEvent
    );
  }

  function componentsByDevelopID(developID) {
    return componentsState.eventComponents.data.filter(
      (component) => component.develop_id === developID
    );
  }

  function getChat() {
    const chat = componentsByDevelopID(CHAT_ID);

    const chatProperties = {
      enable: true,
      properties: !!chat[0]?.properties ? chat[0].properties : {},
    };
    if (chat.length === 1) {
      chatProperties.enable = chat[0].enable;
    }
    return chatProperties;
  }
  function getTheme() {
    const theme = componentsByDevelopID(THEME_ID);
    if (theme.length === 1) {
      if (theme[0].enable) {
        setThemeLoaded(theme[0].enable);
        return theme[0];
      }
    } else {
      return {};
    }
    return {};
  }

  function getStickers() {
    let rawStickers = {};
    let rawStickersDisabled = {};
    let marketableDisabled = [];

    const stickers = componentsByDevelopID(STICKERS_ID);

    const rest = isAdmin
      ? stickers
      : stickers.filter((stickerComponent) => stickerComponent.enable);

    // raw stickers
    for (let index = 0; index < stickers.length; index++) {
      const stickerComponentAll = stickers[index];
      const stickersObjectAll = stickerComponentAll.properties?.stickers;
      if (isObject(stickersObjectAll)) {
        rawStickers = { ...rawStickers, ...stickersObjectAll };
      }
    }

    if (!isAdmin) {
      marketableDisabled = stickers.filter(
        (stickerComponent) =>
          stickerComponent.marketable && !stickerComponent.enable
      );

      //raw disabled
      for (let index = 0; index < marketableDisabled.length; index++) {
        const stickersComponentDisabled = marketableDisabled[index];
        const stickersObjectDisabled =
          stickersComponentDisabled.properties?.stickers;
        if (isObject(stickersObjectDisabled)) {
          rawStickersDisabled = {
            ...rawStickersDisabled,
            ...stickersObjectDisabled,
          };
        }
      }
    }

    return {
      marketableDisabled,
      rawStickers,
      rawStickersDisabled,
      rest,
    };
  }
  function getAvatars() {
    let rawAvatars = {};
    let marketableDisabled = [];

    const avatars = componentsByDevelopID(AVATAR_ID);

    const rest = isAdmin
      ? avatars
      : avatars.filter((avatarComponent) => avatarComponent.enable);

    for (let index = 0; index < avatars.length; index++) {
      const avatarComponentAll = avatars[index];
      const avatarsObjectAll = avatarComponentAll.properties?.avatars;
      if (isObject(avatarsObjectAll)) {
        rawAvatars = { ...rawAvatars, ...avatarsObjectAll };
      }
    }

    if (!isAdmin) {
      marketableDisabled = avatars.filter(
        (avatarComponent) =>
          avatarComponent.marketable && !avatarComponent.enable
      );
    }

    return {
      marketableDisabled,
      rawAvatars,
      rest,
    };
  }

  function getBadges() {
    const rawBadges = [];

    const badges = componentsByDevelopID(BADGE_ID);

    const rest = badges.filter((badgeComponent) => badgeComponent.enable);

    for (let index = 0; index < badges.length; index++) {
      const badgeComponent = badges[index];
      const ID = badgeComponent.id;
      const icon = badgeComponent.properties?.icon;
      rawBadges.push({
        [ID]: icon,
      });
    }
    return {
      rawBadges,
      rest,
    };
  }

  function checkOwnedComponents(components) {
    const marketableDisabled = componentsState.eventComponents.data.filter(
      (stickerComponent) =>
        stickerComponent.marketable && stickerComponent.enable
    );
    const userComponetsIDs = marketableDisabled.map((item) => item.id);

    return components.every((item) => userComponetsIDs.includes(item.id));
  }
  const defaultContext = {
    componentsState,
    componentsReady,
    eventComponents: componentsState.eventComponents,
    stickerComponents,
    themeComponent,
    avatarComponents,
    badgesComponents,
    checkOwnedComponents,
    loadComponents,
    themeLoaded,
    chatComponent,
    showChat:
      typeof chatComponent?.enable !== undefined ? chatComponent?.enable : true,
  };

  return (
    <EventComponentsContext.Provider value={defaultContext}>
      {children}
    </EventComponentsContext.Provider>
  );
}

export const EventComponentsContext = createContext();

export function useEventComponentsContext() {
  return useContext(EventComponentsContext);
}
