import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  height = '100%',
  className = 'is-hamburger',
  viewBox = '0 0 48 32'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id='Artboard-Copy-9' fill={fill}>
      <path d='M46,28 C47.106,28 48,28.894 48,30 C48,31.106 47.106,32 46,32 L2,32 C0.894,32 0,31.106 0,30 C0,28.894 0.894,28 2,28 L46,28 Z M46,14 C47.106,14 48,14.894 48,16 C48,17.106 47.106,18 46,18 L2,18 C0.894,18 0,17.106 0,16 C0,14.894 0.894,14 2,14 L46,14 Z M46,0 C47.106,0 48,0.894 48,2 C48,3.106 47.106,4 46,4 L2,4 C0.894,4 0,3.106 0,2 C0,0.894 0.894,0 2,0 L46,0 Z' />
    </g>
  </svg>
)

export default SVG
