// import App from "next/app"
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";
import useGTM from "@elgorditosalsero/react-gtm-hook";

import AppRouter from "./Router";
import { AuthContextProvider } from "contexts/authContext";

import "./style/main.scss";

function MyApp(props) {
  const { t } = useTranslation();

  const { init } = useGTM();
// eslint-disable-next-line
  useEffect(() => init({ id: "GTM-WMWX8PK" }), []);

  return (
    <AuthContextProvider>
      <Helmet titleTemplate="%s - Neerme" defaultTitle={t("meta.title")}>
        <meta name="description" content={t("meta.description")} />
        <meta
          name="og:description"
          content="Video streaming, monetization, interaction, and engagement tools, all in one place."
        />
        <meta
          property="og:title"
          content="Neerme | The best digital venue for live stream experiences"
        />
        <meta property="og:url" content="http://neerme.tv" />
        <meta
          property="og:image"
          content="https://boletia-v2.s3.amazonaws.com/uploads/neerme/Neerme_banner.jpg"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://boletia-v2.s3.amazonaws.com/uploads/neerme/favicon/apple-touch-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://boletia-v2.s3.amazonaws.com/uploads/neerme/favicon/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://boletia-v2.s3.amazonaws.com/uploads/neerme/favicon/favicon-194x194.png"
          sizes="194x194"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://boletia-v2.s3.amazonaws.com/uploads/neerme/favicon/favicon-96x96.png"
          sizes="96x96"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://boletia-v2.s3.amazonaws.com/uploads/neerme/favicon/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="icon"
          href="https://boletia-v2.s3.amazonaws.com/uploads/neerme/favicon/favicon.ico"
          type="image/x-icon"
        />
      </Helmet>
      <AppRouter />
    </AuthContextProvider>
  );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default MyApp;
