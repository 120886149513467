import React from "react";
// import { Link } from "react-router-dom";
import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";

function Conduct(props) {
  return (
    <>
      <Header stylePage={"is-gradient"} />
      <div className="c-layout">
        <div className="c-legal">
          <h1>Código de conducta</h1>
          <ul>
            <li>Debes tener al menos 13 años de edad.</li>
            <li>
              No debes tener prohibido el uso de ningún aspecto de nuestro Servicio en virtud de las leyes aplicables o la utilización de servicios relacionados con pagos si estás en una lista de partes denegadas aplicable.
            </li>
            <li>
              No debemos haber inhabilitado previamente tu cuenta debido a una infracción de la ley o a cualquiera de nuestras políticas.
            </li>
            <li>No debes ser un delincuente condenado por delitos sexuales.</li>
            <li>
              No puedes hacerte pasar por otra persona ni brindar información incorrecta.
            </li>
            <li>
              No puedes realizar actividades ilegales, engañosas o fraudulentas, ni actividades con fines ilegales o no autorizados.
            </li>
            <li>
              No puedes infringir estas Condiciones o nuestras políticas (ni ayudar o alentar a otros para que lo hagan).
            </li>
            <li>
              Utilizar la música para fines comerciales, o no personales, a menos que hayas obtenido una licencia para ello.
            </li>
            <li>
              Cualquier información que compartas y que no se tenga autorización para ello será removida.
            </li>
            <li>
              Si cualquier información que compartas es contenido cuya propiedad es de alguien mas, se puede cancelar tu cuenta. 
            </li>
            <li>
              No puedes realizar actividades que interfieran con el Servicio o impidan que se opere como está previsto.
            </li>
            <li>
              No puedes intentar crear cuentas ni recopilar información o acceder a ella sin autorización.
            </li>
            <li>
              Esto incluye crear cuentas o recopilar información de un modo automatizado sin nuestro permiso expreso.
            </li>
            <li>
              No puedes intentar comprar, vender o transferir ninguna parte de tu cuenta (incluido el nombre de usuario), ni solicitar, recopilar o usar credenciales de inicio de sesión o insignias de otros usuarios.
            </li>
            <li>
              No puedes publicar información privada o confidencial ni realizar actividades que infrinjan los derechos de otra persona, incluidos los derechos de propiedad intelectual.
            </li>
            <li>
              En la plataforma, no se aceptan actividades ni personas que apoyen o elogien el terrorismo, el crimen organizado o grupos que promuevan el odio. Tampoco se permiten la oferta de servicios sexuales ni la compra o venta de armas de fuego, alcohol y productos de tabaco entre personas, ni la compra o la venta de medicamentos recetados o ilegales (incluso si es legal en tu zona). 
            </li>
            <li>
              No se puede utilizar la plataforma para realizar ventas de ningún tipo.
            </li>
            <li>
              No tenemos ningún tipo de tolerancia con aquellas personas que comparten contenido sexual relacionado con menores o que amenazan con publicar imágenes íntimas de otros usuarios.
            </li>
            <li>
              Retiramos cualquier contenido que incluya amenazas creíbles o lenguaje que incite al odio, contenido dirigido a particulares con el fin de humillarlos o avergonzarlos, información personal utilizada para chantajear o acosar a alguien y mensajes no deseados reiterativos. Por lo general, permitimos debates más críticos en torno a personas públicas o muy populares debido a su profesión o actividad. 
            </li>
            <li>
              En ningún caso es aceptable fomentar la violencia ni atacar a alguien por razones de raza, etnia, nacionalidad, sexo, identidad sexual, orientación sexual, creencias religiosas, discapacidad o enfermedad. Cabe la posibilidad de que permitamos el uso de lenguaje que incite al odio si se utiliza con el fin de cuestionar este tipo de comportamientos o para poner de manifiesto estos temas.
            </li>
            <li>
              No se permiten amenazas graves a la seguridad pública y personal. Estas amenazas incluyen amenazas específicas contra la integridad física y amenazas de robo, vandalismo y otros perjuicios financieros. Revisamos meticulosamente los reportes de amenazas y tenemos en cuenta muchos factores a la hora de determinar si son creíbles. 
            </li>
          </ul>

          <p>
            <strong>Permisos que nos concedes</strong>. Como parte de nuestro
            acuerdo, también nos concedes los permisos que necesitamos para
            proporcionarte el Servicio.
          </p>
          <ul>
            <li>
              No reclamamos la propiedad sobre tu contenido, pero nos concedes una licencia para usarlo.
            </li>
            <li>
              Nada cambia respecto de tus derechos sobre tu contenido. No reclamamos la propiedad del contenido que publicas en el Servicio o por medio de él. En lugar de ello, cuando compartes, publicas o subes contenido que se encuentra protegido por derechos de propiedad intelectual (como fotos o videos) en nuestro Servicio, o en relación con este, por el presente nos otorgas una licencia internacional, sublicenciable, transferible, libre de regalías y no exclusiva para alojar, usar, distribuir, modificar, administrar, copiar, mostrar o exhibir públicamente y traducir tu contenido, así como para crear trabajos derivados de él (de conformidad con tu configuración de privacidad y de la aplicación).
            </li>
            <li>
              Permiso para usar tu nombre de usuario, perfil e información sobre tus relaciones y las acciones que realizas con cuentas, anuncios y contenido patrocinado.
            </li>
            <li>
              Nos concedes permiso para mostrar tu nombre de usuario, perfil e información sobre las acciones que realizas. 
            </li>
            <li>
              Aceptas que podemos descargar e instalar actualizaciones del Servicio en tu dispositivo.
            </li>
          </ul>

          <p>
            <strong>Derechos adicionales que nos reservamos</strong>
          </p>
          <ul>
            <li>
              Si seleccionas un nombre de usuario o un identificador similar para tu cuenta, podemos cambiarlo si lo consideramos apropiado o necesario. Por ejemplo, si infringe los derechos de propiedad intelectual de otra persona o suplanta la identidad de otro usuario.
            </li>
            <li>
              Si usas contenido protegido por derechos de propiedad intelectual que poseemos e incluimos en nuestro Servicio, tales como imágenes, diseños, videos o sonidos que ofrecemos y que tú agregas al contenido que creas o compartes, nos reservamos todos los derechos sobre dicho contenido, pero no sobre los tuyos.
            </li>
            <li>
              No puedes usar nuestra propiedad intelectual ni marcas comerciales.
            </li>
          </ul>

          <p>
            <strong>
              Eliminación de contenido e inhabilitación o cancelación de la
              cuenta
            </strong>
          </p>
          <ul>
            <li>
              Podemos eliminar cualquier contenido o información que compartas en el Servicio si consideramos que infringe estas Condiciones de uso o nuestras políticas o si la ley lo exige o nos lo permite. Podemos negarnos a proporcionarte o dejar de proporcionarte la totalidad o una parte del Servicio (incluido cancelar o inhabilitar tu cuenta) inmediatamente para proteger nuestra comunidad o nuestros servicios, o bien si supones para nosotros un riesgo o una exposición legal, infringes estas Condiciones de uso o nuestras políticas.
            </li>
          </ul>

          <p>
            <strong>
              Nuestro acuerdo y qué ocurre si no estamos de acuerdo con las disposiciones.
            </strong>
          </p>

          <ul>
            <li>
              Si no se puede ejecutar alguna parte de este acuerdo, el resto continuará en vigor.
            </li>
            <li>
              Cualquier modificación a nuestro acuerdo o exención de su cumplimiento deberá hacerse por escrito y estar firmada por nosotros. Si no cumplimos alguna parte de este acuerdo, ello no se considerará una exención a su cumplimiento.
            </li>
            <li>
              Nos reservamos todos los derechos que no te hayamos concedido de forma expresa.
            </li>
          </ul>

          <p>
            <strong>Quién asume la responsabilidad si ocurre algo.</strong>
          </p>
          <ul>
            <li>
              Nuestro Servicio se proporciona "tal como está", y no podemos garantizar que será seguro o funcionará correctamente en todo momento. EN LA MEDIDA EN QUE LA LEY LO PERMITA, TAMBIÉN RENUNCIAMOS A TODA GARANTÍA, EXPRESA O IMPLÍCITA, INCLUIDAS LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN FIN PARTICULAR, TÍTULO Y NO INFRACCIÓN.
            </li>
            <li>
              Tampoco controlamos lo que las personas u otros hacen o dicen, ni somos responsables por sus acciones, conductas (dentro o fuera de internet) o contenidos (ni por los tuyos), incluido contenido ilegal o cuestionable. Tampoco somos responsables por los servicios y las funciones que otras personas o empresas ofrecen, incluso si accedes a ellos mediante nuestro Servicio.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Conduct;
