import React, { Component } from "react";
import PropTypes from "prop-types";
import CheckIcon from "../icon/check";

export default class Alert extends Component {
  render() {
    return (
      <div className={`o-alert ${this.props.className}`}>
        <CheckIcon width={14} />
        {/* <ErrorIcon width={14} /> */}
        <span>{this.props.text}</span>
      </div>
    );
  }
}

Alert.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};
