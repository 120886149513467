import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollTrigger from '@terwanerik/scrolltrigger';
import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";

function About(props) {
  const { t } = useTranslation();
  useEffect(()=>{
    const trigger = new ScrollTrigger({
      trigger: {
        // If the trigger should just work one time
        once: true,
        offset: {
          element: {
            x: 0,
            y: (trigger, rect, direction) => {
              return 0.1
            }
          },
          viewport: {
            x: 0,
            y: (trigger, frame, direction) => {
              return trigger.visible ? 0 : 0.1
            }
          }
        }
      }
    })
    trigger.add('[data-slideleft]')
           .add('[data-slideright]')
           .add('[data-slidebottom]')
  },[])

  return (
    <>
      <Header stylePage={"is-gradient"} />
      <div className="c-landing is-about">
        <section className="c-about__hero">
          <div className="c-about-hero__wrapper">
            <h1 className="c-about-hero__title c-landing-hero__title">
              {t("about.title")}
            </h1>
            <p className="c-home-hero__text">{t("about.desc")}</p>
            <Link to="/request-demo" className="o-button--lg o-button--green">{t("home.main_cta")}</Link>
          </div>
          <figure className="c-about-hero__video">
            <video
              muted
              loop
              autoPlay
              className=""
              playsInline
            >
              <source
                src="https://s3.amazonaws.com/boletia-v2/uploads/neerme/AboutIntro.webm"
                type="video/webm"
              />
              <source
                src="https://s3.amazonaws.com/boletia-v2/uploads/neerme/AboutIntroSmall.mp4"
                type="video/mp4"
              />
              <source
                src="https://s3.amazonaws.com/boletia-v2/uploads/neerme/AboutIntro.mp4"
                type="video/mp4"
              />
            </video>
          </figure>
        </section>
        <section className="c-about__intro">
          <div className="c-about-intro__wrapper" data-slidebottom>
            <span className="c-landing-slides__emoji" role="img" aria-label="rocket">🚀</span>
            <h1>{t("about.intro.title")}</h1>
            <div className="c-about-intro__column">
              <p>{t("about.intro.text1")}</p>
              <p>{t("about.intro.text2")}</p>
            </div>
            <div className="c-about-intro__column">
              <p>{t("about.intro.text3")}</p>
            </div>
          </div>  
        </section>
        <section className="c-landing-slides">
          <article className="c-landing-slides__single">
            <div className="c-landing-slides__video" data-slideright>
              <video 
                muted 
                loop 
                autoPlay 
                playsInline
                poster="https://boletia-v2.s3.amazonaws.com/uploads/neerme/DrakeBell.jpg"
              >
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/DrakeBell.webm"
                  type="video/webm"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/DrakeBellLow.mp4"
                  type="video/mp4"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/DrakeBell.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="c-landing-slides__content" data-slideleft>
              <span className="c-landing-slides__emoji" role="img" aria-label="fireworks">🎇</span>
              <h1 className="c-home__title">{t("about.list.title")}</h1>
              <ul className="c-landing-slides__list">
                <li>{t("about.list.text1")}</li>
                <li>{t("about.list.text2")}</li>
                <li>{t("about.list.text3")}</li>
                <li>{t("about.list.text4")}</li>
                <li>{t("about.list.text5")}</li>
                <li>{t("about.list.text6")}</li>
              </ul>
            </div>
          </article>
        </section>
        <section className="c-about__phrase">
          <div className="c-about-phrase__wrapper" data-slidebottom>
            <div className="c-about-phrase__title">{t("about.phrase.title")}</div>
            <Link to="/request-demo" className="o-button--lg o-button--purple">
              {t("home.main_cta")}
            </Link>
          </div>
        </section>
        <section className="c-landing-stories">
          <div className="c-landing-stories__wrapper" data-slideright>
            <div className="c-landing-stories__content">
              <h1 className="c-home__title">{t("about.history.title")}</h1>
            </div>
          </div>
          <div className="c-landing-stories__list c-about-stories" data-slideleft>
            <p>{t("about.history.text1")}</p>

            <p>{t("about.history.text2")}</p>

            <p>{t("about.history.text3")}</p>

            <h2 className="c-landing-stories__quote">{t("about.history.text4")}</h2>

            <p>{t("about.history.text5")}</p>

            <figure className="c-about-stories__figure">
              <img src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/NeermeStoryAbout.jpg" alt="Our site now" />
            </figure>

            <p>{t("about.history.text6")}</p>

            <h2 className="c-landing-stories__quote">{t("about.history.text7")}</h2>

            <p>{t("about.history.text8")}</p>

            <p>{t("about.history.text9")}</p>

            <p>{t("about.history.text10")}</p>
          </div>
        </section>
        <section className="c-landing-magnet c-whyneerme-magnet">
          <div className="c-landing-magnet__wrapper" data-slidebottom>
            <h2 className="c-landing-magnet__title">{t("home.magnet.title")}</h2>
            <p className="c-home__text">{t("home.magnet.desc")}</p>
            <Link to="/request-demo" className="o-button--green o-button--lg">
              {t("home.main_cta")}
            </Link>
          </div>  
        </section>
      </div>
      <Footer />
    </>
  );
}

export default About;
