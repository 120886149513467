import axios from "axios";

import { BASE_URL, USER_DATA } from "../endpoints";

export async function getUserData(token) {
  return axios.get(`${BASE_URL}${USER_DATA}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
