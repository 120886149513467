import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";

import InputField from "components/shared/InputField";
import BoletiaLogo from "components/shared/icon/boletia_logo";
import NeermeLogo from "components/shared/icon/neerme_logo";

import {
  getUserDataEmailToken,
  setValidAccountToken,
} from "api/emailConfirmation";

function Login() {
  const [userData, setUserData] = useState({});
  const [newPassword, setNewPassword] = useState("");
  const [passwordHasError, setPasswordHasError] = useState(true);

  const history = useHistory();
  const { emailToken } = useParams();

  const buttonSubmit = useRef();

  function validatePassword() {
    const MIN_PASSWORD_LENGHT = 8;
    const passwordLength = newPassword.length < MIN_PASSWORD_LENGHT;
    const submitButtonRef = buttonSubmit.current;

    setPasswordHasError(passwordLength);

    if (passwordLength) {
      submitButtonRef.setAttribute("disabled", "disabled");
    } else {
      submitButtonRef.removeAttribute("disabled");
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    const payload = generatePayload();
    setValidAccountToken(payload)
      .then((response) => {
        history.push("/login?activation=success");
      })
      .catch((error) => {});
  }

  function handlePassword(event) {
    const password = event.target.value;
    setNewPassword(password);
  }

  function generatePayload() {
    return {
      invitation_token: emailToken,
      password: newPassword,
    };
  }

  useEffect(() => {
    getUserDataEmailToken(emailToken).then((response) => {
      const { data } = response.data;
      if (data.id === 0) {
        history.push("/login");
      }
      setUserData(data);
    });
  }, [emailToken, history]);

  useEffect(() => {
    validatePassword();
  });

  const { first_name, last_name, email } = userData;

  return (
    <section className="c-login__container">
      <div className="c-login">
        <NeermeLogo width={120} />
        <div className="c-login__form">
          <h2 className="c-login__title">Activa tu cuenta</h2>
          <div className="o-field">
            <label className="c-login__label">Nombre</label>
            <h3>{first_name}</h3>
          </div>

          <div className="o-field">
            <label className="c-login__label">Apellidos</label>
            <h3>{last_name}</h3>
          </div>

          <div className="o-field">
            <label className="c-login__label">Correo Electrónico</label>
            <h3>{email}</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <InputField
              label="Crea tu contraseña (Mínimo 8 caracteres)"
              classLabel="c-login__label"
              name="newPassword"
              type="password"
              autoComplete="new-password"
              placeholder=""
              maxLength="50"
              errorText="Necesitas al menos 8 caracteres para crear tu contraseña"
              onChange={handlePassword}
              haserror={passwordHasError}
              required
            />
            <button
              ref={buttonSubmit}
              type="submit"
              className="o-button--blue o-button--lg"
            >
              Activa tu cuenta
            </button>
          </form>
        </div>

        <div className="c-login__powered">
          <span>Un producto de</span>
          <a
            href="https://boletia.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            <BoletiaLogo width={80} />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Login;
