import React from "react";
import { useTranslation } from "react-i18next";

import IconCrown from "components/shared/icon/crown_filled";

function ChatAlert(props) {
  const { t } = useTranslation(["watch"]);
  const { nickname, level_name } = props;
  return (
    <div className="c-chat__message-wrapper">
      <div className="c-chat__message is-alert">
        <figure className="c-chat__badge is-top-fan">
          <IconCrown width={12} />
        </figure>
        <span>
          {t("chat.exclamation")}<strong>{nickname}</strong> {t("chat.alert1")}
          <strong> {level_name}</strong>!
        </span>
      </div>
    </div>
  );
}

export default ChatAlert;
