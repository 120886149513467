import React, { useRef, useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";

import { tryDataLayerPush } from "utils/gtm";
import { useAuth } from "contexts/authContext";
import { useChannelWebsocketContext } from "contexts/channelWebsocket";
import { useChatWebsocketContext } from "contexts/chatWebsocket";
import { useEventComponentsContext } from "contexts/EventComponentsContext";
import { useLevelStoreContext } from "contexts/LevelStoreContext";
import { useWatchEventContext } from "contexts/WatchEventContext";
import LevelUpBadge from "components/layouts/HeaderWatch/LevelUpBadge";
import SendIcon from "components/shared/icon/send";
import IconLock from "components/shared/icon/lock";

import "emoji-mart/css/emoji-mart.css";
import { isObject } from "utils/dataStructures";

import reducer, { initialNicknameByToken } from "./store/reducer";
import { setNicknameByToken } from "./store/actions";

const VALID_USERNAME_REGEX = RegExp("^[a-zA-Z0-9_]{4,15}$");

function EmptyChat(props) {
  const { t } = useTranslation(["watch"]);
  const { isChatV2, isUpdate, showUpdateHandler } = props;
  const {
    handleChatUsername,
    handleChatAvatar,
    chatUsername,
    chatAvatar,
  } = useAuth();
  const { accessCode } = useWatchEventContext();
  const { connectionData } = useChannelWebsocketContext();
  const { joinChat } = useChatWebsocketContext();
  const { showLevels } = useLevelStoreContext();
  const { avatarComponents } = useEventComponentsContext();

  const { is_organizer } = connectionData;

  const [isUsernameValid, setUsernameValid] = useState(isUpdate && chatAvatar);
  const [selectedAvatar, setSelectedAvatar] = useState(
    isUpdate && chatAvatar ? chatAvatar : "bot-6"
  );
  // eslint-disable-next-line
  const [nicknameState, dispatchEvent] = useReducer(
    reducer,
    initialNicknameByToken
  );

  const inputUsername = useRef();

  useEffect(() => {
    if (isUpdate) {
      try {
        inputUsername.current.value = chatUsername.split("#")[0].split("@")[0];
      } catch (error) {
        inputUsername.current.value = "";
      }
    }
    // eslint-disable-next-line
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    if (!isUsernameValid) return;
    tryDataLayerPush({
      event: "start-chatting-button-click",
    });
    const usernameClean = inputUsername.current.value;
    if (accessCode) {
      const data = {
        nickname: usernameClean,
      };
      setNicknameByToken({ token: accessCode, payload: data }, dispatchEvent);
    }
    const usernameAuthFlag = `${usernameClean}@${
      is_organizer ? "admin" : "user"
    }`;
    handleChatUsername(usernameAuthFlag);
    handleChatAvatar(selectedAvatar);
    if (isChatV2) {
      joinChat();
    }
    if (isUpdate) {
      showUpdateHandler(false);
    }
  }

  function handleUsernameChange(event) {
    event.preventDefault();
    const usernameValue = event.target.value;
    if (usernameValue === "" || !VALID_USERNAME_REGEX.test(usernameValue)) {
      setUsernameValid(false);
    } else {
      setUsernameValid(true);
    }
  }

  function handleSelectAvatar(event) {
    setSelectedAvatar(event.target.value);
  }

  function AvatarListElement(props) {
    const { url, name, locked } = props;

    function levelClick(event) {
      event.preventDefault();
      showLevels();
      tryDataLayerPush({
        event: "level-up-avatar-locked-click",
      });
    }

    if (locked) {
      return (
        <li className="c-avatar__single" data-tooltip={t("chat.level_up")}>
          <a href="/" className="c-avatar" onClick={levelClick}>
            <img alt={name} src={url} />
            <div className="c-avatar__lock">
              <IconLock width={14} />
            </div>
          </a>
        </li>
      );
    } else {
      return (
        <li className="c-avatar__single">
          <input
            type="radio"
            name="avatar"
            id={name}
            value={name}
            onClick={handleSelectAvatar}
            defaultChecked={name === selectedAvatar}
          />
          <label className="c-avatar" htmlFor={name}>
            <img alt={name} src={url} />
          </label>
        </li>
      );
    }
  }
  function handleCancel(event) {
    event.preventDefault();
    showUpdateHandler(false);
  }
  return (
    <div className="c-chat__empty">
      <h5 className="c-empty__title">
        {t("chat.username")}
        <small className="c-empty__text">
          {!isUsernameValid && t("chat.requirements")}
        </small>
      </h5>

      <form className="MessageForm" onSubmit={handleSubmit}>
        <div className="o-field">
          <input
            type="text"
            maxLength="15"
            ref={inputUsername}
            onChange={handleUsernameChange}
            placeholder={t("chat.example")}
          />
        </div>

        {avatarComponents.marketableDisabled.map((avatarComponent) => {
          const avatars = avatarComponent.properties?.avatars;

          return (
            <div className="c-chat__upgrade-wrapper">
              <h5 className="c-empty__title">{t("chat.get_exclusive")}</h5>
              <React.Fragment key={avatarComponent.id}>
                <h6 className="c-chat__title">{avatarComponent.name}</h6>
                <ul className="c-avatar__container is-top-fan">
                  {isObject(avatars) &&
                    Object.keys(avatars).map((avatar, idx) => (
                      <AvatarListElement
                        key={idx}
                        url={avatars[avatar]}
                        name={avatar}
                        locked
                      />
                    ))}
                </ul>
              </React.Fragment>
              <LevelUpBadge avatarEvent />
            </div>
          );
        })}
        {avatarComponents.marketableDisabled.length === 0 ? (
          <h5 className="c-empty__title">{t("chat.choose_avatar")}</h5>
        ) : (
          <h5 className="c-empty__title">{t("chat.free_avatar")}</h5>
        )}
        {avatarComponents.rest.map((avatarComponent) => {
          const avatars = avatarComponent.properties?.avatars;

          return (
            <React.Fragment key={avatarComponent.id}>
              <h6 className="c-chat__title">{avatarComponent.name}</h6>
              <ul className="c-avatar__container is-neerme">
                {isObject(avatars) &&
                  Object.keys(avatars).map((avatar, idx) => (
                    <AvatarListElement
                      key={idx}
                      url={avatars[avatar]}
                      name={avatar}
                    />
                  ))}
              </ul>
            </React.Fragment>
          );
        })}
        <div className="c-empty__button-wrapper">
          <button
            type="submit"
            className="c-empty__button o-button--green o-button--bs"
          >
            <SendIcon width={16} />
            {t("chat.start")}
          </button>

          <button
            type="submit"
            className="c-empty__button o-button--link o-button--bs"
            onClick={handleCancel}
          >
            {t("chat.cancel")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default EmptyChat;
