export const GET_XSOLLA_ORDER_STATUS_REQUESTED =
  "GET_XSOLLA_ORDER_STATUS_REQUESTED";
export const GET_XSOLLA_ORDER_STATUS_FULFILLED =
  "GET_XSOLLA_ORDER_STATUS_FULFILLED";
export const GET_XSOLLA_ORDER_STATUS_REJECTED =
  "GET_XSOLLA_ORDER_STATUS_REJECTED";

export const SET_XSOLLA_ORDER_STATUS_REQUESTED =
  "SET_XSOLLA_ORDER_STATUS_REQUESTED";
export const SET_XSOLLA_ORDER_STATUS_FULFILLED =
  "SET_XSOLLA_ORDER_STATUS_FULFILLED";
export const SET_XSOLLA_ORDER_STATUS_REJECTED =
  "SET_XSOLLA_ORDER_STATUS_REJECTED";
