import React from "react";

const SVG = ({
  style = {},
  fill = "currentColor",
  width = "100%",
  height = "100%",
  className = "is-loading",
  viewBox = "0 0 24 24"
}) => (
  <svg
    version="1.1"
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={`c-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    enableBackground="new 0 0 24 19" 
  >

    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M23.7403,10.5273 C23.3693,10.1193 22.7353,10.0893 22.3273,10.4603 L21.4623,11.2473 C21.0763,6.3603 16.9843,2.5003 12.0003,2.5003 C9.5543,2.5003 7.2313,3.4273 5.4583,5.1103 C5.0583,5.4913 5.0423,6.1243 5.4223,6.5253 C5.8043,6.9253 6.4363,6.9403 6.8363,6.5613 C8.2353,5.2323 10.0693,4.5003 12.0003,4.5003 C15.9533,4.5003 19.1943,7.5763 19.4723,11.4603 L18.3733,10.4603 C17.9663,10.0893 17.3323,10.1193 16.9603,10.5273 C16.5893,10.9363 16.6193,11.5683 17.0273,11.9403 L19.6773,14.3493 C19.6953,14.3673 19.7193,14.3733 19.7393,14.3873 C19.7853,14.4233 19.8353,14.4513 19.8863,14.4783 C19.9533,14.5133 20.0183,14.5433 20.0903,14.5613 C20.1143,14.5683 20.1333,14.5853 20.1583,14.5893 C20.2193,14.6013 20.2793,14.6063 20.3393,14.6073 C20.3433,14.6073 20.3453,14.6093 20.3493,14.6093 C20.4013,14.6093 20.4503,14.5893 20.5013,14.5823 C20.5653,14.5713 20.6313,14.5683 20.6903,14.5473 C20.7383,14.5293 20.7783,14.4963 20.8243,14.4713 C20.8833,14.4393 20.9433,14.4103 20.9943,14.3673 C21.0033,14.3593 21.0133,14.3573 21.0223,14.3493 L23.6733,11.9403 C24.0813,11.5693 24.1113,10.9363 23.7403,10.5273" id="Fill-1" fill={fill}></path>
      <path d="M18.8252,17.1631 C18.4262,16.7811 17.7932,16.7921 17.4112,17.1921 C15.9832,18.6811 14.0612,19.5001 12.0002,19.5001 C8.0842,19.5001 4.8642,16.4821 4.5332,12.6511 L5.6272,13.6451 C5.8192,13.8191 6.0592,13.9051 6.2992,13.9051 C6.5712,13.9051 6.8422,13.7951 7.0392,13.5781 C7.4112,13.1691 7.3812,12.5371 6.9722,12.1651 L4.3232,9.7561 C4.2902,9.7271 4.2502,9.7151 4.2162,9.6911 C4.1842,9.6681 4.1522,9.6501 4.1182,9.6311 C4.0432,9.5901 3.9692,9.5601 3.8882,9.5401 C3.8612,9.5331 3.8392,9.5141 3.8092,9.5091 C3.7812,9.5041 3.7552,9.5131 3.7272,9.5111 C3.6682,9.5061 3.6122,9.5081 3.5532,9.5141 C3.4722,9.5201 3.3962,9.5321 3.3212,9.5571 C3.2982,9.5641 3.2772,9.5761 3.2542,9.5861 C3.1532,9.6281 3.0622,9.6821 2.9802,9.7541 C2.9792,9.7551 2.9782,9.7551 2.9772,9.7561 L0.3272,12.1651 C-0.0818,12.5371 -0.1118,13.1691 0.2602,13.5781 C0.6312,13.9861 1.2632,14.0171 1.6732,13.6451 L2.5432,12.8541 C2.9772,17.6931 7.0502,21.5001 12.0002,21.5001 C14.6112,21.5001 17.0462,20.4621 18.8542,18.5771 C19.2362,18.1791 19.2232,17.5461 18.8252,17.1631" id="Fill-4" fill={fill}></path>
      <animateTransform 
         attributeName="transform" 
         attributeType="XML" 
         type="rotate"
         dur=".8s" 
         from="0 12 12"
         to="360 12 12" 
         repeatCount="indefinite" />
    </g>
  </svg>
);

export default SVG;
