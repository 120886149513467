import React, { createContext, useContext, useEffect, useRef } from "react";

import useWebSocket from "react-use-websocket";

import { setMessage } from "utils/webSocketHelper";

const ROUTE_USERS_CHANNEL_SUBSCRIBE = "v1/users/channel/subscribe";

const SOCKET_URL = process.env.REACT_APP_CHAT_SOCKET_URL;

export const ChatWebsocketContext = createContext({});

export function ChatWebsocketProvider(props) {
  const { children, joinChatData } = props;

  const userJoinedChat = useRef(false);

  // const socketOptions = useMemo(
  //   () => ({
  //     shouldReconnect: (closeEvent) => true,
  //     reconnectAttempts: 10,
  //     reconnectInterval: 3000,
  //   }),
  //   []
  // );

  const {
    sendMessage,
    lastMessage,
    readyState,
    getWebSocket,
    lastJsonMessage,
  } = useWebSocket(SOCKET_URL, {
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onOpen: () => {
      const chatJoinMessage = setMessage(
        ROUTE_USERS_CHANNEL_SUBSCRIBE,
        joinChatData
      );
      sendMessage(chatJoinMessage);
    },
  });

  function joinChat() {
    const chatJoinMessage = setMessage(
      ROUTE_USERS_CHANNEL_SUBSCRIBE,
      joinChatData
    );
    if (!userJoinedChat.current) {
      userJoinedChat.current = true;
      sendMessage(chatJoinMessage);
    }
  }

  useEffect(() => {
    const PING = setInterval(() => {
      sendMessage(`{"action": "ping"}`);
    }, 10000);

    return () => {
      clearInterval(PING);
    };
    // eslint-disable-next-line
  }, []);

  //   useEffect(() => {
  //     const chatJoinMessage = setMessage(
  //       ROUTE_USERS_CHANNEL_SUBSCRIBE,
  //       joinChatData
  //     );
  //     if (readyState === ReadyState.OPEN && !userJoinedChat.current) {
  //       userJoinedChat.current = true;
  //       sendMessage(chatJoinMessage);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [joinChatData, readyState]);

  const context = {
    sendMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
    joinChat,
    userJoined: userJoinedChat.current,
  };
  return (
    <ChatWebsocketContext.Provider value={context}>
      {children}
    </ChatWebsocketContext.Provider>
  );
}

export function useChatWebsocketContext() {
  return useContext(ChatWebsocketContext);
}
