import React from "react";

import { useEventComponentsContext } from "contexts/EventComponentsContext";

const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
const renderText = (txt) =>
  txt.split(" ").map((part) =>
    URL_REGEX.test(part) ? (
      <a href={part} target="_blank" rel="noopener noreferrer">
        {part}{" "}
      </a>
    ) : (
      part + " "
    )
  );

function Message(props) {
  const { me, author, message, avatar } = props;
  const {
    stickerComponents,
    avatarComponents,
    // badgesComponents,
  } = useEventComponentsContext();

  function separatedAuthor() {
    let badges, userAndID, roleAndUser, badgesAndID, username, ID, isAdmin;
    try {
      userAndID = author.split("#");
      roleAndUser = userAndID[0].split("@");
      badgesAndID = userAndID[1].split("|");
    } catch (error) {
      badgesAndID = [];
    }
    username = roleAndUser[0];
    ID = badgesAndID[0];
    isAdmin = roleAndUser[1] === "admin";

    try {
      badges =
        badgesAndID[1] !== "" && badgesAndID !== undefined
          ? badgesAndID[1].split(",")
          : [];
    } catch (error) {
      badges = [];
    }

    return [username, `#${ID}`, isAdmin, badges];
  }

  function setAvatarURL(avatarName) {
    return avatarComponents.rawAvatars[avatarName];
  }

  function ParsedMessage() {
    if (stickerComponents.rawStickers[message]) {
      return (
        <img
          width="64"
          src={stickerComponents.rawStickers[message]}
          alt={message}
        />
      );
    } else {
      return <>{renderText(message)}</>;
    }
  }

  const username = author ? separatedAuthor()[0] : null;
  const hashtagId = author ? separatedAuthor()[1] : null;
  const isAdmin = author ? separatedAuthor()[2] : null;
  const badges = author ? separatedAuthor()[3] : [];
  const avatarURL = avatar ? setAvatarURL(avatar) : setAvatarURL("bot-6");
  const hasBadge = badges.length > 0;
  const userTypeClass = isAdmin
    ? "is-admin"
    : hasBadge
    ? "is-top-fan"
    : me
    ? "is-me"
    : "is-guest";

  return (
    <div className={`c-chat__message-wrapper ${userTypeClass}`}>
      <figure className="c-chat__avatar">
        <img src={avatarURL} alt={username} />
      </figure>
      <div className={`c-chat__message ${userTypeClass}`}>
        {/*
          {badges.map((badge) => {
            return (
              <figure className="c-chat__badge is-top-fan" key={badge}>
                <IconCrown width={12} alt={badgesComponents.rawBadges[badge]} />
              </figure>
            );
          })}
        */}

        {author && (
          <span className="c-chat__author">
            {username}
            <small>{hashtagId}:</small>
          </span>
        )}
        <ParsedMessage />
      </div>
    </div>
  );
}

export default Message;
