import React from "react";

function ModalIcon(props) {
  const { children, active } = props;
  return (
    <div className={`c-upgrade__modal ${active ? "c-upgrade__active" : "close"}`}>
      <div className="c-upgrade is-large">
          {children}
      </div>
    </div>
  );
}

export default ModalIcon;
