import Home from "components/pages/Home";
import EventList from "components/pages/EventList";
import WhyNeerme from "components/pages/WhyNeerme";
import ArtistManagers from "components/pages/WhosItFor/ArtistManagers";
import ContentCreators from "components/pages/WhosItFor/ContentCreators";
import Musicians from "components/pages/WhosItFor/Musicians";
import Comedians from "components/pages/WhosItFor/Comedians";
import TheaterProducers from "components/pages/WhosItFor/TheaterProducers";
import RequestDemo from "components/pages/RequestDemo";
import About from "components/pages/About";
import StoriesComedy from "components/pages/CaseStories/Comedy";
import StoriesDigitalContent from "components/pages/CaseStories/DigitalContent";
import StoriesCorporate from "components/pages/CaseStories/Corporate";
import StoriesTheater from "components/pages/CaseStories/Theater";
import StoriesMusic from "components/pages/CaseStories/Music";
import EventTypes from "components/pages/EventTypes";
import EventPage from "components/pages/EventPage";
import TermsBehaviour from "components/pages/TermsBehaviour";
import TermsConditions from "components/pages/TermsConditions";
import TermsPurchase from "components/pages/TermsPurchase";
import Troubleshooting from "components/pages/Troubleshooting";
import StreamingHelp from "components/pages/StreamingHelp";
import InboundNeerme from "components/pages/InboundNeerme";
import Banned from "components/pages/Banned";
import MultipleConnections from "components/pages/MultipleConnections";
import Watch from "components/pages/Watch";

const publicRoutes = [
  {
    name: "home",
    route: "/",
    component: Home,
  },
  {
    name: "event-list",
    route: "/event-list",
    component: EventList,
  },
  {
    name: "whyneerme",
    route: "/why-neerme",
    component: WhyNeerme,
  },
  {
    name: "artistmanagers",
    route: "/whos-it-for/artist-managers",
    component: ArtistManagers,
  },
  {
    name: "contentcreator",
    route: "/whos-it-for/content-creators",
    component: ContentCreators,
  },
  {
    name: "musicians",
    route: "/whos-it-for/musicians",
    component: Musicians,
  },
  {
    name: "comedians",
    route: "/whos-it-for/comedians",
    component: Comedians,
  },
  {
    name: "theaterproducers",
    route: "/whos-it-for/theater-producers",
    component: TheaterProducers,
  },
  {
    name: "requestdemo",
    route: "/request-demo",
    component: RequestDemo,
  },
  {
    name: "about",
    route: "/about",
    component: About,
  },
  {
    name: "studies-comedy",
    route: "/case-studies/comedy",
    component: StoriesComedy,
  },
  {
    name: "studies-digital-content",
    route: "/case-studies/digital-content",
    component: StoriesDigitalContent,
  },
  {
    name: "studies-corporate",
    route: "/case-studies/corporate",
    component: StoriesCorporate,
  },
  {
    name: "studies-theater",
    route: "/case-studies/theater",
    component: StoriesTheater,
  },
  {
    name: "studies-music",
    route: "/case-studies/music",
    component: StoriesMusic,
  },
  {
    name: "event-types",
    route: "/event-types",
    component: EventTypes,
  },
  {
    name: "termsbehaviour",
    route: "/codigo-de-conducta",
    component: TermsBehaviour,
  },
  {
    name: "termspurchase",
    route: "/terminos-de-compra",
    component: TermsPurchase,
  },
  {
    name: "termsconditions",
    route: "/terminos-y-condiciones",
    component: TermsConditions,
  },
  {
    name: "troubleshooting",
    route: "/help",
    component: Troubleshooting,
  },
  {
    name: "streaminghelp",
    route: "/streaming-help",
    component: StreamingHelp,
  },
  {
    name: "banned",
    route: "/banned",
    component: Banned,
  },
  {
    name: "multiple",
    route: "/multiple",
    component: MultipleConnections,
  },
  {
    name: "inbound-neerme",
    route: "/inbound-neerme",
    component: InboundNeerme,
  },

  {
    name: "event",
    route: "/:eventSubdomain",
    component: EventPage,
  },
  {
    name: "event",
    route: "/:eventSubdomain/:accessCode",
    component: Watch,
  },
];

export default publicRoutes;
