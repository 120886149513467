export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL_V2 = process.env.REACT_APP_BASE_URL_V2;
export const BASE_URL_V3 = process.env.REACT_APP_BASE_URL_V3;
export const BASE_URL_POLLS = process.env.REACT_APP_BASE_URL_POLLS;

// BASE_URL = https://live-events.boletiaapi.com/
// BASE_URL_V2 = https://api.neerme.io/
// BASE_URL_V3 =  https://api-plus.neerme.io/
// BASE_URL_POLLS =  https://polls.neerme.io/api/v1/polls/

export const CONFIRM_EMAIL_LAMBDA = `users/account-confirm`;
export const CONFIRM_EMAIL_USER_DATA = (token) =>
  `${CONFIRM_EMAIL_LAMBDA}?invitation_token=${token}`;

export const USER_LOGIN = `login`;
export const USER_LOGOUT = `logout`;
export const USER_EVENTS = `users/events`;
export const USER_DATA = `users/info`;

export const USER_TOKEN_CHAT = `users/chat-token`;

export const POLL_LIST = (event_subdomain) =>
  `polls/list/subdomain/${event_subdomain}`;
export const POLL_CREATE = `polls/create/`;
export const POLL_DETAIL = (pollID) => `${pollID}`;
export const POLL_VOTE = (pollID) => `${pollID}/vote`;
export const POLL_SWITCH = (pollID) => `${pollID}/switch`;

export const EVENT_DETAIL = (eventSubdomain) => `event/${eventSubdomain}`;
export const EVENT_LIST = `events`;

export const EVENT_ACCESS_CODE_VALIDATION = `access/token/validation`;
export const EVENT_TOKEN_COMPONENTS = (eventID, accessCode) =>
  `components/event/${eventID}/elements/${accessCode}`;
export const EVENT_TOKEN_LEVELS = (eventID) =>
  `components/event/${eventID}/levels`;

export const CREATE_XSOLLA_TOKEN = "components/event/purchaseorder";
export const SET_NICKNAME_BY_TOKEN = (token) => `access/token/${token}/user`;

export const GET_TICKET_DATA = (token) => `access/token/${token}`;

export const GET_XSOLLA_ORDER_STATUS = (token) => `order/detail/${token}`;
export const SET_XSOLLA_ORDER_STATUS = `order/status/`;

export const VALIDATE_CHAT_BLOCKED = (token, eventSubdomain) => `event/blocked/chat/${token}?event_subdomain=${eventSubdomain}`;
