import axios from "axios";

import {
  BASE_URL,
  CONFIRM_EMAIL_LAMBDA,
  CONFIRM_EMAIL_USER_DATA,
} from "../endpoints";

export async function getUserDataEmailToken(token) {
  return axios.get(`${BASE_URL}${CONFIRM_EMAIL_USER_DATA(token)}`);
}
export async function setValidAccountToken(data) {
  return axios.post(`${BASE_URL}${CONFIRM_EMAIL_LAMBDA}`, data);
}
