import React, { useContext, createContext } from "react";
import { ThemeProvider } from "styled-components";
import { useWatchEventContext } from "contexts/WatchEventContext";
import { useEventComponentsContext } from "contexts/EventComponentsContext";
import Loading from "components/shared/Loading";

const defaultTheme = {
  rootStyle: {
    colors: {
      body: "#1A1A23",
      text: "#fff",
    },
  },
  header: {
    colors: {
      body: "#2B2B3B",
      text: "#fff",
    },
    logo: {
      active: false,
      src: "",
    },
  },
  headerAd: {
    active: false,
    src: "",
    link: "",
    alt: "",
  },
  chat: {
    colors: {
      border: "#FFFFFF",
      body: "#1A1A23",
      text: "#fff",
    },
    header: {
      colors: {
        body: "#1A1A23",
      },
    },
  },
  chatMessage: {
    colors: {
      body: "#3D3D52",
    },
  },
  playerAd: {
    active: false,
    src: "",
    link: "",
    alt: "",
    multi: false,
    colors:{
      body: "#00000"
    }
  },
  titleAd: {
    active: false,
    src: "",
    link: "",
    alt: "",
    multi: false,
  },
  titleEvent: {
    background: {
      active: false,
      src: "",
    },
  },
};

export function ThemeContextProvider(props) {
  const { children } = props;
  const { watchData } = useWatchEventContext();
  const {
    themeComponent,
    themeLoaded,
    componentsState,
    componentsReady,
  } = useEventComponentsContext();
  const defaultContext = {};
  return (
    <ThemeContext.Provider value={defaultContext}>
      {watchData.loading || componentsState.eventComponents.loading ? (
        <Loading />
      ) : componentsReady ? (
        <ThemeProvider
          theme={themeLoaded ? themeComponent?.properties?.theme : defaultTheme}
        >
          {children}
        </ThemeProvider>
      ) : (
        <Loading />
      )}
    </ThemeContext.Provider>
  );
}

const ThemeContext = createContext();

export function useThemeContext() {
  return useContext(ThemeContext);
}
