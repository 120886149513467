import {
  GET_EVENT_TOKEN_COMPONENTS_REQUESTED,
  GET_EVENT_TOKEN_COMPONENTS_FULFILLED,
  GET_EVENT_TOKEN_COMPONENTS_REJECTED,
} from "./types";

export const initialEventComponets = {
  eventComponents: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
};

function reducer(state, action) {
  switch (action.type) {
    case GET_EVENT_TOKEN_COMPONENTS_REQUESTED: {
      const { eventComponents } = action;
      return { ...state, eventComponents };
    }
    case GET_EVENT_TOKEN_COMPONENTS_FULFILLED: {
      const { eventComponents } = action;
      return { ...state, eventComponents };
    }
    case GET_EVENT_TOKEN_COMPONENTS_REJECTED: {
      const { eventComponents } = action;
      return { ...state, eventComponents };
    }

    default:
      return state;
  }
}

export default reducer;
