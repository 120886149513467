import React from "react";
import { useTranslation } from "react-i18next";
// import IconCrown from "components/shared/icon/crown";

function UpgradeFinish(props) {
  const { active, handler, level } = props;

  const { t } = useTranslation(["watch"]);
  function closeModal(event) {
    event.preventDefault();
    handler(false);
  }

  return (
    <>
      <div
        className={`c-upgrade__modal ${active ? "c-upgrade__active" : "close"}`}
      >
        <div className="c-upgrade is-finish">
          <div className="c-upgrade__alert">
            <strong>
              {t("upgrade.alert.title")}
              {/*{t ("upgrade.alert.title_neermepro")}*/}
            </strong>
            {t("upgrade.alert.desc")}
          </div>
          <div className="c-upgrade__finish">
            <figure className="c-upgrade__badge">
              {/* <IconCrown width={56} /> */}
              {/*<IconCheckFilled width={56} />*/}
              <img src={level.icon} alt="" width="56" />
            </figure>
            <h3 className="c-upgrade__title">
              {t("upgrade.finish.title")}
              <span>
                {level.name} {t("upgrade.finish.yours")}
                {/*{t ("upgrade.finish.sticker")}*/}
                {/*{t ("upgrade.finish.neermepro")}*/}
              </span>
            </h3>

            <a
              href="/"
              className="o-button--lg o-button--outline"
              onClick={closeModal}
            >
              {t("upgrade.finish.cta")}
              {/*{t ("upgrade.finish.login")}*/}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpgradeFinish;
