import {
  GET_STRIPE_TOKEN_REQUESTED,
  GET_STRIPE_TOKEN_FULFILLED,
  GET_STRIPE_TOKEN_REJECTED,
  RESET_STRIPE_TOKEN,
} from "./types";

export const initialStripeToken = {
  loading: false,
  success: false,
  error: false,
  data: null,
};
function reducer(state, action) {
  switch (action.type) {
    case GET_STRIPE_TOKEN_REQUESTED: {
      const { stripeToken } = action;
      return { ...state, ...stripeToken };
    }
    case GET_STRIPE_TOKEN_FULFILLED: {
      const { stripeToken } = action;
      return { ...state, ...stripeToken };
    }
    case GET_STRIPE_TOKEN_REJECTED: {
      const { stripeToken } = action;
      return { ...state, ...stripeToken };
    }
    case RESET_STRIPE_TOKEN: {
      const { stripeToken } = initialStripeToken;
      return { ...state, ...stripeToken };
    }

    default:
      return state;
  }
}

export default reducer;
