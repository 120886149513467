import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loading from "components/shared/Loading";
import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";
import { getEvents } from "api/events";

import EventCard from "./EventCard";

const testEvents = ["el-show-de-producto-online", "noisescapes"];
const fbEvents = [];
const excludeEvents = [...testEvents, ...fbEvents];

function Home(props) {
  const { t } = useTranslation();
  const [events, setEvents] = useState([]);
  const [topBannerEvent, setTopBannerEvent] = useState({});
  const [showTopBanner, setShowTopBanner] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let labels = ["home"];
    try {
      //eslint-disable-next-line
      window.zE("webWidget", "show");
      window.zE("webWidget", "helpCenter:setSuggestions", { labels });
    } catch (error) {
      console.log("Zendes undefined", error);
    }
    getEvents()
      .then((response) => {
        const responseEvents = response.data;
        const filteredEvents = responseEvents.filter(
          (event) => !excludeEvents.includes(event.event_subdomain)
        );
        setEvents(filteredEvents);
        const selectTopBanner = filteredEvents.filter(
          (event) => event.top_banner
        );
        if (selectTopBanner.length > 0) {
          setShowTopBanner(true);
          setTopBannerEvent(selectTopBanner[0]);
        }
        setIsLoading(false);
      })
      .catch((error) => {});
  }, []);

  const eventsLenght = events.length;
  return (
    <>
      <Header stylePage={"is-gradient"} />
      <div className="c-layout">
        {showTopBanner && (
          <div className="c-banner__wrapper">
            <figure className="c-banner__blur"></figure>
            <h6 className="c-banner__label">{t("eventlist.miss")}</h6>
            <Link
              to={`/${topBannerEvent.event_subdomain}`}
              className="c-banner__image"
            >
              <img src={topBannerEvent.event_banner} alt="" />
            </Link>
          </div>
        )}

        <ul className="c-event-card__wrapper">
          <h1 className="c-event-card__hero">
            {t("eventlist.title")}
            <small>{t("eventlist.slogan")}</small>
          </h1>
          {isLoading ? (
            <Loading />
          ) : eventsLenght === 0 ? (
            <p>{t("eventlist.no_events")}</p>
          ) : (
            events.map((event, idx) => (
              <EventCard key={idx} eventData={event} />
            ))
          )}
        </ul>
      </div>
      <Footer />
    </>
  );
}

export default Home;
