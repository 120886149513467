import {
  VOTE_POLL_REQUESTED,
  VOTE_POLL_FULFILLED,
  VOTE_POLL_REJECTED,
} from "./types";

export const initialPoll = {
  votePoll: {
    loading: false,
    success: false,
    data: [],
    error: false,
  },
};

export default function reducer(state, action) {
  switch (action.type) {
    case VOTE_POLL_REQUESTED: {
      const { votePoll } = action;
      return { ...state, votePoll };
    }
    case VOTE_POLL_FULFILLED: {
      const { votePoll } = action;
      return { ...state, votePoll };
    }
    case VOTE_POLL_REJECTED: {
      const { votePoll } = action;
      return { ...state, votePoll };
    }
    default: {
      return state;
    }
  }
}
