import React, { useContext } from "react";

import useQuery from "hooks/useQuery";
import { ThemeContext } from "styled-components";

import { useAuth } from "contexts/authContext";
import { ChannelWebsocketContextProvider } from "contexts/channelWebsocket";
import { ChatWebsocketProvider } from "contexts/chatWebsocket";
import { RebusContextProvider } from "contexts/rebusContext";
import { useWatchEventContext } from "contexts/WatchEventContext";
import { useEventComponentsContext } from "contexts/EventComponentsContext";

import LiveInfoStyled from "components/layouts/styled/LiveInfoStyled";
import HeaderWatch from "components/layouts/HeaderWatch";

import LivePlayer from "../LivePlayer";
import VanillaChat from "../VanillaChat";
import ViewersCounter from "../ViewersCounter";
import AdminPoll from "../AdminPoll";
import LivePoll from "../LivePoll";

function Livestream(props) {
  const query = useQuery();
  const { userData, getUserID } = useAuth();
  const viewer = query.get("viewer");
  const { eventSubdomain, eventData, accessCode } = useWatchEventContext();
  const themeContext = useContext(ThemeContext);
  const { tileAd, playerAd, titleAd, liveIcon } = themeContext;
  const {
    event_logo,
    event_name,
    is_owner,
    hasAccessCode,
    has_viewer_counter,
    has_custom_stickers,
    has_merchant,
    merchant_info,
  } = eventData;

  const nickname = hasAccessCode ? accessCode : userData.email;
  const forceViewer = viewer === "force";
  const showAdminPanels = forceViewer ? !forceViewer : is_owner;
  const { showChat } = useEventComponentsContext();
  const ConditionalRebusComponent = has_merchant
    ? RebusContextProvider
    : React.Fragment;

  const rebusProps = has_merchant ? { merchantInfo: merchant_info } : {};

  const connectionData = {
    event_subdomain: eventSubdomain,
    is_organizer: showAdminPanels,
    nickname: nickname,
    temporary_id: getUserID(),
    has_custom_stickers,
  };

  const divPlayerAdStyle = {
    "background-color": playerAdColors(playerAd),
  };

  function playerAdColors(playerProps) {
    let color = "#000000";
    if (!!playerProps.colors && !!playerProps.colors.body) {
      color = playerProps.colors.body;
    }
    return color;
  }

  const liveIconStyle = {
    ...(liveIcon
      ? {
          ...(liveIcon.backgroundGradient &&
          liveIcon.backgroundGradient.from &&
          liveIcon.backgroundGradient.to
            ? {
                backgroundImage: `linear-gradient(${liveIcon.backgroundGradient.from}, ${liveIcon.backgroundGradient.to})`,
              }
            : {
                ...(liveIcon.background
                  ? { backgroundColor: liveIcon.background }
                  : { backgroundColor: "#E14C6C" }),
              }),
        }
      : { backgroundColor: "#E14C6C" }),
  };

  return (
    <ChannelWebsocketContextProvider connectionData={connectionData}>
      <ConditionalRebusComponent {...rebusProps}>
        <HeaderWatch stylePage={"is-solid"} helpBlank />
        <section
          className={`c-livestream__container ${
            showAdminPanels && "c-livestream--admin"
          }`}
        >
          {showAdminPanels && (
            <div className="c-livestream__sidebar is-left">
              <AdminPoll />
            </div>
          )}

          <div className="c-livestream">
            <div className="c-livestream__links">
              <ul className="o-badge__container">
                <li className="o-badge" style={liveIconStyle}>
                  <span className="o-badge__text">Live</span>
                </li>
                <ViewersCounter showCounter={has_viewer_counter || is_owner} />
              </ul>
            </div>
            <div className="c-livestream__video">
              <LivePlayer />
            </div>
            {showChat &&
              playerAd.active &&
              playerAd?.multi &&
              Array.isArray(playerAd.ads) && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  {playerAd.ads.map((ad) => (
                    <a href={ad.link} target="_blank" rel="noopener noreferrer">
                      <img
                        style={{ padding: "5px" }}
                        height="80"
                        src={ad.src}
                        alt={ad.alt}
                      />
                    </a>
                  ))}
                </div>
              )}
            {playerAd.active && !playerAd?.multi && (
              <div className="c-livestream__adlarge" style={divPlayerAdStyle}>
                <a
                  href={playerAd.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    width="100%"
                    height="auto"
                    src={playerAd.src}
                    alt={playerAd.alt}
                  />
                </a>
              </div>
            )}
            {tileAd && tileAd.active && (
              <div className="c-livestream__tilead">
                <div className="c-livestream__tilead-column-1">
                  {tileAd.ads.map((ad, index) =>
                    index > 4 ? (
                      <></>
                    ) : (
                      <div
                        className={`c-livestream__tilead-item size-${
                          index === 3 ? "2" : "1"
                        }`}
                      >
                        <a
                          href={ad.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            style={{ height: "90%" }}
                            src={ad.src}
                            alt={ad.alt}
                          />
                        </a>
                      </div>
                    )
                  )}
                </div>
                {tileAd.ads.map((ad, index) =>
                  index !== 5 ? (
                    <></>
                  ) : (
                    <div
                      className="c-livestream__tilead-column-2"
                      style={{
                        backgroundImage: `url(${ad.src})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                      }}
                    >
                      <a
                        href={ad.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      ></a>
                    </div>
                  )
                )}
              </div>
            )}
            {!showAdminPanels && (
              <LivePoll
                classPoll={`${
                  showChat ? "c-livepoll--mobile" : "c-livepoll--nochat"
                }`}
              />
            )}
            {titleAd.active && (
              <LiveInfoStyled className="c-livestream__info">
                <div className="c-livestream__event">
                  <figure className="c-livestream__logo">
                    <img src={event_logo} alt={event_name} />
                  </figure>
                  <h3 className="c-livestream__name">{event_name}</h3>

                  {titleAd?.multi && Array.isArray(titleAd.ads) && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      {titleAd.ads.map((ad) => (
                        <a
                          href={ad.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            style={{ padding: "15px" }}
                            height="80"
                            src={ad.src}
                            alt={ad.alt}
                          />
                        </a>
                      ))}
                    </div>
                  )}

                  {!titleAd?.multi && (
                    <div className="c-livestream__ad">
                      <a
                        href={titleAd.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={titleAd.src} alt={titleAd.alt} />
                      </a>
                    </div>
                  )}
                </div>
              </LiveInfoStyled>
            )}
          </div>
          {showChat && (
            <ChatWebsocketProvider joinChatData={connectionData}>
              <div className="c-livestream__sidebar is-right">
                <VanillaChat />
                {!showAdminPanels && (
                  <LivePoll classPoll="c-livepoll--desktop" />
                )}
              </div>
            </ChatWebsocketProvider>
          )}
          {!showChat &&
            playerAd.active &&
            playerAd?.multi &&
            Array.isArray(playerAd.ads) && (
              <div className="c-livestream__sidebar is-right c-sidebar__ads">
                {playerAd.ads.map((ad) => (
                  <div
                    className="c-sidebar-item"
                    style={{
                      backgroundImage: `url(${ad.src})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                  >
                    <a
                      href={ad.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    ></a>
                  </div>
                ))}
              </div>
            )}
        </section>
      </ConditionalRebusComponent>
    </ChannelWebsocketContextProvider>
  );
}
export default Livestream;
