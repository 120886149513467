import React from "react";

const SVG = ({
  style = {},
  fill = "#E14C6C",
  width = "100%",
  height = "100%",
  className = "is-neerme-logo",
  viewBox = "0 0 270 40",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`c-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" fill={fill}>
        <path
          d="M7.1668,0.0004 L7.3138,0.1764 L30.8808,28.5964 L30.8808,0.0004 L38.5788,0.0004 L38.5788,40.0004 L31.4118,40.0004 L31.2648,39.8234 L7.6978,11.4044 L7.6978,40.0004 L-0.000199999994,40.0004 L-0.000199999994,0.0004 L7.1668,0.0004 Z M157.0304,-7.10542736e-14 C167.7634,-7.10542736e-14 173.2044,4.154 173.2044,12.348 C173.2044,17.64 170.7104,21.257 165.7864,23.104 L165.7864,23.104 L175.7584,40 L166.8064,40 L158.0224,24.669 C157.7874,24.688 157.5574,24.696 157.3324,24.696 L157.3324,24.696 L145.5504,24.696 L145.5504,40 L137.9064,40 L137.9064,-7.10542736e-14 Z M189.5289,-0.0003 L204.3639,30.9497 L219.1959,-0.0003 L228.0259,-0.0003 L228.0259,39.9997 L220.4899,39.9997 L220.4899,12.4717 L207.2949,39.9997 L201.1069,39.9997 L187.8539,12.6697 L187.8539,39.9997 L180.4279,39.9997 L180.4279,-0.0003 L189.5289,-0.0003 Z M269.9996,0.0002 L269.9996,6.8232 L245.1336,6.8232 L245.1336,16.0142 L268.7976,16.0142 L268.7976,22.8382 L245.1336,22.8382 L245.1336,33.1762 L269.9996,33.1762 L269.9996,40.0002 L237.2146,40.0002 L237.2146,0.0002 L269.9996,0.0002 Z M130.137,-7.10542736e-14 L130.137,6.823 L93.769,6.823 L93.769,16.015 L128.935,16.015 L128.935,22.838 L93.769,22.838 L93.769,33.176 L130.137,33.176 L130.137,40 L47.768,40 L47.768,-7.10542736e-14 L130.137,-7.10542736e-14 Z M85.853,22.838 L55.686,22.838 L55.686,33.176 L85.853,33.176 L85.853,22.838 Z M156.7034,6.606 L145.5504,6.606 L145.5504,18.091 L156.5934,18.091 C159.6134,18.091 161.8674,17.604 163.2904,16.645 C164.6724,15.713 165.3434,14.289 165.3434,12.293 C165.3434,10.261 164.7064,8.847 163.3994,7.971 C162.0484,7.064 159.7944,6.606 156.7034,6.606 L156.7034,6.606 Z M85.853,6.823 L55.686,6.823 L55.686,16.014 L85.853,16.014 L85.853,6.823 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
);

export default SVG;
