import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import InputField from "components/shared/InputField";
import Alert from "components/shared/Alert";
import BoletiaLogo from "components/shared/icon/boletia_logo";
import NeermeLogo from "components/shared/icon/neerme_logo";

import { loginUser } from "api/auth";
import { useAuth } from "contexts/authContext";
import useQuery from "hooks/useQuery";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");

  const [requestFailed, setRequestFailed] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showActivationMsg, setShowActivationMsg] = useState(false);

  const history = useHistory();
  const queryParams = useQuery();
  const location = useLocation();
  const { handleToken } = useAuth();

  function createPayload() {
    return {
      email,
      password,
    };
  }

  function handleSubmit(event) {
    event.preventDefault();
    const { from } = location.state || { from: { pathname: "/events" } };
    const payload = createPayload();
    loginUser(payload)
      .then((response) => {
        const { token, success, error_message } = response.data;
        if (success) {
          handleToken(token);
          history.replace(from);
        } else {
          if (error_message === "user already logged in") {
            setIsLoggedIn(true);
          } else {
            setRequestFailed(true);
          }
        }
      })
      .then((error) => {
        // setRequestFailed(true);
      });
  }

  function handleEmailChanege(event) {
    setEmail(event.target.value);
    setRequestFailed(false);
  }

  function handlePasswordChanege(event) {
    setpassword(event.target.value);
    setRequestFailed(false);
  }

  useEffect(() => {
    const activation = queryParams.get("activation");
    setShowActivationMsg(activation === "success");
  }, [queryParams]);

  const { t } = useTranslation();

  return (
    <section className="c-login__container">
      <div className="c-login">
        <Link to="/">
          <NeermeLogo width={100} />
        </Link>
        {showActivationMsg && (
          <Alert
            text="¡Tu cuenta se ha activado con éxito!"
            className="is-success"
          />
        )}
        {isLoggedIn && (
          <div className="o-alert is-error">
            <span>
              Tienes una sesión activa en otro dispositivo. Cierra sesión para
              intentar de nuevo.
            </span>
          </div>
        )}

        <form className="c-login__form" onSubmit={handleSubmit}>
          <h2 className="c-login__title">{t ("login.title")}</h2>
          <InputField
            label={t ("login.email")}
            classLabel="c-login__label"
            name="email"
            type="email"
            autoComplete="username"
            placeholder=""
            maxLength="100"
            required
            onChange={handleEmailChanege}
          />
          <InputField
            label={t ("login.password")}
            classLabel="c-login__label"
            name="password"
            type="password"
            autoComplete="current-password"
            placeholder=""
            maxLength="100"
            errorText={t ("login.error")}
            haserror={requestFailed}
            required
            onChange={handlePasswordChanege}
          />
          <button type="submit" className="o-button--green o-button--lg">
            {t ("login.login")}
          </button>
          <a
            href="https://boletia.com/forgot-password"
            rel="noopener noreferrer"
            target="_blank"
            className="c-login__help"
          >
            {t ("login.forgot_password")}
          </a>
        </form>

        <div className="c-login__powered">
          <span>{t ("login.product_by")}</span>
          <a
            href="https://boletia.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            <BoletiaLogo width={80} />
          </a>
        </div>
      </div>
      <div className="c-login__message">
        <div>
          <h1>
            “Solo vine por esa rola!!!!!!”
            <small>
              <figure className="c-chat__avatar">
                <img
                  src="https://home-statics.boletia.com/uploads/avatars/bot-3.png"
                  alt=""
                />
              </figure>{" "}
              Robocopsincasco - HIPNOSIS @ HOME: Black Overdrive
            </small>
          </h1>
          <h4>
            Este no es tu live stream de siempre.
            <div>#TheNeermeExperience</div>
          </h4>
        </div>
      </div>
    </section>
  );
}

export default Login;
