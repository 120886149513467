import Events from "components/pages/Events";
import Watch from "components/pages/Watch";

const protectedRoutes = [
  {
    name: "events",
    route: "/events",
    component: Events,
  },
  {
    name: "watch",
    route: "/watch/:eventSubdomain",
    component: Watch,
  },
];

export default protectedRoutes;
