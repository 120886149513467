import React, { useEffect, useReducer } from "react";

import { useTranslation } from "react-i18next";
import { tryDataLayerPush } from "utils/gtm";
import { useWatchEventContext } from "contexts/WatchEventContext";
import { useEventComponentsContext } from "contexts/EventComponentsContext";
import { useAuth } from "contexts/authContext";
import Loading from "components/shared/Loading";
import { useLevelStoreContext } from "contexts/LevelStoreContext";
import IconCrown from "components/shared/icon/crown";
import IconCrownFilled from "components/shared/icon/crown_filled";
import IconClose from "components/shared/icon/error";

import {
  STICKERS_ID,
  AVATAR_ID,
} from "contexts/EventComponentsContext/componentsKeys";

import ComponentShowcase from "./ComponentShowcase";
import Payment from "./Payment";

import reducer, { initialEventLevels } from "./store/reducer";
import { getEventLevels } from "./store/actions";

function LevelList(props) {
  const { t } = useTranslation(["watch"]);

  const [levelState, dispatchEvent] = useReducer(reducer, initialEventLevels);
  const {
    setBoughtLevel,
    hideLevels,
    showPaymentProcess,
    setShowPaymentProcess,
  } = useLevelStoreContext();
  const { eventData } = useWatchEventContext();
  const { checkOwnedComponents } = useEventComponentsContext();
  const { chatUsername } = useAuth();
  const { loading: loadingLevels, data: levels } = levelState.eventLevels;

  useEffect(() => {
    getEventLevels({ eventID: eventData.event_id }, dispatchEvent);
    // eslint-disable-next-line
  }, []);

  function handleBuyLevel(event, level) {
    event.preventDefault();
    const gtmEventName =
      level.price === 33 ? "click_buy_stickers" : "click_buy_topfan";
    tryDataLayerPush({
      event: gtmEventName,
    });
    setBoughtLevel(level);
    setShowPaymentProcess(true);
  }

  function closeModal(event) {
    event.preventDefault();
    hideLevels();
  }
  let firstStickerUrl =
    "https://boletia-v2.s3.amazonaws.com/uploads/neerme/eternidadfest/sticker-3.png";
  let firstAvatarUrl =
    "https://boletia-v2.s3.amazonaws.com/uploads/neerme/eternidadfest/avatar-1.png";
  if (levels.length > 0) {
    const firstLevelComponent = levels[0].components;

    const stickerComponents = firstLevelComponent.filter(
      (comp) => comp.develop_id === STICKERS_ID
    );
    const avatarComponents = firstLevelComponent.filter(
      (comp) => comp.develop_id === AVATAR_ID
    );

    if (stickerComponents.length > 0 && avatarComponents.length > 0) {
      const firstStickerComponent = stickerComponents[0];
      const firstStickerEntity = firstStickerComponent.properties.stickers;
      const firstStickerKey = Object.keys(firstStickerEntity)[0];
      firstStickerUrl =
        firstStickerComponent.properties.stickers[firstStickerKey];

      const firstAvatarComponent = avatarComponents[0];
      const firstAvatarEntity = firstAvatarComponent.properties.avatars;
      const firstAvatarKey = Object.keys(firstAvatarEntity)[0];
      firstAvatarUrl = firstAvatarComponent.properties.avatars[firstAvatarKey];
    }
  }
  let username;
  try {
    username = chatUsername.split("#")[0].split("@")[0];
  } catch (error) {
    username = "villanelle27";
  }
  return loadingLevels ? (
    <Loading />
  ) : showPaymentProcess ? (
    <Payment />
  ) : (
    <>
      <div className="c-upgrade__header">
        <h2 className="c-upgrade__title">{t("upgrade.title")}</h2>
        <span>{t("upgrade.description")}</span>
        <a href="/" onClick={closeModal} className="c-upgrade__cancel">
          <IconClose width={16} />
        </a>
      </div>
      <figure className="c-upgrade__icon">
        <IconCrown width={48} />
      </figure>
      <div className="c-upgrade__container">
        <div className="c-upgrade__column is-chat">
          <h4 className="c-upgrade__subtitle">{t("upgrade.beyou")}</h4>
          <div className="c-upgrade__chat">
            <div className="c-livestream__header">
              <h6 className="c-livestream__title">{t("livestream.chat")}</h6>
            </div>
            <div className="c-upgrade__chat-wrapper">
              <div className="c-chat__message-wrapper">
                <div className="c-chat__message is-alert">
                  <figure className="c-chat__badge is-top-fan">
                    <IconCrownFilled width={12} />
                  </figure>
                  <span>
                    {t("chat.exclamation")}<strong>{username}</strong>{" "}
                    {t("chat.alert1")}
                    <strong> Top Fan</strong>!
                  </span>
                </div>
              </div>

              <div className="c-chat__message-wrapper is-top-fan">
                <figure className="c-chat__avatar">
                  <img src={firstAvatarUrl} alt="bern" />
                </figure>
                <div className="c-chat__message is-top-fan">
                  <span className="c-chat__author">{username}:</span>{t("upgrade.chat_1")}
                </div>
              </div>

              <div className="c-chat__message-wrapper is-guest">
                <figure className="c-chat__avatar">
                  <img
                    src="https://home-statics.boletia.com/uploads/avatars/bot-3.png"
                    alt="top fan"
                  />
                </figure>
                <div className="c-chat__message is-guest">
                  <span className="c-chat__author">Marie:</span>{t("upgrade.chat_2")}
                </div>
              </div>

              <div className="c-chat__message-wrapper is-top-fan">
                <figure className="c-chat__avatar">
                  <img src={firstAvatarUrl} alt="bern" />
                </figure>
                <div className="c-chat__message is-top-fan">
                  <span className="c-chat__author">{username}:</span>
                  <img width="64" src={firstStickerUrl} alt=":eternidad-2:" />
                </div>
              </div>

              <div className="c-chat__message-wrapper is-guest">
                <figure className="c-chat__avatar">
                  <img
                    src="https://home-statics.boletia.com/uploads/avatars/bot-3.png"
                    alt="top fan"
                  />
                </figure>
                <div className="c-chat__message is-guest">
                  <span className="c-chat__author">Marie:</span>{t("upgrade.chat_3")}
                </div>
              </div>

              <div className="c-chat__message-wrapper is-top-fan">
                <figure className="c-chat__avatar">
                  <img src={firstAvatarUrl} alt="top fan" />
                </figure>
                <div className="c-chat__message is-top-fan">
                  <span className="c-chat__author">{username}:</span>{t("upgrade.chat_4")} 
                </div>
              </div>
            </div>
          </div>
        </div>
        {levels.map((level) => {
          return (
            <div className="c-upgrade__column" key={level.id}>
              <div className="c-upgrade__column-wrapper">
                <ComponentShowcase components={level.components} />
              </div>
              <div className="c-upgrade__button-wrapper">
                {checkOwnedComponents(level.components) ? (
                  <div className="o-button--inactive o-button--lg">
                    {t("upgrade.alreadybought")}
                  </div>
                ) : (
                  <>
                    <a
                      href="/"
                      className="o-button--green o-button--lg c-upgrade__button"
                      onClick={(event) => handleBuyLevel(event, level)}
                    >
                      {`${t("upgrade.only")} $${level.price} ${
                        eventData.currency
                      }!`}
                    </a>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default LevelList;
