import {
  USER_BAN_CHECK_REQUESTED,
  USER_BAN_CHECK_FULFILLED,
  USER_BAN_CHECK_REJECTED,
  GET_USER_EVENT_OWNERSHIP_REQUESTED,
  GET_USER_EVENT_OWNERSHIP_FULFILLED,
  GET_USER_EVENT_OWNERSHIP_REJECTED,
  GET_EVENT_DETAIL_REQUESTED,
  GET_EVENT_DETAIL_FULFILLED,
  GET_EVENT_DETAIL_REJECTED,
  VALIDATE_ACCESS_CODE_REQUESTED,
  VALIDATE_ACCESS_CODE_FULFILLED,
  VALIDATE_ACCESS_CODE_REJECTED,
  GET_TICKET_DATA_REQUESTED,
  GET_TICKET_DATA_FULFILLED,
  GET_TICKET_DATA_REJECTED,
  VALIDATE_CHAT_BLOCKED,
} from "./types";

export const initialWatchData = {
  access: true,
  userAuthAccess: true,
  loading: true,
  chatBlocked: false,
  banCheck: {
    banned: false,
    success: false,
    error: false,
  },
  accessCode: {
    valid: true,
    error: false,
    success: false,
  },
  eventDetail: {
    error: false,
    success: false,
    data: {},
  },
  getTicketData: {
    error: false,
    success: false,
    loading: false,
    data: {},
  },
};

function reducer(state, action) {
  switch (action.type) {
    case USER_BAN_CHECK_REQUESTED: {
      const { banCheck } = action;
      return {
        ...state,
        banCheck: {
          ...banCheck,
        },
      };
    }
    case USER_BAN_CHECK_FULFILLED: {
      const { banCheck } = action;
      return {
        ...state,
        banCheck: {
          ...banCheck,
        },
      };
    }
    case USER_BAN_CHECK_REJECTED: {
      const { banCheck } = action;
      return {
        ...state,
        banCheck: {
          ...banCheck,
        },
      };
    }
    case GET_USER_EVENT_OWNERSHIP_REQUESTED: {
      const { userAuthAccess } = action;
      return { ...state, userAuthAccess };
    }
    case GET_USER_EVENT_OWNERSHIP_FULFILLED: {
      const { userAuthAccess } = action;
      return { ...state, userAuthAccess };
    }
    case GET_USER_EVENT_OWNERSHIP_REJECTED: {
      const { userAuthAccess } = action;
      return { ...state, userAuthAccess };
    }
    case GET_TICKET_DATA_REQUESTED: {
      const { getTicketData } = action;
      return { ...state, getTicketData };
    }
    case GET_TICKET_DATA_FULFILLED: {
      const { getTicketData } = action;
      return { ...state, getTicketData };
    }
    case GET_TICKET_DATA_REJECTED: {
      const { getTicketData } = action;
      return { ...state, getTicketData };
    }
    case GET_EVENT_DETAIL_REQUESTED: {
      const { loading } = action;
      return { ...state, loading };
    }
    case GET_EVENT_DETAIL_FULFILLED: {
      const { access, loading, eventDetail } = action;
      return { ...state, access, loading, eventDetail };
    }
    case GET_EVENT_DETAIL_REJECTED: {
      const { access, loading, eventDetail } = action;
      return { ...state, access, loading, eventDetail };
    }
    case VALIDATE_ACCESS_CODE_REQUESTED: {
      return state;
    }
    case VALIDATE_ACCESS_CODE_FULFILLED: {
      const { accessCode } = action;
      return {
        ...state,
        accessCode: {
          ...accessCode,
        },
      };
    }
    case VALIDATE_ACCESS_CODE_REJECTED: {
      const { accessCode } = action;
      return {
        ...state,
        accessCode: {
          ...accessCode,
        },
      };
    }
    case VALIDATE_CHAT_BLOCKED: {
      const { chatBlocked } = action;
      return {
        ...state,
        chatBlocked,
      };
    }

    default:
      return state;
  }
}

export default reducer;
