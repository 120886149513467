import React, { useContext, createContext, useState } from "react";

import ModalIcon from "components/shared/ModalIcon";
import ModalAlert from "components/shared/ModalAlert";
import { tryDataLayerPush } from "utils/gtm";
import LevelList from "./LevelList";

export function LevelStoreContextProvider(props) {
  const { children } = props;

  const [levelModal, setLevelModal] = useState(false);
  const [showPaymentProcess, setShowPaymentProcess] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [boughtLevel, setBoughtLevel] = useState({});

  function showLevels() {
    tryDataLayerPush({
      event: "buy_level_view",
    });
    setLevelModal(true);
  }

  function hideLevels() {
    setLevelModal(false);
    setBoughtLevel({});
    setShowPaymentProcess(false);
  }

  function showSuccess(params) {
    setLevelModal(false);
    setSuccessModal(true);
  }

  function hideSuccess(params) {
    setLevelModal(false);
  }

  const defaultContext = {
    showLevels,
    hideLevels,
    showSuccess,
    hideSuccess,
    boughtLevel,
    setBoughtLevel,
    showPaymentProcess,
    setShowPaymentProcess,
  };
  return (
    <LevelStoreContext.Provider value={defaultContext}>
      {children}
      <ModalIcon active={levelModal} handler={setLevelModal}>
        <LevelList />
      </ModalIcon>
      <ModalAlert
        active={successModal}
        handler={setSuccessModal}
        level={boughtLevel}
      />
    </LevelStoreContext.Provider>
  );
}

export const LevelStoreContext = createContext();

export function useLevelStoreContext() {
  return useContext(LevelStoreContext);
}
