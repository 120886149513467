import React, { createContext, useContext, useEffect, useReducer } from "react";

import { useParams, useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NeermeIcon from "components/shared/icon/neerme_icon";
import IconBack from "components/shared/icon/back";
import Loading from "components/shared/Loading";
import { useAuth } from "contexts/authContext";

import reducer, { initialWatchData } from "./store/reducer";
import { accessEventDataActions, validateChatBlocked } from "./store/actions";

const EXCLUDE_BACK_BUTTON_EVENT = "cotorrisa-legendaria";
const REDIRECT_EVENTS = [
  "ars-estirando-el-chicle-live",
  "clp-estirando-el-chicle-live",
  "cop-estirando-el-chicle-live",
  "gbp-estirando-el-chicle-live",
  "gbp-estirando-el-chicle-live",
  "estirandoelchicle-liveeur",
  "sfr-estirando-el-chicle-live",
  "usd-estirando-el-chicle-live",
];
const REDIRECT_URL = "mex-estirando-el-chicle-live";

export function WatchEventContextProvider(props) {
  const { children } = props;
  const [watchData, dispatchEvent] = useReducer(reducer, initialWatchData);

  const { authToken, userData } = useAuth();
  const { eventSubdomain, accessCode } = useParams();
  const history = useHistory();
  const { t } = useTranslation(["watch"]);

  useEffect(() => {
    if (eventSubdomain && accessCode) {
      if (REDIRECT_EVENTS.includes(eventSubdomain)) {
        history.replace(`/${REDIRECT_URL}/${accessCode}`);
        return;
      }
    }
    if (eventSubdomain && (accessCode || userData.email)) {
      accessEventDataActions(
        { accessCode, eventSubdomain, userData, authToken },
        dispatchEvent
      );
      validateChatBlocked({ token: accessCode, eventSubdomain }, dispatchEvent);
    }
  }, [authToken, eventSubdomain, userData, accessCode]);

  useEffect(() => {
    if (watchData.banCheck.banned) {
      history.replace("/banned");
    }
    if (!watchData.accessCode.valid) {
      console.log("accessCode.valid", watchData.accessCode.valid);
      history.replace(`/${eventSubdomain}/?access=error`);
    }
    if (!watchData.userAuthAccess) {
      history.replace(`/${eventSubdomain}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchData]);

  const defaultContext = {
    eventSubdomain,
    eventData: watchData.eventDetail.data,
    accessCode,
    watchData,
    ticketData: watchData.getTicketData,
    validateChatBlocked: () => {
      validateChatBlocked({ token: accessCode, eventSubdomain }, dispatchEvent);
    },
  };

  return (
    <WatchEventContext.Provider value={defaultContext}>
      {watchData.loading ? (
        <Loading />
      ) : watchData.access ? (
        children
      ) : (
        <section className="c-waiting__container">
          <div className="c-waiting">
            <NeermeIcon width={40} />
            <h2>{t("waiting.title")}</h2>
            <p>{t("waiting.description")}</p>
            <br />
            {eventSubdomain !== EXCLUDE_BACK_BUTTON_EVENT && (
              <Link
                to={`/${eventSubdomain}`}
                className="o-button--green o-button--lg"
              >
                <IconBack width={11} /> {t("waiting.back")}
              </Link>
            )}
          </div>
        </section>
      )}
    </WatchEventContext.Provider>
  );
}

export const WatchEventContext = createContext();

export function useWatchEventContext() {
  return useContext(WatchEventContext);
}
