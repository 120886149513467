import React from "react";

function InputField(props) {
  const {
    name,
    label,
    changeValue,
    hasFocus,
    Blur,
    haserror,
    errorText,
    classLabel,
    ...rest
  } = props;
  return (
    <div className="o-field c-login__field" id="mail">
      <label className={classLabel} htmlFor={name}>
        {label}
      </label>
      <input
        className="o-field__input"
        onChange={changeValue}
        onFocus={hasFocus}
        onBlur={Blur}
        id={name}
        {...rest}
      />
      {haserror && (
        <span className="o-field__error c-login__error">{errorText}</span>
      )}
    </div>
  );
}

export default InputField;
