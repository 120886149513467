import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// import Overlay from "../Overlay/Overlay";
import NeermeLogo from "../../shared/icon/neerme_logo";
import UserIcon from "../../shared/icon/user";
import LogoutIcon from "../../shared/icon/logout";
import HamburgerIcon from "../../shared/icon/hamburger";
import BackIcon from "../../shared/icon/back";
import Menu from "../Menu";

import { OverlayContext } from "../../../contexts/overlayMenu";
import { useAuth } from "../../../contexts/authContext";

import { logOut } from "../../../api/auth";

function Header(props) {
  const { t,i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const [showOverlay, setShowOverlay] = useState(false);

  const { handleToken, authToken, userData, handleChatUsername } = useAuth();

  const history = useHistory();
  function toggleOverlay() {
    setShowOverlay(!showOverlay);
  }

  const overlayContext = {
    showOverlay,
    toggleOverlay: toggleOverlay,
  };

  const { stylePage } = props;

  function handleLogout(event) {
    event.preventDefault();
    const logOutPayload = {
      email: userData.email,
    };
    logOut(authToken, logOutPayload).then((response) => {
      handleToken(false);
      handleChatUsername(false);
      history.replace("/");
    });
  }

  return (
    <OverlayContext.Provider value={overlayContext}>
      <header className={`c-header ${stylePage}`}>
        <Link className="c-header__logo" to="/">
          <NeermeLogo width={90} />
        </Link>
        <ul className="c-header__links">
          <li className="c-header__single is-mobile">
            <label htmlFor="mobile-menu" className="c-header__icon">
              <HamburgerIcon width={16} />
              <span>{t('menu.menu')}</span>
            </label>
          </li>
          <li className="c-header__single is-language is-desktop">
            <button onClick={() => changeLanguage('en')}>
              EN 
            </button>
            <span>|</span>
            <button onClick={() => changeLanguage('es')}>
              ES 
            </button>
          </li>
          <li className="c-header__single is-desktop">
            <div className="c-header__primary">
              {t("menu.why")}
              <BackIcon width={10} />
            </div>
            <ul className="c-header__dropdown">
              <li>
                <Link to="/why-neerme">
                  {t("menu.why")}
                </Link>
              </li>
              <li>
                <Link to="/event-types">{t("menu.event_types")}</Link>
              </li>
            </ul>
          </li>
          <li className="c-header__single is-desktop">
            <div className="c-header__primary">
              {t("menu.whositfor")}
              <BackIcon width={10} />
            </div>
            <ul className="c-header__dropdown">
              <li>
                <Link to="/whos-it-for/artist-managers">
                  <span role="img" aria-label="artist">🎙</span>
                  {t("menu.artist_managers")}
                </Link>
              </li>
              <li>
                <Link to="/whos-it-for/content-creators">
                  <span role="img" aria-label="artist">👩‍🔬</span>
                  {t("menu.content_creators")}
                </Link>
              </li>
              <li>
                <Link to="/whos-it-for/musicians">
                  <span role="img" aria-label="artist">🎸</span>
                  {t("menu.musicians")}
                </Link>
              </li>
              <li>
                <Link to="/whos-it-for/comedians">
                  <span role="img" aria-label="artist">😂</span>
                  {t("menu.comedians")}
                </Link>
              </li>
              <li>
                <Link to="/whos-it-for/theater-producers">
                  <span role="img" aria-label="artist">🎭</span>
                  {t("menu.theater_producers")}
                </Link>
              </li>
            </ul>
          </li>
          <li className="c-header__single is-desktop">
            <div className="c-header__primary">
              {t("menu.case_stories")}
              <BackIcon width={10} />
            </div>
            <ul className="c-header__dropdown">
              <li>
                <Link to="/case-studies/comedy">
                  <span role="img" aria-label="comedy">😂</span>
                  {t("menu.comedy")}
                </Link>
              </li>
              <li>
                <Link to="/case-studies/digital-content">
                  <span role="img" aria-label="digital content">👩‍🔬</span>
                  {t("menu.digital_content")}
                </Link>
              </li>
              <li>
                <Link to="/case-studies/theater">
                  <span role="img" aria-label="theater">🎭</span>
                  {t("menu.theater")}
                </Link>
              </li>
              <li>
                <Link to="/case-studies/music">
                  <span role="img" aria-label="music">🎸</span>
                  {t("menu.music")}
                </Link>
              </li>
              <li>
                <Link to="/case-studies/corporate">
                  <span role="img" aria-label="corporate">🤝</span>
                  {t("menu.corporate")}
                </Link>
              </li>
              
            </ul>
          </li>
          <li className="c-header__single is-desktop">
            <Link to="/about">{t("menu.about")}</Link>
          </li>
          {authToken ? (
            <>
              <li className="c-header__single is-desktop">
                <Link className="c-header__icon" to="/events">
                  <span>{t("menu.lives")}</span>
                </Link>
              </li>
              <li className="c-header__single is-desktop">
                <a className="c-header__icon" href="/" onClick={handleLogout}>
                  <LogoutIcon width={16} />
                  {t("menu.logout")}
                </a>
              </li>
            </>
          ) : (
            <li className="c-header__single is-desktop">
              <Link className="c-header__icon" to="/login">
                <UserIcon width={16} />
                <span>{t("menu.login")}</span>
              </Link>
            </li>
          )}
        </ul>
      </header>
      {/* <Overlay /> */}
      <Menu />
    </OverlayContext.Provider>
  );
}

export default Header;
