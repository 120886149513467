import {
  GET_EVENT_LEVELS_REQUESTED,
  GET_EVENT_LEVELS_FULFILLED,
  GET_EVENT_LEVELS_REJECTED,
} from "./types";

export const initialEventLevels = {
  eventLevels: {
    loading: true,
    success: false,
    error: false,
    data: [],
  },
  xsollaToken: {
    loading: false,
    success: false,
    error: false,
    data: null,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case GET_EVENT_LEVELS_REQUESTED: {
      const { eventLevels } = action;
      return { ...state, eventLevels };
    }
    case GET_EVENT_LEVELS_FULFILLED: {
      const { eventLevels } = action;
      return { ...state, eventLevels };
    }
    case GET_EVENT_LEVELS_REJECTED: {
      const { eventLevels } = action;
      return { ...state, eventLevels };
    }

    default:
      return state;
  }
}

export default reducer;
