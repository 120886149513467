import React from "react";
import { useTranslation } from 'react-i18next';

function Banned(props) {
  const { t } = useTranslation();

  return (
    <>
      <div className="c-banned__wrapper">
        <div className="c-banned">
          <span role="img" aria-label="prohibited">
            🚫
          </span>
          <h1>{t ("banned.title")}</h1>
          <p>{t ("banned.description_1")}</p>
          <p>{t ("banned.description_2")}</p>
        </div>
      </div>
    </>
  );
}

export default Banned;
