import React, { useEffect, useRef } from "react";

import { Helmet } from "react-helmet";

import useScript from "hooks/useScript";

function TheoPlayer(props) {
  const videoNode = useRef();
  // eslint-disable-next-line
  const [loadedCast, loadCastError] = useScript(
    "//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"
  );
  // eslint-disable-next-line
  const [loadedTheoPlayer, loadTheoPlayerError] = useScript(
    "https://cdn.myth.theoplayer.com/5956e8d7-2e8b-4d37-a4b1-f88870b987c0/THEOplayer.js"
  );
  const { src } = props;

  useEffect(() => {
    if ([loadedCast, loadedTheoPlayer].every(Boolean)) {
      const videoElement = videoNode.current;
      // eslint-disable-next-line
      const player = new THEOplayer.Player(videoElement, {
        libraryLocation:
          "https://cdn.myth.theoplayer.com/5956e8d7-2e8b-4d37-a4b1-f88870b987c0",
        // Picture-in-picture initialisation
        pip: {
          visibility: 0.7,
        },
      });

      // OPTIONAL CONFIGURATION
      // Customized video player parameters
      player.source = {
        sources: [
          {
            src: src,
            type: "application/x-mpegurl",
          },
        ],
      };
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [loadedCast, loadedTheoPlayer]);
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdn.myth.theoplayer.com/5956e8d7-2e8b-4d37-a4b1-f88870b987c0/ui.css"
        />
      </Helmet>
      <div
        className="theoplayer-container video-js theoplayer-skin vjs-16-9"
        ref={videoNode}
      ></div>
    </>
  );
}

export default TheoPlayer;
