import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'is-play',
  viewBox = '0 0 24 24'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id="Artboard" fill={fill}>
      <path d='M12,0 C18.617,0 24,5.383 24,12 C24,18.617 18.617,24 12,24 C5.383,24 0,18.617 0,12 C0,5.383 5.383,0 12,0 Z M12,2 C6.486,2 2,6.486 2,12 C2,17.514 6.486,22 12,22 C17.514,22 22,17.514 22,12 C22,6.486 17.514,2 12,2 Z M9.53,6.1527 L17.53,11.1527 C17.822,11.3347 18,11.6557 18,11.9997 C18,12.3447 17.822,12.6647 17.53,12.8477 L9.53,17.8477 C9.368,17.9497 9.184,17.9997 9,17.9997 C8.833,17.9997 8.667,17.9577 8.515,17.8747 C8.197,17.6987 8,17.3637 8,16.9997 L8,6.9997 C8,6.6367 8.197,6.3017 8.515,6.1247 C8.833,5.9507 9.221,5.9577 9.53,6.1527 Z M10,8.8047 L10,15.1957 L15.113,11.9997 L10,8.8047 Z'></path>
    </g>
  </svg>
)

export default SVG
