import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { random } from "lodash";
import useWebSocket from "react-use-websocket";
import { useParams, useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "clientjs";
import { useTranslation } from "react-i18next";

import { CHAT_EVENT } from "aws/dynamodb/tables";
import { useDynamodb } from "hooks/useDynamodb";
import useQuery from "hooks/useQuery";
import DynamodbConfig from "aws/dynamodb/config";
import { setMessage } from "utils/webSocketHelper";

export const ChannelWebsocketContext = createContext({});

const SOCKET_URL = process.env.REACT_APP_NOTIFICATION_SOCKET_URL;

const ROUTE_USERS_CHANNEL_SUBSCRIBE = "v1/users/channel/subscribe";

const ACTION_CHANNEL_USER_BANNED = "userBanned";
const ACTION_CHANNEL_USER_NEW_INC_CONN = "userNewIncomingConnection";

const SLOW_MODE_MIN_CONNECTIONS = 40000;
const SLOW_MODE_SECONDS_TIMEOUT = 20;

const SAME_MSG_TIMEOUT = 30;

const SPAM_TIMEOUT = 10;
const MAX_SPAM_MSGS = 5;

const BURST_MAX = "NO-LIMITS-PRODUCT-NEERME";

export function ChannelWebsocketContextProvider(props) {
  const { t } = useTranslation(["watch"]);
  const { children, connectionData } = props;

  const [userConnections, setUserConnections] = useState(0);
  // const [userSubscribed, setUserSubscribed] = useState(false);

  const [activePoll, setActivePoll] = useState({});

  const [lastChatMsgTimeStamp, setLastChatMsgTimeStamp] = useState(null);
  const [lastChatMsg, setLastChatMsg] = useState(null);

  const secondsPassedMulti = useRef(0);
  const messagesSent = useRef(0);
  const hasSpamTimeout = useRef(false);

  const query = useQuery();
  const burst = query.get("burst");

  const history = useHistory();
  const { accessCode } = useParams();
  const [DputItem] = useDynamodb(DynamodbConfig);

  // const socketOptions = useMemo(
  //   () => ({
  //     shouldReconnect: (closeEvent) => true,
  //     reconnectAttempts: 10,
  //     reconnectInterval: 3000,
  //     onOpen: ()=> {}
  //   }),
  //   []
  // );

  // attrs = [sendMessage, lastMessage, readyState, getWebSocket]
  const {
    sendMessage,
    lastMessage,
    readyState,
    getWebSocket,
    lastJsonMessage,
  } = useWebSocket(SOCKET_URL, {
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
    onOpen: () => {
      const clientjs = new ClientJS(); // eslint-disable-line no-undef
      const connDataFinegerprint = {
        ...connectionData,
        fingerprint: {
          id: clientjs.getFingerprint(),
          os: `${clientjs.getOS()} ${clientjs.getOSVersion()}`,
          browser: `${clientjs.getBrowser()} ${clientjs.getBrowserVersion()}`,
          location: "cant tell you",
          ip: "cant tell you",
        },
      };
      const streamJoinUserMessage = setMessage(
        ROUTE_USERS_CHANNEL_SUBSCRIBE,
        connDataFinegerprint
      );
      sendMessage(streamJoinUserMessage);
    },
  });

  useEffect(() => {
    if (lastMessage !== null) {
      const { action, data } = JSON.parse(lastMessage.data);

      switch (action) {
        case ACTION_CHANNEL_USER_BANNED:
          handleUserBanned(data);
          break;
        case ACTION_CHANNEL_USER_NEW_INC_CONN:
          handleMultipleConn(data);
          break;

        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  // useEffect(() => {
  //   const clientjs = new ClientJS(); // eslint-disable-line no-undef
  //   const connDataFinegerprint = {
  //     ...connectionData,
  //     fingerprint: {
  //       id: clientjs.getFingerprint(),
  //       os: `${clientjs.getOS()} ${clientjs.getOSVersion()}`,
  //       browser: `${clientjs.getBrowser()} ${clientjs.getBrowserVersion()}`,
  //       location: "cant tell you",
  //       ip: "cant tell you",
  //     },
  //   };
  //   const streamJoinUserMessage = setMessage(
  //     ROUTE_USERS_CHANNEL_SUBSCRIBE,
  //     connDataFinegerprint
  //   );
  //   if (readyState === ReadyState.OPEN && !userSubscribed) {
  //     sendMessage(streamJoinUserMessage);
  //     setUserSubscribed(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [connectionData, readyState, userSubscribed]);

  useEffect(() => {
    const spamInterval = setInterval(() => {
      if (burst === BURST_MAX) {
        return;
      } else {
        secondsPassedMulti.current += 1;

        if (
          hasSpamTimeout.current &&
          secondsPassedMulti.current >= SPAM_TIMEOUT
        ) {
          hasSpamTimeout.current = false;
          secondsPassedMulti.current = 0;
          messagesSent.current = 0;
        } else if (
          !hasSpamTimeout.current &&
          secondsPassedMulti.current < SPAM_TIMEOUT &&
          messagesSent.current >= MAX_SPAM_MSGS
        ) {
          hasSpamTimeout.current = true;
          secondsPassedMulti.current = 0;
        } else if (secondsPassedMulti.current >= SPAM_TIMEOUT) {
          secondsPassedMulti.current = 0;
          hasSpamTimeout.current = false;
        }
      }
    }, 1000);

    return () => {
      clearInterval(spamInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function handleNewMessage(msg) {
  function sendChatMessage(data, messageHandler, errorHandler) {
    const { event_subdomain, is_organizer, nickname } = connectionData;
    const { chatUsername, chatAvatar, msg } = data;
    const epoch = Date.now();
    const utc = new Date().toISOString();
    const paramsMessage = {
      TableName: CHAT_EVENT,
      Item: {
        id: { S: uuidv4() },
        event_subdomain: { S: event_subdomain },
        message: { S: msg },
        nickname: { S: chatUsername },
        avatar: { S: chatAvatar },
        epoch: { S: epoch.toString() },
        utc: { S: utc },
        token: { S: accessCode ? accessCode : nickname },
      },
    };

    const timeElapsed = Date.now() - lastChatMsgTimeStamp;
    const secondsPassed = Math.floor(timeElapsed / 1000);

    const canSendChatMsg = is_organizer || validateChatMsg();

    if (canSendChatMsg) sendNewMessage();

    return canSendChatMsg;

    function validateChatMsg() {
      let hasError = false;
      let seconds;
      let errorMessage;

      const slowModeCheck =
        userConnections >= SLOW_MODE_MIN_CONNECTIONS &&
        secondsPassed < SLOW_MODE_SECONDS_TIMEOUT;
      const sameChatMsgCheck =
        lastChatMsg === msg && secondsPassed < SAME_MSG_TIMEOUT;

      if (sameChatMsgCheck) {
        errorMessage = (time) =>
          `${t("chat.error_1")} ${time} ${t("chat.error_seconds")}`;
        seconds = SAME_MSG_TIMEOUT - secondsPassed;
        hasError = sameChatMsgCheck;
      } else if (slowModeCheck) {
        errorMessage = (time) =>
          `${t("chat.error_2")} ${time} ${t("chat.error_seconds")}`;
        seconds = SLOW_MODE_SECONDS_TIMEOUT - secondsPassed;
        hasError = slowModeCheck;
      } else if (hasSpamTimeout.current) {
        errorMessage = (time) =>
          `${t("chat.error_3")} ${time} ${t("chat.error_seconds")}`;
        seconds = SPAM_TIMEOUT - secondsPassedMulti.current;
        hasError = hasSpamTimeout.current;
      }

      if (hasError) errorHandler(errorMessage, seconds);

      return !hasError;
    }

    function sendNewMessage() {
      // sendMessage(channelChatUserOnMessageAction);
      if(!random(0, 2)) {
        DputItem(paramsMessage);
      }
      messageHandler({
        author: chatUsername,
        avatar: chatAvatar,
        message: msg,
      });
      setLastChatMsgTimeStamp(Date.now());
      setLastChatMsg(msg);
      messagesSent.current += 1;
    }
  }

  function handleUserBanned(params) {
    history.replace("/banned");
  }
  function handleMultipleConn(params) {
    history.replace("/multiple");
  }

  const context = {
    // websocket functions/vars
    sendMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
    // getters
    userConnections,
    activePoll,
    connectionData,
    //setters
    setUserConnections,
    setActivePoll,
    //actions
    sendChatMessage,
  };
  return (
    <ChannelWebsocketContext.Provider value={context}>
      {children}
    </ChannelWebsocketContext.Provider>
  );
}

export function useChannelWebsocketContext() {
  return useContext(ChannelWebsocketContext);
}
