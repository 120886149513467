import {
  GET_EVENT_LEVELS_REQUESTED,
  GET_EVENT_LEVELS_FULFILLED,
  GET_EVENT_LEVELS_REJECTED,
} from "./types";

import { getEventLevels as getEventLevelsAPI } from "api/events";

export function getEventLevels({ eventID }, dispatch) {
  dispatch(getEventLevelsRequested());
  getEventLevelsAPI(eventID)
    .then((response) => {
      const levels = response.data;
      dispatch(getEventLevelsFulfilled(levels));
    })
    .catch((error) => {
      dispatch(getEventLevelsRejected(error));
    });
}

function getEventLevelsRequested() {
  return {
    type: GET_EVENT_LEVELS_REQUESTED,
    eventLevels: {
      loading: true,
      success: false,
      error: false,
      data: [],
    },
  };
}
function getEventLevelsFulfilled(data) {
  return {
    type: GET_EVENT_LEVELS_FULFILLED,
    eventLevels: {
      loading: false,
      success: true,
      error: false,
      data,
    },
  };
}
function getEventLevelsRejected(error) {
  return {
    type: GET_EVENT_LEVELS_REJECTED,
    eventLevels: {
      loading: false,
      success: false,
      error,
      data: [],
    },
  };
}
