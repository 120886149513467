import { useDynamodb } from "aws/dynamodb";
import { BAN_LIST } from "aws/dynamodb/tables";

import {
  getUserEvents,
  getEventDetail,
  validateAccessCode,
  getTicketData as getTicketDataAPI,
  validateTokenChatBlocked,
} from "api/events";

import {
  USER_BAN_CHECK_REQUESTED,
  USER_BAN_CHECK_FULFILLED,
  USER_BAN_CHECK_REJECTED,
  GET_USER_EVENT_OWNERSHIP_REQUESTED,
  GET_USER_EVENT_OWNERSHIP_FULFILLED,
  GET_USER_EVENT_OWNERSHIP_REJECTED,
  GET_EVENT_DETAIL_REQUESTED,
  GET_EVENT_DETAIL_FULFILLED,
  GET_EVENT_DETAIL_REJECTED,
  VALIDATE_ACCESS_CODE_REQUESTED,
  VALIDATE_ACCESS_CODE_FULFILLED,
  VALIDATE_ACCESS_CODE_REJECTED,
  GET_TICKET_DATA_REQUESTED,
  GET_TICKET_DATA_FULFILLED,
  GET_TICKET_DATA_REJECTED,
  VALIDATE_CHAT_BLOCKED,
} from "./types";

const IS_DEVELOPMENT = process.env.NODE_ENV === "development";
// const IS_DEVELOPMENT = false;
const { Dscan } = useDynamodb();

export function accessEventDataActions(
  { accessCode, eventSubdomain, userData, authToken },
  dispatch
) {
  const curatedEventAttributes = {
    is_owner: false,
    hasAccessCode: false,
  };
  if (accessCode) {
    userBanCheck({ code: accessCode }, dispatch);
    getTicketData({ token: accessCode }, dispatch);
    curatedEventAttributes.hasAccessCode = true;
  } else if (userData?.email) {
    userBanCheck({ code: userData.email }, dispatch);
    getUserEventOwnership({ authToken, eventSubdomain }, dispatch);
    curatedEventAttributes.is_owner = true;
  }
  getCleanEventDetail(
    { curatedEventAttributes, eventSubdomain, accessCode },
    dispatch
  );
}

export function userBanCheck({ code }, dispatch) {
  dispatch(userBanCheckRequested());
  const scanUser = {
    FilterExpression: "email = :email",
    ExpressionAttributeValues: {
      ":email": { S: code },
    },
    TableName: BAN_LIST,
  };
  Dscan(scanUser)
    .then((data) => {
      const banned = data.length > 0;
      dispatch(userBanCheckFulfilled(banned));
    })
    .catch((error) => {
      dispatch(userBanCheckRejected(error));
    });
}

function userBanCheckRequested() {
  return {
    type: USER_BAN_CHECK_REQUESTED,
    banCheck: {
      banned: false,
      success: false,
      error: false,
    },
  };
}
function userBanCheckFulfilled(banned) {
  return {
    type: USER_BAN_CHECK_FULFILLED,
    banCheck: {
      banned,
      success: true,
      error: false,
    },
  };
}
function userBanCheckRejected(error) {
  console.error("Error getting user status by dynamo: ", error);
  return {
    type: USER_BAN_CHECK_REJECTED,
    banCheck: {
      banned: false,
      success: false,
      error,
    },
  };
}

export function getUserEventOwnership({ authToken, eventSubdomain }, dispatch) {
  dispatch(getUserEventOwnershipRequested());
  getUserEvents(authToken)
    .then((request) => {
      const eventsUser = request.data.events;
      const selectEvent = eventsUser.filter(
        (event) => event.event_subdomain === eventSubdomain
      );
      const userAuthAccess = !(selectEvent.length === 0);
      dispatch(getUserEventOwnershipFulfilled(userAuthAccess));
    })
    .catch((error) => {
      dispatch(getUserEventOwnershipRejected(error));
    });
}

function getUserEventOwnershipRequested() {
  return {
    type: GET_USER_EVENT_OWNERSHIP_REQUESTED,
    userAuthAccess: true,
  };
}
function getUserEventOwnershipFulfilled(userAuthAccess) {
  return {
    type: GET_USER_EVENT_OWNERSHIP_FULFILLED,
    userAuthAccess: userAuthAccess,
  };
}
function getUserEventOwnershipRejected(error) {
  console.error("Error getting user event ownership: ", error);
  return {
    type: GET_USER_EVENT_OWNERSHIP_REJECTED,
    userAuthAccess: true,
  };
}

export function getCleanEventDetail(
  { curatedEventAttributes, eventSubdomain, accessCode },
  dispatch
) {
  dispatch(getCleanEventDetailRequested());
  const { hasAccessCode } = curatedEventAttributes;
  getEventDetail(eventSubdomain)
    .then((response) => {
      const eventData = response.data;
      const curatedEvent = {
        ...eventData,
        ...curatedEventAttributes,
      };
      if (hasAccessCode) {
        validateEventAccessCode({ accessCode, event: curatedEvent }, dispatch);
      } else {
        dispatch(getCleanEventDetailFulfilled(curatedEvent));
      }
    })
    .catch((error) => {
      dispatch(getCleanEventDetailRejected(error));
    });
}

function getCleanEventDetailRequested(params) {
  return {
    type: GET_EVENT_DETAIL_REQUESTED,
    loading: true,
  };
}

function getCleanEventDetailFulfilled(curatedEvent) {
  const { is_time_to_access, is_owner } = curatedEvent;
  const access = is_owner ? is_owner : is_time_to_access || IS_DEVELOPMENT;

  return {
    type: GET_EVENT_DETAIL_FULFILLED,
    access: access,
    loading: false,
    eventDetail: {
      error: false,
      success: true,
      data: access ? curatedEvent : {},
    },
  };
}
function getCleanEventDetailRejected(error) {
  return {
    type: GET_EVENT_DETAIL_REJECTED,
    eventDetail: {
      error,
      success: false,
      data: {},
    },
  };
}

export function validateEventAccessCode({ accessCode, event }, dispatch) {
  dispatch(validateEventAccessCodeRequested());
  const accessCodePayload = {
    token: accessCode,
    event_id: event.event_id,
  };
  validateAccessCode(accessCodePayload)
    .then((response) => {
      const { valid } = response.data;
      dispatch(validateEventAccessCodeFulfilled(valid));
      if (valid) {
        dispatch(getCleanEventDetailFulfilled(event));
      }
    })
    .catch((error) => {
      dispatch(validateEventAccessCodeRejected(error));
    });
}

function validateEventAccessCodeRequested() {
  return {
    type: VALIDATE_ACCESS_CODE_REQUESTED,
  };
}
function validateEventAccessCodeFulfilled(valid) {
  return {
    type: VALIDATE_ACCESS_CODE_FULFILLED,
    accessCode: {
      valid: valid,
      error: false,
      success: true,
    },
  };
}
function validateEventAccessCodeRejected(error) {
  console.error("Error validating access code: ", error);
  return {
    type: VALIDATE_ACCESS_CODE_REJECTED,
    accessCode: {
      valid: false,
      error,
      success: false,
    },
  };
}

export function getTicketData({ token }, dispatch) {
  dispatch(getTicketDataRequested());
  getTicketDataAPI(token)
    .then((response) => {
      const data = response.data;
      dispatch(getTicketDataFulfilled(data));
    })
    .catch((error) => {
      dispatch(getTicketDataRejected(error));
    });
}

function getTicketDataRequested() {
  return {
    type: GET_TICKET_DATA_REQUESTED,
    getTicketData: {
      loading: true,
      success: false,
      error: false,
      data: {},
    },
  };
}
function getTicketDataFulfilled(data) {
  return {
    type: GET_TICKET_DATA_FULFILLED,
    getTicketData: {
      loading: false,
      success: true,
      error: false,
      data: data,
    },
  };
}
function getTicketDataRejected(error) {
  return {
    type: GET_TICKET_DATA_REJECTED,
    getTicketData: {
      loading: false,
      success: false,
      error,
    },
  };
}

export function validateChatBlocked({ token, eventSubdomain }, dispatch) {
  validateTokenChatBlocked(token, eventSubdomain).then((response) => {
    const data = response.data;
    dispatch({
      type: VALIDATE_CHAT_BLOCKED,
      chatBlocked: data.status,
    });
  });
}
