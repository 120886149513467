import React from "react";
import { useTranslation } from 'react-i18next';

import OpenIcon from "components/shared/icon/back";

function ChatButton(props) {
  const { t } = useTranslation(['watch']);

  return (
    <div onClick={props.clickHandler} className="c-load-more">
      <button className="c-load-more__button">
        {t ("chat.latest")} <OpenIcon width={12} />
      </button>
    </div>
  );
}
export default ChatButton;
