import {
  SWITCH_POLL_STATUS_REQUESTED,
  SWITCH_POLL_STATUS_FULFILLED,
  SWITCH_POLL_STATUS_REJECTED,
  GET_EVENT_DETAIL_REQUESTED,
  GET_EVENT_DETAIL_REJECTED,
  GET_EVENT_DETAIL_FULFILLED,
} from "./types";

export let initialPoll = {
  switchPoll: {
    success: false,
    loading: false,
    error: false,
    data: {},
  },
  getPollDetail: {
    loading: false,
    success: false,
    data: {},
    error: false,
  },
};

export default function reducer(state, action) {
  switch (action.type) {
    case SWITCH_POLL_STATUS_REQUESTED: {
      const { switchPoll } = action;
      return { ...state, switchPoll };
    }
    case SWITCH_POLL_STATUS_FULFILLED: {
      const { switchPoll } = action;
      return { ...state, switchPoll };
    }
    case SWITCH_POLL_STATUS_REJECTED: {
      const { switchPoll } = action;
      return { ...state, switchPoll };
    }
    case GET_EVENT_DETAIL_REQUESTED: {
      const { getPollDetail } = action;
      return { ...state, getPollDetail };
    }
    case GET_EVENT_DETAIL_REJECTED: {
      const { getPollDetail } = action;
      return { ...state, getPollDetail };
    }
    case GET_EVENT_DETAIL_FULFILLED: {
      const { getPollDetail } = action;
      return { ...state, getPollDetail };
    }
    default:
      return state;
  }
}
