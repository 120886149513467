import React, { useState, useContext, createContext, useEffect } from "react";

import { pollContainerStates } from "consts/pollContainerStates";
import { getUserPolls } from "api/polls";

export const AdminPollContext = createContext({});

export function AdminPollContextProvider(props) {
  const { children, requestData } = props;

  const [adminPollNavigation, setAdminPollNavigation] = useState(
    pollContainerStates.LOADING
  );
  const [polls, setPolls] = useState([]);
  const [pollsNumber, setPollsNumber] = useState(0);
  const [livePoll, setLivePoll] = useState({});
  // eslint-disable-next-line
  const [eventSubdomain, setEventSubdomain] = useState(
    requestData.eventSubdomain
  );

  function requestPolls() {
    return getUserPolls(requestData.authToken, requestData.eventSubdomain);
  }

  function responsePollToState(response) {
    const { polls_count, polls_list } = response.data.polls_info;
    setPolls(polls_list);
    setPollsNumber(polls_count);
  }

  function setPollData() {
    requestPolls().then((response) => {
      const { data } = response;
      if (data.success) {
        responsePollToState(response);
      }
    });
  }

  useEffect(() => {
    requestPolls().then((response) => {
      const { data } = response;

      if (data.success) {
        responsePollToState(response);
        const { polls_count, polls_list } = response.data.polls_info;

        if (polls_count === 0) {
          setAdminPollNavigation(pollContainerStates.EMPTY);
        } else {
          const anyPollActive = polls_list.some((poll) => poll.active);

          if (anyPollActive) {
            const singleActivePoll = polls_list.filter(
              (poll) => poll.active
            )[0];

            setAdminPollNavigation(pollContainerStates.LIVE);
            setLivePoll(singleActivePoll);
          } else {
            setAdminPollNavigation(pollContainerStates.LIST);
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = {
    //getters
    adminPollNavigation,
    polls,
    pollsNumber,
    livePoll,
    eventSubdomain,
    //setters
    setAdminPollNavigation,
    setPolls,
    setPollsNumber,
    setLivePoll,
    setPollData,
  };

  return (
    <AdminPollContext.Provider value={context}>
      {children}
    </AdminPollContext.Provider>
  );
}

export function useAdminPollContext() {
  return useContext(AdminPollContext);
}
