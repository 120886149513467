import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'is-check',
  viewBox = '0 0 24 16'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id="Artboard" fill={fill}>
      <path d='M7.00008645,15.99956 C6.97608645,15.99956 6.95208645,15.99856 6.92808645,15.99756 C6.66208645,15.97856 6.41508645,15.85356 6.24108645,15.65056 L0.241086454,8.65056004 C-0.118913546,8.23156004 -0.0699135464,7.60056004 0.349086454,7.24056004 C0.768086454,6.88156004 1.39908645,6.92956004 1.75908645,7.34956004 L7.10208645,13.58256 L22.3410865,0.248560044 C22.7570865,-0.116439956 23.3890865,-0.0744399557 23.7520865,0.341560044 C24.1160865,0.757560044 24.0740865,1.38856004 23.6590865,1.75256004 L7.65908645,15.75156 C7.47608645,15.91256 7.24108645,15.99956 7.00008645,15.99956'></path>
    </g>
  </svg>
)

export default SVG
