import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import IconError from "components/shared/icon/error";
import IconSoundOff from "components/shared/icon/sound_off";

function MuteAlert(props) {
  const { t } = useTranslation(['watch']);
  const [showMuteAlert, setShowMuteAlert] = useState(true);
  function handleCloseMessage(event) {
    event.preventDefault();
    setShowMuteAlert(false);
  }

  return (
    <>
      {showMuteAlert && (
        <div className="c-livestream__alert">
          <span>
            {t ("mute.alert_1")} 
            <IconSoundOff width={14} />{" "}
            {t ("mute.alert_2")}
          </span>
          <a
            href="/"
            onClick={handleCloseMessage}
            className="c-livestream__alert-exit"
          >
            <IconError width={14} />
          </a>
        </div>
      )}
    </>
  );
}

export default MuteAlert;
