import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'is-help',
  viewBox = '0 0 24 24'
}) => (
  <svg
    width={width}
    style={style}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path fill={fill} d='M12,0 C18.617,0 24,5.383 24,12 C24,18.617 18.617,24 12,24 C5.383,24 0,18.617 0,12 C0,5.383 5.383,0 12,0 Z M12,2 C6.486,2 2,6.486 2,12 C2,17.514 6.486,22 12,22 C17.514,22 22,17.514 22,12 C22,6.486 17.514,2 12,2 Z M11.7598,15.787 C12.2938,15.787 12.7118,15.931 13.0128,16.217 C13.3148,16.502 13.4648,16.896 13.4648,17.398 C13.4648,17.895 13.3148,18.286 13.0128,18.572 C12.7118,18.857 12.2938,19 11.7598,19 C11.2258,19 10.8068,18.857 10.5018,18.572 C10.1978,18.286 10.0448,17.895 10.0448,17.398 C10.0448,16.896 10.1978,16.502 10.5018,16.217 C10.8068,15.931 11.2258,15.787 11.7598,15.787 Z M12.0518,5 C12.7238,5 13.3348,5.089 13.8838,5.269 C14.4338,5.448 14.9058,5.699 15.2978,6.022 C15.6908,6.346 15.9928,6.732 16.2068,7.181 C16.4198,7.63 16.5278,8.128 16.5278,8.674 C16.5278,9.384 16.3578,9.993 16.0188,10.502 C15.6788,11.011 15.1768,11.463 14.5108,11.859 C14.1968,12.041 13.9378,12.209 13.7338,12.363 C13.5298,12.517 13.3658,12.674 13.2428,12.834 C13.1208,12.994 13.0348,13.163 12.9848,13.343 C12.8748,13.735 13.0148,14.449 12.4028,14.449 L10.9238,14.449 C10.4708,14.449 10.3938,13.932 10.3938,13.932 C10.3628,13.555 10.3718,13.216 10.4218,12.914 C10.4718,12.612 10.5668,12.337 10.7048,12.089 C10.8428,11.841 11.0268,11.614 11.2558,11.406 C11.4848,11.199 11.7628,11.001 12.0898,10.813 C12.3908,10.631 12.6438,10.463 12.8478,10.309 C13.0518,10.155 13.2158,10 13.3378,9.843 C13.4598,9.686 13.5468,9.521 13.5968,9.348 C13.6478,9.175 13.6718,8.979 13.6718,8.759 C13.6718,8.539 13.6298,8.338 13.5448,8.156 C13.4598,7.974 13.3398,7.817 13.1828,7.685 C13.0258,7.553 12.8368,7.45 12.6168,7.374 C12.3978,7.299 12.1528,7.261 11.8828,7.261 C11.3358,7.261 10.8978,7.415 10.5678,7.723 C10.3208,7.954 10.1568,8.245 10.0778,8.595 C10.0268,8.822 9.7998,8.966 9.5678,8.966 L7.8778,8.966 C7.2358,8.966 7.5958,7.847 7.8028,7.36 C8.0098,6.873 8.3088,6.456 8.6978,6.107 C9.0878,5.758 9.5628,5.487 10.1248,5.292 C10.6878,5.097 11.3298,5 12.0518,5 Z' id='Fill-1' />
  </svg>
)

export default SVG
