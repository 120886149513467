import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'is-back',
  viewBox = '0 0 16 24'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id="Artboard" fill={fill}>
      <path d='M14.9995,24.0008 C14.7835,24.0008 14.5655,23.9318 14.3825,23.7868 L0.3825,12.7868 C0.1415,12.5978 -0.0005,12.3078 -0.0005,12.0008 C-0.0005,11.6938 0.1415,11.4038 0.3825,11.2148 L14.3825,0.2148 C14.8165,-0.1282 15.4445,-0.0522 15.7865,0.3828 C16.1275,0.8168 16.0525,1.4458 15.6175,1.7868 L2.6185,12.0008 L15.6175,22.2148 C16.0525,22.5558 16.1275,23.1848 15.7865,23.6188 C15.5885,23.8698 15.2955,24.0008 14.9995,24.0008'></path>
    </g>
  </svg>
)

export default SVG
