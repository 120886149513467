import {
  GET_EVENT_TOKEN_COMPONENTS_REQUESTED,
  GET_EVENT_TOKEN_COMPONENTS_FULFILLED,
  GET_EVENT_TOKEN_COMPONENTS_REJECTED,
} from "./types";

import { getEventTokenComponents } from "api/events";

export function getEventByTokenComponents({ eventID, accessCode }, dispatch) {
  dispatch(getEventByTokenComponentsRequested());
  getEventTokenComponents(eventID, accessCode)
    .then((response) => {
      const components = response.data;
      dispatch(getEventByTokenComponentsFulfilled(components));
    })
    .catch((error) => {
      dispatch(getEventByTokenComponentsRejected(error));
    });
}

function getEventByTokenComponentsRequested() {
  return {
    type: GET_EVENT_TOKEN_COMPONENTS_REQUESTED,
    eventComponents: {
      loading: true,
      success: false,
      error: false,
      data: [],
    },
  };
}
function getEventByTokenComponentsFulfilled(data) {
  return {
    type: GET_EVENT_TOKEN_COMPONENTS_FULFILLED,
    eventComponents: {
      loading: false,
      success: true,
      error: false,
      data,
    },
  };
}
function getEventByTokenComponentsRejected(error) {
  return {
    type: GET_EVENT_TOKEN_COMPONENTS_REJECTED,
    eventComponents: {
      loading: false,
      success: false,
      error,
      data: [],
    },
  };
}
