import React from "react";

import { useAdminPollContext } from "contexts/adminPoll";

import SinglePoll from "./SinglePoll";

function PollList(props) {
  const { polls } = useAdminPollContext();

  return (
    <>
      {polls.map((poll, index) => (
        <SinglePoll key={index} pollData={poll} />
      ))}
    </>
  );
}

export default PollList;
