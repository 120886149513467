import React from "react"

const SVG = ({
  style = {},
  fill = "currentColor",
  width = "100%",
  className = "is-update",
  viewBox = "0 0 24 21"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`c-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

    <g id="Artboard" fill={fill}>
      <path d="M23.6504,8.741 C23.2324,8.381 22.6004,8.429 22.2414,8.849 L20.9904,10.308 C20.8864,4.607 16.2254,1.77635684e-15 10.5004,1.77635684e-15 C4.7104,1.77635684e-15 0.0004,4.71 0.0004,10.5 C0.0004,16.29 4.7104,21 10.5004,21 C11.0524,21 11.5004,20.553 11.5004,20 C11.5004,19.447 11.0524,19 10.5004,19 C5.8134,19 2.0004,15.186 2.0004,10.5 C2.0004,5.813 5.8134,2 10.5004,2 C15.1144,2 18.8734,5.698 18.9894,10.285 L17.7584,8.849 C17.4004,8.429 16.7684,8.381 16.3494,8.741 C15.9294,9.1 15.8804,9.731 16.2414,10.15 L19.2414,13.65 C19.4304,13.872 19.7084,14 20.0004,14 C20.2924,14 20.5694,13.872 20.7584,13.65 L23.7584,10.15 C24.1194,9.731 24.0704,9.1 23.6504,8.741" id="path-1"></path>
    </g>
  </svg>
)

export default SVG
