import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'is-eye',
  viewBox = '0 0 24 16'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id="Artboard" fill={fill}>
      <path d='M12,0 C18.508,0 24,4.914 24,8 C24,11.086 18.508,16 12,16 C5.608,16 0,11.014 0,8 C0,4.914 5.492,0 12,0 Z M12,2 C6.435,2 2,6.309 2,8 C2,9.691 6.435,14 12,14 C17.672,14 22,9.74 22,8 C22,6.309 17.565,2 12,2 Z M12.001,3.0015 C14.758,3.0015 17.001,5.2445 17.001,8.0015 C17.001,10.7585 14.758,13.0015 12.001,13.0015 C9.244,13.0015 7.001,10.7585 7.001,8.0015 C7.001,5.2445 9.244,3.0015 12.001,3.0015 Z M12.001,5.0015 C10.347,5.0015 9.001,6.3475 9.001,8.0015 C9.001,9.6555 10.347,11.0015 12.001,11.0015 C13.655,11.0015 15.001,9.6555 15.001,8.0015 C15.001,6.3475 13.655,5.0015 12.001,5.0015 Z'></path>
    </g>
  </svg>
)

export default SVG
