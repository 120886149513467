import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import { useAuth } from "../../../../../contexts/authContext";

export default function AuthToLogin({ children, ...rest }) {
  const { authToken, requestUserData, setUserData, logOut } = useAuth();

  useEffect(() => {
    if (authToken) {
      requestUserData()
        .then((request) => {
          const { profile_info } = request.data;
          setUserData(profile_info);
        })
        .catch((request) => {
          logOut();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
