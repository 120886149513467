import React from "react";
import { useTranslation } from 'react-i18next';

import { useAdminPollContext } from "contexts/adminPoll";
import { pollContainerStates } from "consts/pollContainerStates";

function EmptyPoll(props) {
  const { t } = useTranslation(['watch']);
  const { setAdminPollNavigation } = useAdminPollContext();

  function handleCreateNavigate(event) {
    event.preventDefault();
    setAdminPollNavigation(pollContainerStates.CREATE);
  }

  return (
    <div className="c-poll__empty">
      <h3 className="c-poll__title">{t ("poll.empty_title")}</h3>
      <p>{t ("poll.empty_title")}</p>
      <a
        href="/"
        onClick={handleCreateNavigate}
        className="c-poll__button o-button--lg o-button--green"
      >
        {t ("poll.empty_create")}
      </a>
    </div>
  );
}

export default EmptyPoll;
