import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'is-check-filled',
  viewBox = '0 0 48 36'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id="Artboard" fill={fill}>
      <path d='M15.3107277,35.393242 L0.570892884,20.2854113 C-0.203098444,19.4914202 -0.189098601,18.2194344 0.60689248,17.4434431 L4.9248441,13.2314903 C5.7188352,12.457499 6.99082095,12.4734988 7.76681225,13.2674899 L16.980709,22.7133841 L40.4704458,0.547632418 C41.2784368,-0.214359044 42.5504225,-0.17635947 43.312414,0.631631477 L47.4523676,5.01758233 C48.214359,5.8235733 48.1763595,7.09755903 47.3683685,7.85755051 L18.1286962,35.4512413 C17.3307051,36.2052329 16.0767191,36.1792332 15.3107277,35.393242'></path>
    </g>
  </svg>
)

export default SVG
