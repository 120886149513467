import {
  GET_STRIPE_TOKEN_REQUESTED,
  GET_STRIPE_TOKEN_FULFILLED,
  GET_STRIPE_TOKEN_REJECTED,
  RESET_STRIPE_TOKEN,
} from "./types";

import { createXsollaToken as createStripeTokenAPI } from "api/events";

export function getStripeToken(data, dispatch) {
  dispatch(getStripeTokenRequested());
  createStripeTokenAPI(data)
    .then((response) => {
      const object = response.data;
      dispatch(getStripeTokenFulfilled(object.token));
    })
    .catch((error) => {
      dispatch(getStripeTokenRejected(error));
    });
}

function getStripeTokenRequested() {
  return {
    type: GET_STRIPE_TOKEN_REQUESTED,
    stripeToken: {
      loading: true,
      success: false,
      error: false,
      data: null,
    },
  };
}
function getStripeTokenFulfilled(data) {
  return {
    type: GET_STRIPE_TOKEN_FULFILLED,
    stripeToken: {
      loading: false,
      success: true,
      error: false,
      data,
    },
  };
}
function getStripeTokenRejected(error) {
  return {
    type: GET_STRIPE_TOKEN_REJECTED,
    stripeToken: {
      loading: false,
      success: false,
      error,
      data: null,
    },
  };
}
export function resetStripeToken() {
  return {
    type: RESET_STRIPE_TOKEN,
  };
}
