import React from "react";

import { tryDataLayerPush } from "utils/gtm";
import { useTranslation } from "react-i18next";
import { useLevelStoreContext } from "contexts/LevelStoreContext";
import CrownIcon from "components/shared/icon/crown_filled";
import { useWatchEventContext } from "contexts/WatchEventContext";

function ChatLevelUp(props) {
  const { t } = useTranslation(["watch"]);
  const { showLevels } = useLevelStoreContext();
  const { eventData } = useWatchEventContext();

  function handleLevelUp(event) {
    event.preventDefault();
    showLevels();
    tryDataLayerPush({
      event: "click_levelup_chat",
    });
  }
  return (
    eventData.marketable && (
      <div data-tooltip={t("livestream.levelup")}>
        <a href="/" onClick={handleLevelUp} className="c-chat__levelup">
          <CrownIcon width={20} />
        </a>
      </div>
    )
  );
}
export default ChatLevelUp;
