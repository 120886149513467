import React, { useState, useEffect } from "react";

import getRandomArbitraryNum from "utils/getRandomArbitraryNum";

function WaterMark(props) {
  const { userID } = props;

  const [showWaterMark, setShowWaterMark] = useState(true);

  const positionClasses = [
    "top-center",
    "top-left",
    "top-right",
    "bottom-center",
    "bottom-left",
    "bottom-right",
    "left-center",
    "right-center",
  ];

  useEffect(() => {
    const delay = 300000;
    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, []);

  function tick() {
    const delay = 0;
    setShowWaterMark((val) => !val);
    setTimeout(() => {
      setShowWaterMark((val) => !val);
    }, delay);
  }

  function renderCode(userID) {
    const arrayID = userID ? userID.toString().split("") : [];
    return arrayID.map((val, idx) => (
      <div key={idx} className="id-item">{val}</div>
    ));
  }

  const randomPos = getRandomArbitraryNum(0, positionClasses.length);
  return (
    showWaterMark && (
      <div className={`vjs-id ${positionClasses[randomPos]}`}>{renderCode(userID)}</div>
    )
  );
}
export default WaterMark;
