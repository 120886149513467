import axios from "axios";

import {
  BASE_URL_V3,
  GET_XSOLLA_ORDER_STATUS,
  SET_XSOLLA_ORDER_STATUS,
} from "../endpoints";

export function getXsollaOrderStatus(token) {
  return axios.get(`${BASE_URL_V3}${GET_XSOLLA_ORDER_STATUS(token)}`);
}
export function setXsollaOrderStatus(data) {
  return axios.post(`${BASE_URL_V3}${SET_XSOLLA_ORDER_STATUS}`, data);
}
