import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
// import Fade from "../Fade";
import { tryDataLayerPush } from "utils/gtm";
import getRandomArbitraryNum from "utils/getRandomArbitraryNum";
import { useChannelWebsocketContext } from "contexts/channelWebsocket";
import { useChatWebsocketContext } from "contexts/chatWebsocket";
import { useAuth } from "contexts/authContext";
import { useEventComponentsContext } from "contexts/EventComponentsContext";
import { useWatchEventContext } from "contexts/WatchEventContext";
import useQuery from "hooks/useQuery";

import IconSettings from "components/shared/icon/settings";
import IconClose from "components/shared/icon/error";
import ChatStyled from "components/layouts/styled/ChatStyled";
import PaymentAlert from "./PaymentAlert";
import MessageList from "./MessageList";
import MessageForm from "./MessageForm";
import EmptyChat from "./EmptyChat";

import { MESSAGE_TYPES } from "./consts";

const ACTION_CHANNEL_CHAT_STREAM_MESSAGE = "channelChatStreamMessage";
const ACTION_USER_BOUGHT_LEVEL = "userLevelBought";

const SEND_MESSAGE_TIMEOUT_MS = 2000;
const MAX_BUFFER_ITEMS = 10;

const CHAT_MESSAGES_MONETIZATION = [
  "¿Ya eres Top Fan? 😎  Consigue stickers y avatares exclusivos 👑",
  "Conviértete en Top Fan para usar stickers y avatares exclusivos 😎 ¡Haz Level Up! Manda este sticker si estás disfrutando el live",
];

// const REACT_APP_CHAT_USE_V2 = process.env.REACT_APP_CHAT_USE_V2;

function VanillaChat(props) {
  const [messages, setMessages] = useState([]);
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  // const [chatUsernameWithBadge, setChatUsernameWithBadge] = useState("");
  const messagesRef = useRef([]);
  const { t } = useTranslation(["watch"]);
  // const isChatV2 = REACT_APP_CHAT_USE_V2 === "true";
  const { eventData } = useWatchEventContext();
  const { badgesComponents, componentsState } = useEventComponentsContext();
  const {
    lastJsonMessage: lastMessagev2,
    // joinChat,
    // userJoined,
  } = useChatWebsocketContext();
  const { lastJsonMessage: lastMessage } = useChannelWebsocketContext();
  const { chatUsername } = useAuth();
  const query = useQuery();
  const localBots = query.get("localBots");

  useEffect(() => {
    if (eventData.marketable) {
      const randMessage =
        CHAT_MESSAGES_MONETIZATION[
          getRandomArbitraryNum(0, CHAT_MESSAGES_MONETIZATION.length - 1)
        ];
      addMessageChatClient({
        avatar: "bot-4",
        message: randMessage,
        me: false,
        type: MESSAGE_TYPES.MESSAGE,
        author: "NEERME@admin#1|",
      });
      setInterval(() => {
        const randMessage =
          CHAT_MESSAGES_MONETIZATION[
            getRandomArbitraryNum(0, CHAT_MESSAGES_MONETIZATION.length - 1)
          ];
        addMessageChatClient({
          avatar: "bot-4",
          message: randMessage,
          me: false,
          type: MESSAGE_TYPES.MESSAGE,
          author: "NEERME@admin#1|",
        });
      }, 900000);
    }
  }, [eventData.marketable]);

  useEffect(() => {
    if (lastMessage !== null) {
      const { action, data } = lastMessage;
      switch (action) {
        case ACTION_USER_BOUGHT_LEVEL:
          handleUserNotification(data);
          break;

        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  // useEffect(() => {
  //   if (isChatV2 && chatUsername && !userJoined) {
  //     joinChat();
  //   }
  // }, []);

  const badgesIDs = badgesComponents.rest.map((comp) => comp.id).join(",");
  const chatUsernameWithBadge = `${chatUsername}|${badgesIDs ? badgesIDs : ""}`;

  useEffect(() => {
    // if (!isChatV2) return;
    if (lastMessagev2 !== null) {
      const { action, data } = lastMessagev2;

      switch (action) {
        case ACTION_CHANNEL_CHAT_STREAM_MESSAGE:
          actionsToQueue(data);
          break;

        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessagev2]);

  useEffect(() => {
    const feedChatMsg = setInterval(() => {
      if (messagesRef.current.length === 0) return;
      const firstMsg = messagesRef.current.shift();
      handleChannelChatStreamMessage(firstMsg);
    }, SEND_MESSAGE_TIMEOUT_MS);

    return () => {
      clearInterval(feedChatMsg);
    };
    // eslint-disable-next-line
  }, [chatUsername, componentsState, badgesComponents.rest]);

  useEffect(() => {
    if (localBots === "true") {
      setInterval(() => {
        const epoch = Date.now();
        addMessageChatClient({
          avatar: "bot-6",
          message: "Some randome message " + epoch,
          me: false,
          type: MESSAGE_TYPES.MESSAGE,
        });
      }, 1000);
    }
  }, [localBots]);

  function handleUserNotification(data) {
    for (let index = 0; index < data.length; index++) {
      const notification = data[index];

      const messageData = {
        ...notification,
        me: false,
        type: MESSAGE_TYPES.NOTIFICATION,
      };
      addMessageChatClient(messageData);
    }
  }

  function actionsToQueue(messages) {
    const bufferLenght = messagesRef.current.length;
    if (bufferLenght >= MAX_BUFFER_ITEMS) return;

    const itemsNeeded = MAX_BUFFER_ITEMS - bufferLenght;
    const messagePayload = messages.length;

    if (messagePayload === itemsNeeded || messagePayload < itemsNeeded) {
      messagesRef.current = [...messagesRef.current, ...messages];
    } else {
      messagesRef.current = [
        ...messagesRef.current,
        ...messages.slice(0, itemsNeeded),
      ];
    }
  }

  function handleChannelChatStreamMessage(data) {
    if (data.author === chatUsernameWithBadge) return;
    handleChatMessage(data);
  }

  function addMessageChatClient(messageData) {
    setMessages((oldMsg) => {
      if (oldMsg.length >= 30) {
        oldMsg.shift();
      }
      return [...oldMsg, messageData];
    });
  }

  function handleChatMessage(data) {
    const messageData = {
      ...data,
      me: data.author === chatUsernameWithBadge,
      type: MESSAGE_TYPES.MESSAGE,
    };
    addMessageChatClient(messageData);
  }

  function handleShowChatOptions(event) {
    event.preventDefault();
    tryDataLayerPush({
      event: "config-username-click",
    });
    setShowUpdateUser(true);
  }
  function handleCloseChatOptions(event) {
    event.preventDefault();
    setShowUpdateUser(false);
  }

  return (
    <ChatStyled className="c-chat">
      <div className="c-livestream__header">
        <h6 className="c-livestream__title">
          {showUpdateUser ? t("livestream.edit") : t("livestream.chat")}
        </h6>
        {showUpdateUser ? (
          <a
            data-tooltip="Close"
            href="/"
            className="c-livestream__button tooltip-top-right"
            onClick={handleCloseChatOptions}
          >
            <IconClose width={16} />
          </a>
        ) : (
          <a
            data-tooltip="Settings"
            href="/"
            className="c-livestream__button tooltip-top-right"
            onClick={handleShowChatOptions}
          >
            <IconSettings width={16} />
          </a>
        )}
      </div>
      <PaymentAlert />
      {showUpdateUser ? (
        <EmptyChat
          isChatV2
          isUpdate={showUpdateUser}
          showUpdateHandler={setShowUpdateUser}
        />
      ) : (
        <>
          <MessageList messages={messages} />
          <MessageForm
            messageHandler={handleChatMessage}
            showUpdateHandler={setShowUpdateUser}
          />
        </>
      )}
    </ChatStyled>
  );
}

export default VanillaChat;
