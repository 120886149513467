import React, { useEffect, useState, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useAdminPollContext } from "contexts/adminPoll";

import { pollContainerStates } from "consts/pollContainerStates";

import { getPollDetail, switchPoll } from "../store/actions";
import reducer, { initialPoll } from "../store/reducer";

const LONG_POOLING_MS = 15000;

function CurrentPoll(props) {
  const { t } = useTranslation(["watch"]);
  const {
    setAdminPollNavigation,
    livePoll,
    setLivePoll,
    setPollData,
  } = useAdminPollContext();

  const [totaVotes, setTotaVotes] = useState(0);
  const [mostVotedAnswer, setMostVotedAnswer] = useState({});
  const [pollState, dispatchEvent] = useReducer(reducer, initialPoll);
  const intervalDetail = useRef(null);

  function AnswerList(props) {
    const { answers } = props;

    return answers.map((ans, idx) => {
      return (
        <li className="c-poll__stats" key={idx}>
          <span>{ans.option_label}</span>
          <h4>{ans.total}</h4>
        </li>
      );
    });
  }
  useEffect(() => {
    if (pollState.getPollDetail.success) {
      setLivePoll(pollState.getPollDetail.data);
    }
  }, [pollState.getPollDetail, setLivePoll]);

  function handleFinish(event) {
    event.preventDefault();
    const payload = {
      active: false,
    };
    switchPoll({ pollID: livePoll.id, payload }, dispatchEvent);
    setLivePoll({});
    setPollData();
    setAdminPollNavigation(pollContainerStates.LIST);
  }

  useEffect(() => {
    const { answers } = livePoll;
    if (livePoll.answers) {
      setTotaVotes(answers.reduce((a, b) => +a + +b.total, 0));
      setMostVotedAnswer(answers.sort((a, b) => b.total - a.total)[0]);
    }
  }, [livePoll]);

  useEffect(() => {
    if (livePoll?.id) {
      intervalDetail.current = setInterval(() => {
        getPollDetail({ pollID: livePoll.id }, dispatchEvent);
      }, LONG_POOLING_MS);
    }

    return () => {
      if (intervalDetail.current) {
        clearInterval(intervalDetail.current);
      }
    };
  }, [livePoll]);


  return (
    <div className="c-poll__current">
      <div className="c-poll__flex">
        <h6 className="c-poll__header">{t("poll.live")}</h6>
        <h4 className="c-poll__title">{livePoll.name}</h4>

        {totaVotes > 0 && (
          <h3 className="c-poll__winner">{mostVotedAnswer.option_label}</h3>
        )}

        <div className="c-poll__summary">
          <h5>{t("poll.interactions")}</h5>
          <h4>{totaVotes}</h4>
        </div>
        <ul>
          <AnswerList answers={livePoll.answers || []} />
        </ul>
      </div>
      <a
        href="/"
        onClick={handleFinish}
        className="c-poll__button o-button--bs o-button--outline is-light"
      >
        {t("poll.end")}
      </a>
    </div>
  );
}

export default CurrentPoll;
