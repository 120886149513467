import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'is-sticker',
  viewBox = '0 0 48 48'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id="Artboard" fill={fill}>
      <path d='M44,20 C44,20.676 43.954,21.34 43.898,22 L32,22 C26.486,22 22,26.486 22,32 L22,43.898 C21.338,43.954 20.674,44 20,44 L10,44 C6.692,44 4,41.308 4,38 L4,10 C4,6.692 6.692,4 10,4 L38,4 C41.308,4 44,6.692 44,10 L44,20 Z M26,43.21 L26,32 C26,28.692 28.692,26 32,26 L43.21,26 C41.034,34.412 34.412,41.034 26,43.21 L26,43.21 Z M38,0 L10,0 C4.486,0 0,4.486 0,10 L0,38 C0,43.514 4.486,48 10,48 L20,48 C35.44,48 48,35.44 48,20 L48,10 C48,4.486 43.514,0 38,0 L38,0 Z'></path>
    </g>
  </svg>
)

export default SVG
