import React from "react";

const SVG = ({
  style = {},
  fill = "#E14C6C",
  width = "100%",
  height = "100%",
  className = "is-neerme-icon",
  viewBox = "0 0 66 40",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`c-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" fill={fill}>
        <path
          d="M8.80859712,11.671 L26.6425971,33.176 L8.80859712,33.176 L8.80859712,11.671 Z M31.7695971,28.596 L13.7145971,6.824 L31.7695971,6.824 L31.7695971,28.596 Z M64.8895971,6.824 L64.8895971,0 L39.4675971,0 L33.6755971,0 L32.1035971,0 L31.7695971,0 L8.05559712,0 L0.889597119,0 L0.889597119,40 L8.58759712,40 L32.1035971,40 L32.3015971,40 L33.6755971,40 L39.4675971,40 L64.8895971,40 L64.8895971,33.176 L40.0235971,33.176 L40.0235971,22.838 L63.6875971,22.838 L63.6875971,16.015 L40.0235971,16.015 L40.0235971,6.824 L64.8895971,6.824 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
);

export default SVG;
