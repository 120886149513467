import React, { useEffect, useReducer } from "react";

import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";

import Loading from "components/shared/Loading";
import { useLevelStoreContext } from "contexts/LevelStoreContext";
import { useWatchEventContext } from "contexts/WatchEventContext";
import isMobile from "utils/isMobile";
import { isObject } from "utils/dataStructures";

import reducer, { initialXsollaToken } from "./store/reducer";
import { createXsollaToken, resetXsollaToken } from "./store/actions";

function XsollaProcess(props) {
  const [xsollaState, dispatchEvent] = useReducer(reducer, initialXsollaToken);

  const { boughtLevel, hideLevels } = useLevelStoreContext();
  const { i18n } = useTranslation(["watch"]);
  const { accessCode } = useWatchEventContext();

  const {
    loading: loadingXsolla,
    data: tokenXsolla,
    success: successXsolla,
  } = xsollaState;

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (event.origin !== "https://secure.xsolla.com") return;
        let xsollaMsgObj = {};

        try {
          xsollaMsgObj = JSON.parse(event.data);
        } catch (error) {}
        if (isObject(xsollaMsgObj)) {
          if (xsollaMsgObj?.data?.action === "change-status") {
            dispatchEvent(resetXsollaToken());
            window.dispatchEvent(
              new CustomEvent("xsollaPaymentCheck", {
                detail: { xsolla: true, tokenXsolla },
              })
            );
            hideLevels();
          }
          // if (
          //   xsollaMsgObj?.data?.action === "change-status" &&
          //   xsollaMsgObj?.data?.value === "done"
          // ) {
          //   dispatchEvent(resetXsollaToken());
          //   showSuccess();
          //   loadComponents();
          // }
        }
      },
      false
    );

    // eslint-disable-next-line
  }, [xsollaState]);

  useEffect(() => {
    const payload = {
      access_token: accessCode,
      level_id: boughtLevel.id,
      language: i18n.language,
      is_mobile: isMobile,
    };
    createXsollaToken(payload, dispatchEvent);
    // eslint-disable-next-line
  }, []);

  return loadingXsolla ? (
    <Loading />
  ) : (
    successXsolla && (
      <Iframe
        url={`https://secure.xsolla.com/paystation3/?access_token=${tokenXsolla}`}
        width="100%"
        className="xsolla-iframe"
        frameborder="0"
        scrolling="yes"
      />
    )
  );
}

export default XsollaProcess;
