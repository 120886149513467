import React, { createContext, useContext, useEffect, useRef } from "react";

import useScript from "hooks/useScript";

const REBUS_WIDGET_URL = process.env.REACT_APP_REBUS_WIDGET_URL;

export const RebusContext = createContext({});

export function RebusContextProvider(props) {
  const { children, merchantInfo } = props;
  // eslint-disable-next-line
  const [loadedRebus, loadRebusError] = useScript(REBUS_WIDGET_URL);
  const rebusInstance = useRef();

  const rebusSettings = {
    side: "right",
    initialState: "close",
    token: merchantInfo.access_key,
  };
  useEffect(() => {
    if (loadedRebus) {
      // eslint-disable-next-line
      rebusInstance.current = WidgetRebus;
      rebusInstance.current.default.mount(rebusSettings);
    }
    return () => {
      if (rebusInstance.current) rebusInstance.current.default.unmount();
    };
    // eslint-disable-next-line
  }, [loadedRebus]);

  const context = {
    rebusInstance: rebusInstance.current,
  };
  return (
    <RebusContext.Provider value={context}>{children}</RebusContext.Provider>
  );
}

export function useRebusContext() {
  return useContext(RebusContext);
}
