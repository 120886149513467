import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'is-error',
  viewBox = '0 0 24 24'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id="Artboard" fill={fill}>
      <path d='M23.7051,22.2232 L13.4141,11.9952 L23.6431,1.7042 C24.0321,1.3132 24.0301,0.6792 23.6381,0.2902 C23.2471,-0.0988 22.6141,-0.0958 22.2241,0.2942 L11.9961,10.5852 L1.7051,0.3582 C1.3131,-0.0318 0.6811,-0.0288 0.2901,0.3622 C-0.0989,0.7532 -0.0969,1.3872 0.2951,1.7762 L10.5861,12.0032 L0.3571,22.2942 C-0.0319,22.6862 -0.0299,23.3202 0.3621,23.7082 C0.5571,23.9032 0.8121,23.9992 1.0671,23.9992 C1.3241,23.9992 1.5801,23.9012 1.7761,23.7042 L12.0041,13.4132 L22.2951,23.6412 C22.4901,23.8352 22.7451,23.9322 23.0001,23.9322 C23.2571,23.9322 23.5141,23.8332 23.7091,23.6372 C24.0991,23.2452 24.0971,22.6122 23.7051,22.2232'></path>
    </g>
  </svg>
)

export default SVG
