import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { tryDataLayerPush } from "utils/gtm";
import CloseIcon from "components/shared/icon/error";
import StickerIcon from "components/shared/icon/sticker";
import IconLock from "components/shared/icon/lock";
import { useAuth } from "contexts/authContext";
import { isObject } from "utils/dataStructures";
import { useLevelStoreContext } from "contexts/LevelStoreContext";
import { useEventComponentsContext } from "contexts/EventComponentsContext";

function Stickers(props) {
  const { t } = useTranslation(["watch"]);
  const { sendMessage, showUpdateHandler } = props;
  const { showLevels } = useLevelStoreContext();
  const { stickerComponents } = useEventComponentsContext();
  const [showStickers, setShowStickers] = useState(false);
  const stickerPickerRef = useRef();
  const { chatUsername } = useAuth();

  function handleShowStickers(event) {
    event.preventDefault();
    if (!chatUsername) {
      showUpdateHandler(true);
      return;
    }
    tryDataLayerPush({
      event: "use-sticker-button-click",
    });
    setShowStickers((val) => !val);
  }

  function handleClickOutside(event) {
    if (
      stickerPickerRef.current &&
      !stickerPickerRef.current.contains(event.target)
    ) {
      setShowStickers(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function StickerListElement(props) {
    const { url, name, locked } = props;

    function sendHandler(event) {
      event.preventDefault();
      if (locked) {
        showLevels();
        tryDataLayerPush({
          event: "level-up-sticker-locked-click",
        });
      } else {
        sendMessage(name);
      }
      setShowStickers(false);
    }

    return (
      <li>
        <a
          onClick={sendHandler}
          href="/"
          className="tooltip-top-left"
          data-tooltip={locked ? t("chat.level_up_sticker") : name}
        >
          <img className="c-stickers__image" width="50" src={url} alt={name} />
          {locked && (
            <div className="c-avatar__lock">
              <IconLock width={14} />
            </div>
          )}
        </a>
      </li>
    );
  }

  return (
    <>
      <button
        className="c-chat__send"
        onClick={handleShowStickers}
        data-tooltip={t("chat.stickers")}
      >
        <StickerIcon width={20} />
      </button>
      {showStickers && (
        <>
          <div className="c-stickers__overlay"></div>
          <div className="c-stickers" ref={stickerPickerRef}>
            <div className="c-stickers__header">
              <h6 className="c-stickers__title">Stickers</h6>
              <a
                href="/"
                onClick={handleShowStickers}
                className="c-stickers__close"
              >
                <CloseIcon width="16" />
              </a>
            </div>

            <div className="c-stickers--scroll">
              {stickerComponents.marketableDisabled.map((stickerComponent) => {
                const stickers = stickerComponent.properties?.stickers;
                return (
                  <React.Fragment key={stickerComponent.id}>
                    <h6 className="c-chat__title">{stickerComponent.name}</h6>
                    <ul className="c-stickers__container">
                      {isObject(stickers) &&
                        Object.keys(stickers).map((sticker, idx) => (
                          <StickerListElement
                            key={idx}
                            url={stickers[sticker]}
                            name={sticker}
                            locked
                          />
                        ))}
                    </ul>
                  </React.Fragment>
                );
              })}
              {stickerComponents.rest.map((stickerComponent) => {
                const stickers = stickerComponent.properties?.stickers;
                return (
                  <React.Fragment key={stickerComponent.id}>
                    <h6 className="c-chat__title">{stickerComponent.name}</h6>
                    <ul className="c-stickers__container is-last">
                      {isObject(stickers) &&
                        Object.keys(stickers).map((sticker, idx) => (
                          <StickerListElement
                            key={idx}
                            url={stickers[sticker]}
                            name={sticker}
                          />
                        ))}
                    </ul>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Stickers;
