import {
  SWITCH_POLL_STATUS_REQUESTED,
  SWITCH_POLL_STATUS_FULFILLED,
  SWITCH_POLL_STATUS_REJECTED,
  GET_EVENT_DETAIL_REQUESTED,
  GET_EVENT_DETAIL_REJECTED,
  GET_EVENT_DETAIL_FULFILLED,
} from "./types";

import { switchPoll as switchPollAPI } from "api/polls";
import { getPollDetail as getPollDetailAPI } from "api/polls";

export function getPollDetail({ pollID }, dispatch) {
  dispatch(getPollDetailRequested());
  getPollDetailAPI(pollID)
  .then((response) => {
    const data = response.data;
    dispatch(getPollDetailFulfilled(data));
  })
  .catch((error) => {
      dispatch(getPollDetailRejected(error));
    });
}

function getPollDetailRequested() {
  return {
    type: GET_EVENT_DETAIL_REQUESTED,
    getPollDetail: {
      loading: true,
      success: false,
      error: false,
      data: {},
    },
  };
}
function getPollDetailFulfilled(data) {
  return {
    type: GET_EVENT_DETAIL_FULFILLED,
    getPollDetail: {
      loading: false,
      success: true,
      error: false,
      data: data,
    },
  };
}
function getPollDetailRejected(error) {
  return {
    type: GET_EVENT_DETAIL_REJECTED,
    getPollDetail: {
      loading: false,
      success: false,
      error,
    },
  };
}

export function switchPoll({ pollID, payload }, dispatch) {
  dispatch(switchPollRequested());
  switchPollAPI(pollID, payload)
    .then((response) => {
      const data = response.data;
      dispatch(switchPollFulfilled(data));
    })
    .catch((error) => {
      dispatch(switchPollRejected(error));
    });
}

function switchPollRequested() {
  return {
    type: SWITCH_POLL_STATUS_REQUESTED,
    switchPoll: {
      loading: true,
      success: false,
      error: false,
      data: {},
    },
  };
}
function switchPollFulfilled(data) {
  return {
    type: SWITCH_POLL_STATUS_FULFILLED,
    switchPoll: {
      loading: false,
      success: true,
      error: false,
      data: data,
    },
  };
}
function switchPollRejected(error) {
  return {
    type: SWITCH_POLL_STATUS_REJECTED,
    switchPoll: {
      loading: false,
      success: false,
      error,
    },
  };
}
