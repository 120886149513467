import React, { useState, useEffect, useRef } from "react";

const autoQuality = -1;
const hdHeights = [720, 1080];

function CustomSettings(props) {
  const { hls } = props.mediaObjects;

  const [showMenu, setShowMenu] = useState(false);
  const [quality, setQuality] = useState(autoQuality);

  const qualityMenu = useRef();

  function handleShowMenu(event) {
    event.preventDefault();
    setShowMenu((val) => !val);
  }

  function handleChangeQuality(event) {
    event.preventDefault();
    const qualityValue = parseInt(event.currentTarget.value);
    setQuality(qualityValue);
    hls.currentLevel = qualityValue;
  }
  useEffect(() => {
    function handleClickOutside(event) {
      if (qualityMenu.current && !qualityMenu.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function Levels() {
    const hlsLevels = hls.levels;
    const levelsLength = hlsLevels.length;
    return hlsLevels
      .slice(0)
      .reverse()
      .map((level, idx) => {
        const { height } = level;
        const levelValue = levelsLength - idx - 1;
        return (
          <button
            key={idx}
            data-plyr="quality"
            type="button"
            role="menuitemradio"
            class="plyr__control"
            aria-checked={levelValue === quality}
            value={levelValue}
            onClick={handleChangeQuality}
          >
            <span>
              {height}p
              {hdHeights.includes(height) && (
                <span class="plyr__menu__value">
                  <span class="plyr__badge">HD</span>
                </span>
              )}
            </span>
          </button>
        );
      });
  }

  return (
    <>
      <div class="plyr__controls__item plyr__menu">
        <button
          aria-haspopup="false"
          aria-controls="plyr-settings-9773"
          aria-expanded="false"
          type="button"
          class="plyr__control"
          data-plyr="settings"
          onClick={handleShowMenu}
        >
          <svg id="plyr-settings" viewBox="0 0 18 18">
            <path d="M16.135 7.784a2 2 0 01-1.23-2.969c.322-.536.225-.998-.094-1.316l-.31-.31c-.318-.318-.78-.415-1.316-.094a2 2 0 01-2.969-1.23C10.065 1.258 9.669 1 9.219 1h-.438c-.45 0-.845.258-.997.865a2 2 0 01-2.969 1.23c-.536-.322-.999-.225-1.317.093l-.31.31c-.318.318-.415.781-.093 1.317a2 2 0 01-1.23 2.969C1.26 7.935 1 8.33 1 8.781v.438c0 .45.258.845.865.997a2 2 0 011.23 2.969c-.322.536-.225.998.094 1.316l.31.31c.319.319.782.415 1.316.094a2 2 0 012.969 1.23c.151.607.547.865.997.865h.438c.45 0 .845-.258.997-.865a2 2 0 012.969-1.23c.535.321.997.225 1.316-.094l.31-.31c.318-.318.415-.781.094-1.316a2 2 0 011.23-2.969c.607-.151.865-.547.865-.997v-.438c0-.451-.26-.846-.865-.997zM9 12a3 3 0 110-6 3 3 0 010 6z"></path>
          </svg>
        </button>

        {showMenu && (
          <div
            class="plyr__menu__container"
            id="plyr-settings-9773"
            ref={qualityMenu}
          >
            <div>
              <div id="plyr-settings-9773-quality">
                <div role="menu">
                  <span aria-hidden="true">Ajusta la calidad</span>
                  <Levels />
                  <button
                    data-plyr="quality"
                    type="button"
                    role="menuitemradio"
                    class="plyr__control"
                    aria-checked={quality === autoQuality}
                    value={autoQuality}
                    onClick={handleChangeQuality}
                  >
                    <span>Auto</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CustomSettings;
