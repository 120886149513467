import React, { useState, useEffect, useReducer } from "react";

import { useForm } from "react-hook-form";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

import Loading from "components/shared/Loading";
import { useLevelStoreContext } from "contexts/LevelStoreContext";
import { useWatchEventContext } from "contexts/WatchEventContext";
import isMobile from "utils/isMobile";
import IconCrown from "components/shared/icon/crown";
import IconLoading from "components/shared/icon/loading";
import IconClose from "components/shared/icon/error";
import { COUNTRIES_LIST } from "consts";
import reducer, { initialStripeToken } from "./store/reducer";
import { getStripeToken, resetStripeToken } from "./store/actions";

function CheckoutForm(props) {
  const { handleSubmit, register } = useForm();

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);

  const [stripeState, dispatchEvent] = useReducer(reducer, initialStripeToken);
  const { accessCode, eventData } = useWatchEventContext();
  const { boughtLevel, hideLevels } = useLevelStoreContext();

  const { i18n, t } = useTranslation(["watch"]);
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: "proxima-nova, Helvetica, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  function handleChange(event) {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  }

  function onSubmit(data) {
    setProcessing(true);
    const { city, state, country, zip, address, name } = data;
    const billingDetails = {
      address: {
        postal_code: zip,
        line1: address,
        city,
        country,
        state,
      },
      name,
    };
    stripe
      .confirmCardPayment(stripeState.data, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: billingDetails,
        },
      })
      .then((response) => {
        const { error } = response;
        if (error) {
          setError(`Payment failed ${error.message}`);
          setProcessing(false);
        } else {
          setError(null);
          setProcessing(false);
          setSucceeded(true);
          dispatchEvent(resetStripeToken());
          window.dispatchEvent(
            new CustomEvent("xsollaPaymentCheck", {
              detail: { xsolla: false, token: stripeState.data },
            })
          );
          hideLevels();
        }
      });
  }

  useEffect(() => {
    const payload = {
      access_token: accessCode,
      level_id: boughtLevel.id,
      language: i18n.language,
      is_mobile: isMobile,
    };
    getStripeToken(payload, dispatchEvent);
    // eslint-disable-next-line
  }, []);

  function closeModal(event) {
    event.preventDefault();
    dispatchEvent(resetStripeToken());
    hideLevels();
  }

  return stripeState.loading ? (
    <Loading />
  ) : (
    stripeState.success && (
      <>
        <div className="c-upgrade__header">
          {/* <a href="/" className="c-upgrade__back"><IconBack width={16} /></a> */}
          <h2 className="c-upgrade__title">{t("upgrade.title")}</h2>
          <span>{t("upgrade.description")}</span>
          <a href="/" onClick={closeModal} className="c-upgrade__cancel">
            <IconClose width={16} />
          </a>
        </div>
        <figure className="c-upgrade__icon is-badge">
          <IconCrown width={48} />
        </figure>
        {error && (
          <div className="c-stripe__error card-error" role="alert">
            {error}
          </div>
        )}
        <div className="c-stripe">
          <form className="c-stripe__wrapper" onSubmit={handleSubmit(onSubmit)}>
            <h4 className="c-stripe__title">{t("pay.title")}</h4>

            <div className="o-field">
              <CardElement
                id="card-element"
                options={cardStyle}
                onChange={handleChange}
              />
            </div>
            <div className="o-field">
              <label htmlFor="name">{t("pay.card_name")}</label>
              <input
                name="name"
                ref={register({ required: true })}
                type="text"
              ></input>
            </div>
            <div className="o-field">
              <label>{t("pay.address")}</label>
              <input
                name="address"
                ref={register({ required: true })}
                type="text"
              ></input>
            </div>
            <div className="o-field">
              <label>{t("pay.country")}</label>
              <select
                name="country"
                id="country"
                ref={register({ required: true })}
              >
                <option value="">{t("pay.country")}</option>
                {Object.keys(COUNTRIES_LIST).map((code) => (
                  <option key={code} value={code}>
                    {COUNTRIES_LIST[code]}
                  </option>
                ))}
              </select>
            </div>
            <div className="o-field o-field--third">
              <label>{t("pay.zipcode")}</label>
              <input
                name="zip"
                ref={register({ required: true })}
                type="text"
              ></input>
            </div>
            <div className="o-field">
              <label>{t("pay.state")}</label>
              <input
                name="state"
                ref={register({ required: true })}
                type="text"
              ></input>
            </div>
            <div className="o-field">
              <label>{t("pay.city")}</label>
              <input
                name="city"
                ref={register({ required: true })}
                type="text"
              ></input>
            </div>
            <div className="o-field">
              <label>{t("pay.suburb")}</label>
              <input
                name="suburb"
                ref={register({ required: true })}
                type="text"
              ></input>
            </div>

            <button
              className="o-button--lg o-button--green stripe-submit"
              disabled={processing || disabled || succeeded}
              id="submit"
            >
              <span id="button-text">
                {processing ? <IconLoading width={24} /> : t("pay.pay")}
              </span>
            </button>
          </form>
          <div className="c-stripe__order">
            <div className="c-order">
              <h4 className="c-order__title">{t("pay.order")}</h4>
              <div className="c-order__single">
                <figure className="c-order__icon">
                  <img src={boughtLevel.icon} alt={boughtLevel.name} />
                </figure>
                <span className="is-left">{boughtLevel.name}</span>
                <span className="is-right">
                  ${boughtLevel.price} {eventData.currency}
                </span>
              </div>
              <div className="c-order__single">
                <span className="is-left">
                  <strong>{t("pay.total")}</strong>
                </span>
                <span className="is-right c-order__total">
                  ${boughtLevel.price} {eventData.currency}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}
export default CheckoutForm;
