import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'is-send',
  viewBox = '0 0 24 24'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id="Artboard" fill={fill}>
      <path d="M15.7936,20.3479 L12.2166,13.1949 L20.8006,4.6109 L15.7936,20.3479 Z M19.3876,3.1969 L10.8026,11.7809 L3.6486,8.2029 L19.3876,3.1969 Z M23.9926,0.9569 C23.9886,0.8589 23.9686,0.7639 23.9366,0.6709 C23.9266,0.6419 23.9156,0.6139 23.9026,0.5839 C23.8536,0.4779 23.7936,0.3759 23.7076,0.2899 C23.6216,0.2039 23.5206,0.1439 23.4136,0.0959 C23.3836,0.0819 23.3556,0.0719 23.3246,0.0609 C23.2336,0.0299 23.1416,0.0109 23.0446,0.0059 C23.0256,0.0049 23.0066,-0.0011 22.9866,-0.000211975189 C22.8896,0.0009 22.7926,0.0139 22.6966,0.0439 L0.6966,7.0439 C0.3096,7.1679 0.0346,7.5139 0.0026,7.9189 C-0.0284,8.3259 0.1896,8.7099 0.5526,8.8919 L10.2546,13.7429 L15.1056,23.4449 C15.2756,23.7859 15.6236,23.9969 15.9996,23.9969 C16.0256,23.9969 16.0516,23.9959 16.0776,23.9939 C16.4836,23.9629 16.8296,23.6889 16.9536,23.2999 L23.9536,1.2999 C23.9836,1.2039 23.9966,1.1069 23.9977315,1.0089 C23.9986,0.9919 23.9936,0.9749 23.9926,0.9569 L23.9926,0.9569 Z"></path>
    </g>
  </svg>
)

export default SVG
