
const elements = [
  {
    name: 'Managers de Artistas',
    title: 'Mantén al artista vigente en la conversación',
    desc: 'Desde crear “hype” hasta monetizar de ello. Levanta todos los números del plan de tus artistas. Aquí tienes la oportunidad de crear un nuevo pilar de contenido e ingresos en la estrategia completa de tus artistas.',
    link: '/whos-it-for/artist-managers',
    cta: 'Aprende como otros managers de artistas usan Neerme',
    poster: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManager.jpg',
    webm_1: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManager.webm',
    webm_2: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManagerLow.mp4',
    mp4: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManager.mp4'
  },
  {
    name: 'Creadores de contenido',
    title: 'Genera todo un nuevo canal de ingresos para tu contenido',
    desc: 'Lleva a tu audiencia de tener contenido grabado extraordinario, a tener una experiencia nueva interactiva en vivo. Traer tu contenido a Neerme te permitirá generar nuevos recursos económicos para tu proyecto.',
    link: '/whos-it-for/content-creators',
    cta: 'Aprende como otros creadores usan Neerme',
    poster: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ContentCreator.jpg',
    webm_1: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ContentCreator.webm',
    webm_2: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ContentCreatorLow.mp4',
    mp4: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ContentCreator.mp4'
  },
  {
    name: 'Músicos',
    title: 'Crea nuevas experiencias que ayuden a tu música a trascender',
    desc: 'Desde acercarte más a tus fans, hasta crear nuevos conceptos e ideas. El lugar perfecto para exponer tu música en maneras que no habías intentado antes. Eso es Neerme.',
    link: '/whos-it-for/musicians',
    cta: 'Aprende como otros músicos usan Neerme',
    poster: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Musician.jpg',
    webm_1: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Musician.webm',
    webm_2: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/MusicianLow.mp4',
    mp4: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/Musician.mp4'
  },
  {
    name: 'Comediantes',
    title: 'La comedia triunfa en Neerme',
    desc: 'De tener audiencias limitadas, a interactuar con miles. Supera tus expectativas de audiencia en formas que nunca habías imaginado.',
    link: '/whos-it-for/comedians',
    cta: 'Aprende como otros comediantes usan Neerme',
    poster: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Comedian.jpg',
    webm_1: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Comedian.webm',
    webm_2: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/ComedianLow.mp4',
    mp4: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Comedian.mp4'
  },
  {
    name: 'Productor de teatro',
    title: 'Haz que tu obra sea vista por más gente que nunca',
    desc: 'En un mundo donde las producciones de teatro no reciben el reconocimiento que merecen, Neerme les permite ir de espacios pequeños y limitados a interactuar con miles de espectadores.',
    link: '/whos-it-for/theater-producers',
    cta: 'Aprende como otros productores de teatro usan Neerme',
    poster: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Theater.jpg',
    webm_1: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Theater.webm',
    webm_2: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/TheaterLow.mp4',
    mp4: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Theater.mp4'
  }
]

export default elements;
