import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollTrigger from '@terwanerik/scrolltrigger';
import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";

function StoriesDigitalContent(props) {
  const { t } = useTranslation();
  useEffect(()=>{
    const trigger = new ScrollTrigger({
      trigger: {
        // If the trigger should just work one time
        once: true,
        offset: {
          element: {
            x: 0,
            y: (trigger, rect, direction) => {
              return 0.1
            }
          },
          viewport: {
            x: 0,
            y: (trigger, frame, direction) => {
              return trigger.visible ? 0 : 0.1
            }
          }
        }
      }
    })
    trigger.add('[data-slideleft]')
           .add('[data-slideright]')
           .add('[data-slidebottom]')
  },[])

  return (
    <>
      <Header stylePage={"is-gradient"} />
      <div className="c-landing is-stories">
        <section className="c-stories__hero">
          <div className="c-stories-hero__wrapper">
            <h4 className="c-landing__category c-stories__category">
              {t("case_stories.digital.category")}
            </h4>
            <h1 className="c-stories-hero__title">
              {t("case_stories.digital.title")}
            </h1>
          </div>
        </section>
        <section className="c-stories__challenge">
          <div className="c-stories-challenge__wrapper" data-slidebottom>
            <h1 className="c-stories__title">{t("case_stories.challenge")}</h1>
            <p className="c-stories__text">{t("case_stories.digital.challenge")}</p>
            <p className="c-stories__text">{t("case_stories.digital.challenge2")}</p>
          </div>  
        </section>
        <section className="c-landing-slides">
          <article className="c-landing-slides__single">
            <div className="c-landing-slides__video" data-slideright>
              <img src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/CaseStories/Digital.jpg" alt="Digital Content" width="130%" />
            </div>
            <div className="c-landing-slides__content" data-slideleft>
              <h1 className="c-stories__title">{t("case_stories.solution")}</h1>
              <p className="c-home__text">{t("case_stories.digital.solution")}</p>
              <p className="c-home__text">{t("case_stories.digital.solution2")}</p>
            </div>
          </article>
        </section>
        <section className="c-stories-results is-digital">
          <div className=" c-stories-results__title" data-slidebottom>
            <h1 className="c-stories__title">{t("case_stories.results")}</h1>
          </div>
          <div className="c-stories-results__wrapper">
            <div className="c-stories-results__single is-first" data-slideright>
              <h1>+21,000 {t("case_stories.digital.tickets")}</h1>
              <p>{t("case_stories.digital.results1")}</p>
              <br />
              <h1>+8x {t("case_stories.digital.revenue")}</h1>
              <p>{t("case_stories.digital.results2")}</p>
            </div>
            <div className="c-stories-results__single is-last" data-slideright>
              <h1>+35,000 {t("case_stories.digital.tickets")}</h1>
              <p>{t("case_stories.digital.results3")}</p>
              <br />
              <h1>+12x {t("case_stories.digital.revenue")}</h1>
              <p>{t("case_stories.digital.results4")}</p>
            </div>
          </div>
        </section>
        <section className="c-landing-magnet c-stories-magnet">
          <div className="c-landing-magnet__wrapper" data-slidebottom>
            <h2 className="c-landing-magnet__title">{t("home.magnet.title")}</h2>
            <p className="c-home__text">{t("home.magnet.desc")}</p>
            <Link to="/request-demo" className="o-button--green o-button--lg">
              {t("home.main_cta")}
            </Link>
          </div>  
        </section>
      </div>
      <Footer />
    </>
  );
}

export default StoriesDigitalContent;
