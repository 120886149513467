import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import Hls from "hls.js";
import Plyr from "plyr";

import useQuery from "hooks/useQuery";
import isMobile from "utils/isMobile";

import CustomOverlay from "./CustomOverlay";
import CustomControls from "./CustomControls";
import CustomControlsEnd from "./CustomControlsEnd";
import CustomSettings from "./CustomSettings";

import { defaultControls } from "./defaultControls";

import "plyr/dist/plyr.css";

function PlyrPlayer(props) {
  const videoNode = useRef();
  const query = useQuery();
  const debug = query.get("debug");
  const isDebug = debug === "true";
  const { src } = props;

  const [mediaObjects, setMediaObjects] = useState({});

  const plyrContainer = document.querySelector(".plyr");
  const neermeControls = document.querySelector(".neerme__controls");
  const neermeControlsEnd = document.querySelector(".neerme__controls_end");
  const neermeControlsSettings = document.querySelector(
    ".neerme__controls_custom_settings"
  );

  const plyrOptions = {
    debug: isDebug,
    controls: defaultControls,
    settings: [],
    muted: isMobile,
    clickToPlay: false,
    displayDuration: false,
    storage: { enabled: true, key: "neerme" },
  };
  const hlsConfig = {
    debug: isDebug,
    maxBufferSize: 0,
    maxBufferLength: 30,
    liveSyncDuration: 30,
    liveMaxLatencyDuration: Infinity,
    startFragPrefetch: true,
    manifestLoadingMaxRetry: 1,
    startLevel: -1,
  };
  //   const [player, setPlayer] = useState(null);

  useEffect(() => {
    const videoElement = videoNode.current;
    // eslint-disable-next-line
    const plyr = new Plyr(videoElement, plyrOptions);

    if (!Hls.isSupported()) {
      videoElement.src = src;
    } else {
      // For more Hls.js options, see https://github.com/dailymotion/hls.js
      const hls = new Hls(hlsConfig);
      hls.attachMedia(videoElement);
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource(src);
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          videoElement.play();
          // const initiLeve = -1;
          // hls.loadLevel = initiLeve;
          // hls.currentLevel = initiLeve;
          // hls.nextLevel = initiLeve;
        });
      });

      setMediaObjects({ hls, videoElement, plyr });
      if (isDebug) {
        window.hls = hls;
        window.plyr = plyr;
        window.videoElement = videoElement;
      }
      //video.currentTime = video.duration - 1 go live
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="c-plyr">
      <video ref={videoNode} controls playsInline></video>
      {plyrContainer && ReactDOM.createPortal(<CustomOverlay />, plyrContainer)}
      {neermeControls &&
        ReactDOM.createPortal(
          <CustomControls mediaObjects={mediaObjects} />,
          neermeControls
        )}
      {neermeControlsEnd &&
        ReactDOM.createPortal(
          <CustomControlsEnd mediaObjects={mediaObjects} />,
          neermeControlsEnd
        )}
      {neermeControlsSettings &&
        ReactDOM.createPortal(
          <CustomSettings mediaObjects={mediaObjects} />,
          neermeControlsSettings
        )}
    </div>
  );
}

export default PlyrPlayer;
