import AWS from "aws-sdk/global";

import DynamoDB from "aws-sdk/clients/dynamodb";

export function useDynamodb(config) {
  AWS.config.region = config.region; // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.identityPoolId,
  });

  const ddb = new DynamoDB({ apiVersion: config.dynamoVersion });

  function DputItem(params) {
    return new Promise((resolve, reject) => {
      ddb.putItem(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  function DgetItem(params) {
    return new Promise((resolve, reject) => {
      ddb.getItem(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.Item);
        }
      });
    });
  }
  function Dscan(params) {
    return new Promise((resolve, reject) => {
      ddb.scan(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.Items);
        }
      });
    });
  }

  return [DputItem, DgetItem, Dscan];
}
