import styled from "styled-components";

const HeaderStyled = styled.header`
  background: ${(props) => props.theme.header.colors.body};
  color: ${(props) => props.theme.header.colors.text}!important;
  .c-header__links.is-first a {
    color: ${(props) => props.theme.header.colors.text}!important;
  }
  .c-header__single.is-language button {
    color: ${(props) => props.theme.header.colors.text}!important;
  }
`;
export default HeaderStyled;
