import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";

import { useChannelWebsocketContext } from "contexts/channelWebsocket";

import IconError from "components/shared/icon/error";
import Fade from "components/shared/Fade";

import reducer, { initialPoll } from "./store/reducer";
import { votePoll } from "./store/actions";

const ACTION_CHANNEL_POLL_STREAM = "channelPollStream";

function LivePoll(props) {
  const { t } = useTranslation(["watch"]);

  const { classPoll } = props;

  const [pollState, dispatchEvent] = useReducer(reducer, initialPoll);
  const {
    setActivePoll,
    activePoll,
    lastJsonMessage: lastMessage,
  } = useChannelWebsocketContext();

  useEffect(() => {
    if (pollState.votePoll.error) {
      console.warn("error voting");
    }
  }, [pollState.votePoll]);

  useEffect(() => {
    if (lastMessage !== null) {
      const { action, data } = lastMessage;
      switch (action) {
        case ACTION_CHANNEL_POLL_STREAM:
          setActivePoll(data.active ? data : {});
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [lastMessage]);

  function handleClosePoll(event) {
    event.preventDefault();
    setActivePoll({});
  }

  function handleVote(event, answerId) {
    event.preventDefault();
    votePoll(
      {
        pollID: activePoll.id,
        payload: {
          answer_id: answerId,
        },
      },
      dispatchEvent
    );
    setActivePoll({});
  }

  function AnswerList(props) {
    const { answers } = props;

    return answers
      ? answers.map((ans, idx) => (
          <li key={idx}>
            <a
              href="/"
              className="c-livepoll__button o-button--sm o-button--outline is-light"
              onClick={(e) => handleVote(e, ans.id)}
            >
              {ans.option_label}
            </a>
          </li>
        ))
      : null;
  }
  return (
    <>
      <Fade show={activePoll.active}>
        <div className={`c-livepoll ${classPoll}`}>
          <h6 className="c-poll__header">{t("poll.live")}</h6>
          <h3 className="c-poll__title">{activePoll.name}</h3>
          <a href="/" onClick={handleClosePoll} className="c-livepoll__exit">
            <IconError width={16} />
          </a>
          <ol className="c-livepoll__list">
            <AnswerList answers={activePoll.answers} />
          </ol>
        </div>
      </Fade>
    </>
  );
}

export default LivePoll;
