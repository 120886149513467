import React from "react";
import { useTranslation } from 'react-i18next';

import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";
import ArrowIcon from "components/shared/icon/arrow";
import UpdateIcon from "components/shared/icon/update";
import SoundOffIcon from "components/shared/icon/sound_off";
import PlayIcon from "components/shared/icon/play";
import { HashLink as Link } from 'react-router-hash-link';


function Troubleshooting(props) {
  const { t } = useTranslation(['help']);

  return (
    <>
      <Header stylePage={"is-gradient"} />
      <div className="c-layout is-help">
        <section className="c-help">
          <div className="c-help__hero">
            <span role="img" aria-label="Surfer">🏄‍♀️</span>
            <h1 className="c-help__title">{t ("help.title")}</h1>
            <p>{t ("help.description")} <a href="mailto:hello@neerme.tv">hola@boletia.com</a></p>
          </div>
          <div className="c-help__summary">
            <div className="c-help__block">
              <h2><span role="img" aria-label="Surfer">🤩</span>{t ("help.block-1")}</h2>
              <ol>
                <li><Link to="/help/#answer-1">{t ("faq-1.title")}</Link>
                </li>
                 <li>
                   <Link to="/help/#answer-2">{t ("faq-2.title")}</Link>
                 </li>
                 <li>
                   <Link to="/help/#answer-3">{t ("faq-3.title")}</Link>
                 </li>
                 <li>
                   <Link to="/help/#answer-4">{t ("faq-4.title")}</Link>
                 </li>
               </ol> 
            </div>
            <div className="c-help__block">
              <h2><span role="img" aria-label="Ticket">🎟</span>{t ("help.block-2")}</h2>
              <ol>
                 <li>
                  <Link to="/help/#answer-5">{t ("faq-5.title")}</Link>
                 </li>
                 <li>
                   <Link to="/help/#answer-6">{t ("faq-6.title")}</Link>
                 </li>
                 <li>
                   <Link to="/help/#answer-7">{t ("faq-7.title")}</Link>
                 </li>
                 <li>
                   <Link to="/help/#answer-8">{t ("faq-8.title")}</Link>
                 </li>
                 <li>
                   <Link to="/help/#answer-9">{t ("faq-9.title")}</Link>
                 </li>
               </ol> 
            </div>
            <div className="c-help__block">
              <h2><span role="img" aria-label="Satellite">🛰</span>{t ("help.block-3")}</h2>
              <ol>
                 <li>
                  <Link to="/streaming-help">{t ("faq-10")}</Link>
                 </li>
               </ol> 
            </div>
          </div>
        </section>
        <section className="c-help">

          {/* FIRST BLOCK */}

          <article className="c-help__answers" id="answer-1">
            <div className="c-help__wrapper">
              <h1>{t ("faq-1.title")}</h1>
              <p>{t ("faq-1.answer-1")}</p>
              <ul>
                <li>{t ("faq-1.answer-2")}</li>
                <li>{t ("faq-1.answer-3")}</li>
                <li>{t ("faq-1.answer-4")} <a href="https://speedtest.com" target="_blank" rel="noopener noreferrer">speedtest.com</a></li>
                <li>{t ("faq-1.answer-5")}</li>
              </ul>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-2">
            <div className="c-help__wrapper">
              <h1>{t ("faq-2.title")}</h1>
              <p>{t ("faq-2.answer-1")} <span className="c-help__icon"><UpdateIcon width={18} /></span> {t ("faq-2.answer-2")}</p>

              <p><strong>Windows:</strong></p>
              <code>{t ("faq-2.answer-3")}</code>

              <p><strong>Mac:</strong></p>
              <code>⌘ Cmd + R</code>

              <p>{t ("faq-2.answer-4")}</p>

              <ul>
                <li>{t ("faq-2.answer-5")}<span className="c-help__icon"><UpdateIcon width={18} /></span> {t ("faq-2.answer-6")}</li>
                <li>{t ("faq-2.answer-7")} <span className="c-help__icon"><PlayIcon width={18} /></span> {t ("faq-2.answer-8")}</li>
                <li>{t ("faq-2.answer-9")} <span className="c-help__icon"><SoundOffIcon width={18} /></span> {t ("faq-2.answer-10")}</li>
              </ul>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-3">
            <div className="c-help__wrapper">
              <h1>{t ("faq-3.title")}</h1>
              <p>{t ("faq-3.answer-1")} <span className="c-help__icon"><SoundOffIcon width={18} /></span> {t ("faq-3.answer-2")}</p>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-4">
            <div className="c-help__wrapper">
              <h1>{t ("faq-4.title")}</h1>

              <h3>{t ("faq-4.answer-1")}</h3>
              <p>{t ("faq-4.answer-2")}</p>

              <p>{t ("faq-4.answer-3")} <a href="https://speedtest.com" target="_blank" rel="noopener noreferrer">speedtest.com</a></p>

              <h3>{t ("faq-4.answer-4")}</h3>
              <p>{t ("faq-4.answer-5")} <strong>HD</strong> {t ("faq-4.answer-6")}</p>

              <h3>{t ("faq-4.answer-7")}</h3>
              <p>{t ("faq-4.answer-8")}</p>
              <p>{t ("faq-4.answer-9")} <a href="https://es.wikipedia.org/wiki/help:C%C3%B3mo_limpiar_la_cach%C3%A9" target="_blank" rel="noopener noreferrer">{t ("faq-4.answer-10")}</a>.</p>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          {/* SECOND BLOCK */}

          <article className="c-help__answers" id="answer-5">
            <div className="c-help__wrapper">
              <h1>{t ("faq-5.title")}</h1>
              <ul>
                <li>{t ("faq-5.answer-1")} <Link to="/event-list">neerme.tv</Link> {t ("faq-5.answer-2")}</li>
                <li>{t ("faq-5.answer-3")} <strong>"{t ("faq-5.answer-4")}"</strong></li>
                <li>{t ("faq-5.answer-5")} <a href="https://boletia.com" target="_blank" rel="noopener noreferrer">Boletia</a> {t ("faq-5.answer-6")}</li>
                <li>{t ("faq-5.answer-7")}</li>
                <li>{t ("faq-5.answer-8")}</li>
                <li>{t ("faq-5.answer-9")} <span role="img" aria-label="hands up">🙌 🙌 🙌</span></li>
              </ul>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-6">
            <div className="c-help__wrapper">
              <h1>{t ("faq-6.title")}</h1>
              <p>{t ("faq-6.answer-1")}</p>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-7">
            <div className="c-help__wrapper">
              <h1>{t ("faq-7.title")}</h1>
              <p>{t ("faq-7.answer-1")}</p>

              <p>{t ("faq-7.answer-2")}</p>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-8">
            <div className="c-help__wrapper">
              <h1>{t ("faq-8.title")}</h1>
              <p>{t ("faq-8.answer-1")}</p>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-9">
            <div className="c-help__wrapper">
              <h1>{t ("faq-9.title")}</h1>
              <p>{t ("faq-9.answer-1")}</p>

              <p>{t ("faq-9.answer-2")} <a href="mailto:hello@neerme.tv">hello@neerme.tv</a> {t ("faq-9.answer-3")}</p>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Troubleshooting;
