import {
  SET_NICKNAME_BY_TOKEN_REQUESTED,
  SET_NICKNAME_BY_TOKEN_FULFILLED,
  SET_NICKNAME_BY_TOKEN_REJECTED,
} from "./types";

export const initialNicknameByToken = {
  nicknameByToken: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
};

function reducer(state, action) {
  switch (action.type) {
    case SET_NICKNAME_BY_TOKEN_REQUESTED: {
      const { nicknameByToken } = action;
      return { ...state, nicknameByToken };
    }
    case SET_NICKNAME_BY_TOKEN_FULFILLED: {
      const { nicknameByToken } = action;
      return { ...state, nicknameByToken };
    }
    case SET_NICKNAME_BY_TOKEN_REJECTED: {
      const { nicknameByToken } = action;
      return { ...state, nicknameByToken };
    }

    default:
      return state;
  }
}

export default reducer;
