import React, { useEffect, useState } from "react";
import "./style.css";

function Fade(props) {
  const { show, children } = props;
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  function onAnimationEnd() {
    if (!show) setRender(false);
  }

  return render ? (
    <div className="c-poll__fade"
      style={{
        animation: `${show ? "fadeInComponent" : "fadeOutComponent"} 0.5s`,
      }}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </div>
  ) : null;
}

export default Fade;
