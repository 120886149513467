import React from "react";

import { isObject } from "utils/dataStructures";

function StickerList(props) {
  const { properties } = props;

  const stickers = properties?.stickers;
  return (
    <ul className="c-upgrade__list c-stickers__container is-sticker">
      {isObject(stickers) &&
        Object.keys(stickers).map((sticker, idx) => (
          <li key={idx}>
            <img
              className="c-stickers__image"
              width="32"
              src={stickers[sticker]}
              alt={sticker}
            />
          </li>
        ))}
    </ul>
  );
}

export default StickerList;
