import React from "react";
import { useTranslation } from 'react-i18next';

import IconLoading from "../icon/loading";

function Loading(props) {
  const { t } = useTranslation();
  const { isSmall } = props;
  return (
    <>
      {isSmall ? (
        <div className="c-loading__container is-small">
          <div className="c-loading">
            <IconLoading width={40} />
            <h5 className="c-loading__title">{t ("loading")}</h5>
          </div>
        </div>
      ) : (
        <div className="c-loading__container">
          <div className="c-loading">
            <IconLoading width={40} />
            <h4 className="c-loading__title">{t ("loading")}</h4>
          </div>
        </div>
      )}
    </>
  );
}

export default Loading;
