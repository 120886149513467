import React from "react";
import { useTranslation } from 'react-i18next';

import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";
import ArrowIcon from "components/shared/icon/arrow";
import { HashLink as Link } from 'react-router-hash-link';

function Troubleshooting(props) {
  const { t } = useTranslation(['streaming_help']);
  
  return (
    <>
      <Header stylePage={"is-gradient"} />
      <div className="c-layout is-help">
        <section className="c-help">
          <div className="c-help__hero">
            <span role="img" aria-label="Satellite">🛰</span>
            <h1 className="c-help__title">{t ("title")}</h1>
            <p>{t ("description")}</p>
          </div>
          <div className="c-help__summary is-streaming-help">
            <div className="c-help__block">
              <ol>
                <li><a href="#answer-1">{t ("faq-1.title")}</a>
                </li>
                 <li>
                   <Link to="/streaming-help/#answer-2">{t ("faq-2.title")}</Link>
                 </li>
                 <li>
                   <Link to="/streaming-help/#answer-3">{t ("faq-3.title")}</Link>
                 </li>
                 <li>
                   <Link to="/streaming-help/#answer-4">{t ("faq-4.title")}</Link>
                 </li>
               </ol> 
            </div>
            <div className="c-help__block">
              <ol>
                 <li>
                   <Link to="/streaming-help/#answer-5">{t ("faq-5.title")}</Link>
                 </li>
                 <li>
                   <Link to="/streaming-help/#answer-6">{t ("faq-6.title")}</Link>
                 </li>
                 <li>
                   <Link to="/streaming-help/#answer-7">{t ("faq-7.title")}</Link>
                 </li>
                 <li>
                   <Link to="/streaming-help/#answer-8">{t ("faq-8.title")}</Link>
                 </li>
               </ol> 
            </div>
          </div>
        </section>
        <section className="c-help">

          {/* FIRST BLOCK */}

          <article className="c-help__answers" id="answer-1">
            <div className="c-help__wrapper">
              <h1>{t ("faq-1.title")}</h1>
              <p>{t ("faq-1.answer-1")}</p>
              <ul>
                <li><strong>{t ("faq-1.answer-2")}</strong> {t ("faq-1.answer-3")}</li>
                <li><strong>{t ("faq-1.answer-4")}</strong> {t ("faq-1.answer-5")}</li>
                <li><strong>{t ("faq-1.answer-6")}</strong> {t ("faq-1.answer-7")}</li>
              </ul>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-2">
            <div className="c-help__wrapper">
              <h1>{t ("faq-2.title")}</h1>
              <p>{t ("faq-2.answer-1")}</p>
              
              <h3>{t ("faq-2.answer-2")}</h3>
              <ul>
                <li>{t ("faq-2.answer-3")}</li>
                <li>{t ("faq-2.answer-4")}</li>
              </ul>

              <h3>{t ("faq-2.answer-5")}</h3>
              <ul>
                <li>{t ("faq-2.answer-6")}</li>
                <li>{t ("faq-2.answer-7")}</li>
              </ul>

              <h3>{t ("faq-2.answer-8")}</h3>
              <ul>
                <li>{t ("faq-2.answer-9")}</li>
              </ul>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-3">
            <div className="c-help__wrapper">
              <h1>{t ("faq-3.title")}</h1>
              <p>{t ("faq-3.answer-1")}</p> 
              <p>{t ("faq-3.answer-2")}</p>
              <p><strong>{t ("faq-3.answer-3")} <a href="https://www.speedtest.net/">speedtest.net</a></strong></p>

              <p>{t ("faq-3.answer-4")}</p>
              <img src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/speetdtest.png" width="100%" alt=""/>
              <p>{t ("faq-3.answer-5")}</p>
              <p>{t ("faq-3.answer-6")}</p>
              <ul>
                <li>{t ("faq-3.answer-7")}</li>
                <li>{t ("faq-3.answer-8")}</li>
              </ul>

              <div className="o-alert is-error">
                <p><strong>{t ("faq-3.answer-9")}</strong> {t ("faq-3.answer-10")}</p>
              </div>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-4">
            <div className="c-help__wrapper">
              <h1>{t ("faq-4.title")}</h1>

              <p>{t ("faq-4.answer-1")} <strong>{t ("faq-4.answer-2")}</strong> {t ("faq-4.answer-3")}</p>
              <p>{t ("faq-4.answer-4")}</p>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          {/* SECOND BLOCK */}

          <article className="c-help__answers" id="answer-5">
            <div className="c-help__wrapper">
              <h1>{t ("faq-5.title")}</h1>
              <p>{t ("faq-5.answer-1")}</p>
              <ul>
                <li>{t ("faq-5.answer-2")}</li>
                <li>{t ("faq-5.answer-3")}</li>
              </ul>
              
              <p>{t ("faq-5.answer-4")}</p>
              <ul>
                <li><strong>{t ("faq-5.answer-5")}</strong></li>
                <li>{t ("faq-5.answer-6")}</li>
                <li>{t ("faq-5.answer-7")}</li>
              </ul>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-6">
            <div className="c-help__wrapper">
              <h1>{t ("faq-6.title")}</h1>
              <p>{t ("faq-6.answer-1")}</p>

              <ul>
                <li><strong>{t ("faq-6.answer-2")}</strong> {t ("faq-6.answer-3")}</li>
                <li><strong>{t ("faq-6.answer-4")}</strong> {t ("faq-6.answer-5")}</li>
                <li><strong>{t ("faq-6.answer-6")}</strong> {t ("faq-6.answer-7")}</li>
                <li><strong>{t ("faq-6.answer-8")}</strong> {t ("faq-6.answer-9")}</li>
                <li><strong>{t ("faq-6.answer-10")}</strong> {t ("faq-6.answer-11")}</li>
                <li><strong>{t ("faq-6.answer-12")}</strong> {t ("faq-6.answer-13")}</li>
                <li><strong>{t ("faq-6.answer-14")}</strong> {t ("faq-6.answer-15")}</li>
                <li><strong>{t ("faq-6.answer-16")}</strong> {t ("faq-6.answer-17")}</li>
                <li><strong>{t ("faq-6.answer-18")}</strong> {t ("faq-6.answer-19")}</li>
                <li><strong>{t ("faq-6.answer-20")}</strong> {t ("faq-6.answer-21")}</li>
                <li><strong>{t ("faq-6.answer-22")}</strong> {t ("faq-6.answer-23")}</li>
              </ul>
              <img src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/obs-setup.png" width="100%" alt="OBS Setup"/>
              <img src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/obs-setup-1.png" width="100%" alt="OBS Setup"/>
              <img src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/obs-setup-2.png" width="100%" alt="OBS Setup"/><img src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/obs-setup-3.png" width="100%" alt="OBS Setup"/>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-7">
            <div className="c-help__wrapper">
              <h1>{t ("faq-7.title")}</h1>
              <p>{t ("faq-7.answer-1")}</p>
              <p>{t ("faq-7.answer-2")}</p>

              <ul>
                <li>
                  {t ("faq-7.answer-3")} <strong>{t ("faq-7.answer-4")}</strong> {t ("faq-7.answer-5")}
                  <img src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/obs-veryfast.png" width="100%" alt=""/>
                </li>
                <li>{t ("faq-7.answer-6")}</li>
              </ul>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>

          <article className="c-help__answers" id="answer-8">
            <div className="c-help__wrapper">
              <h1>{t ("faq-8.title")}</h1>
              <p>{t ("faq-8.answer-1")}</p>

              <h3>{t ("faq-8.answer-2")}</h3>

              <ul>
                <li>{t ("faq-8.answer-3")}</li>
                <li>{t ("faq-8.answer-4")}</li>
                <li>{t ("faq-8.answer-5")}</li>
                <li>{t ("faq-8.answer-6")}</li>
              </ul>

              <h3>{t ("faq-8.answer-7")}</h3>

              <ul>
                <li>{t ("faq-8.answer-8")}</li>
                <li>{t ("faq-8.answer-9")}</li>
                <li>{t ("faq-8.answer-10")}</li>
                <li>{t ("faq-8.answer-11")}</li>
                <li>{t ("faq-8.answer-12")}</li>
              </ul>
            </div>
            <Link to="#" className="c-help__back"><ArrowIcon width={16} />{t ("help.back")}</Link>
          </article>
        </section>
      </div>
      <section className="c-about-finish__wrapper">
        <div className="c-about-finish">
          <h2 className="c-about-finish__title">
           {t('end_title')}
          </h2>
          <p>{t('end_text')}</p>

          <a
            href="mailto:hello@neerme.tv"
            target="_blank"
            rel="noopener noreferrer"
            className="o-button--lg o-button--green"
          >
            {t('end_cta')}
          </a>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Troubleshooting;
