import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/browser";

import App from "components/app";
import * as serviceWorker from "./serviceWorker";
import './i18n';

Sentry.init({
  dsn:
    "https://7fdfd49b9f824e9284ccff8d1d0f2f35@o266619.ingest.sentry.io/5270621",
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
