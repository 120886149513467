import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import LogoutIcon from "components/shared/icon/logout";
import UserIcon from "components/shared/icon/user";

import { useAuth } from "../../../contexts/authContext";
import { logOut } from "../../../api/auth";

function Menu(props) {
  const { t,i18n } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };
  const { handleToken, authToken, userData, handleChatUsername } = useAuth();
  const history = useHistory();

  function handleLogout(event) {
    event.preventDefault();
    const logOutPayload = {
      email: userData.email,
    };
    logOut(authToken, logOutPayload).then((response) => {
      handleToken(false);
      handleChatUsername(false);
      history.replace("/");
    });
  }
  return (
    <>
      <input type="checkbox" id="mobile-menu" className="c-menu__checkbox" />
      <div className="c-menu">
        <ul className="c-menu__links">
          <li>
            <Link to="/why-neerme" alt={t("menu.why")}>
              <span>{t("menu.why")}</span>
            </Link>
            <ul>
              <li>
                <Link to="/why-neerme" alt={t("menu.why")}>
                  <span>{t("menu.why")}</span>
                </Link>
              </li>
              <li>
                <Link to="/event-types">
                  <span>{t("menu.event_types")}</span>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/whos-it-for/artist-managers" alt={t("menu.whositfor")}>
              <span>{t("menu.whositfor")}</span>
            </Link>
            <ul>
              <li>
                <Link to="/whos-it-for/artist-managers" alt={t("menu.artist_managers")}>
                  <span>{t("menu.artist_managers")}</span>
                </Link>
              </li>
              <li>
                <Link to="/whos-it-for/content-creators" alt={t("menu.content_creators")}>
                  <span>{t("menu.content_creators")}</span>
                </Link>
              </li>
              <li>
                <Link to="/whos-it-for/musicians" alt={t("menu.musicians")}>
                  <span>{t("menu.musicians")}</span>
                </Link>
              </li>
              <li>
                <Link to="/whos-it-for/comedians" alt={t("menu.comedians")}>
                  <span>{t("menu.comedians")}</span>
                </Link>
              </li>
              <li>
                <Link to="/whos-it-for/theater-producers" alt={t("menu.theater_producers")}>
                  <span>{t("menu.theater_producers")}</span>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/case-studies/comedy" alt={t("menu.case_stories")}>
              <span>{t("menu.case_stories")}</span>
            </Link>
            <ul>
              <li>
                <Link to="/case-studies/comedy" alt={t("menu.comedy")}>
                  <span>{t("menu.comedy")}</span>
                </Link>
              </li>
              <li>
                <Link to="/case-studies/digital-content" alt={t("menu.digital")}>
                  <span>{t("menu.digital_content")}</span>
                </Link>
              </li>
              <li>
                <Link to="/case-studies/theater" alt={t("menu.theater")}>
                  <span>{t("menu.theater")}</span>
                </Link>
              </li>
              <li>
                <Link to="/case-studies/music" alt={t("menu.music")}>
                  <span>{t("menu.music")}</span>
                </Link>
              </li>
              <li>
                <Link to="/case-studies/corporate" alt={t("menu.corporate")}>
                  <span>{t("menu.corporate")}</span>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/about" alt={t("menu.about")}>
              <span>{t("menu.about")}</span>
            </Link>
          </li>
          <li>
            <Link to="/event-list" alt={t("menu.events")}>
              <span>{t("menu.events")}</span>
            </Link>
          </li>
          <li>
            <Link to="/help">
              <span>{t("menu.help")}</span>
            </Link>
          </li>
          {authToken ? (
            <>
              <li className="c-menu__links is-desktop">
                <Link className="c-header__icon" to="/events">
                  <span>{t("menu.lives")}</span>
                </Link>
              </li>
              <li className="c-menu__links is-desktop">
                <Link to="/" className="c-header__icon" onClick={handleLogout}>
                  <LogoutIcon width={14} />
                  {t("menu.logout")}
                </Link>
              </li>
            </>
          ) : (
            <li className="c-menu__link is-desktop">
              <Link className="c-header__icon" to="/login">
                <UserIcon width={14} />
                <span>{t("menu.login")}</span>
              </Link>
            </li>
          )}
          <li>
            <Link to="/codigo-de-conducta">
              <span>{t("menu.code_conduct")}</span>
            </Link>
          </li>
          <li>
            <Link to="/terminos-y-condiciones">
              {t("menu.terms_conditions")}
            </Link>
          </li>
          <li>
            <Link to="/terminos-de-compra">
              {t("menu.terms_purchase")}
            </Link>
          </li>
          <li className="is-language">
            <button onClick={() => changeLanguage('en')}>
              EN 
            </button>
            <span>|</span>
            <button onClick={() => changeLanguage('es')}>
              ES 
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Menu;
