import React from "react";

import { Link } from "react-router-dom";

import MonthsAbEs from "consts/monthsAbbES";

function EventCard(props) {
  const { noLink } = props;

  const {
    event_banner,
    event_name,
    start_date,
    start_time,
    event_subdomain,
    is_time_to_access,
  } = props.eventData || {};

  function getDayFromData(date) {
    return date.split("-")[2];
  }
  function getMonthFromData(date) {
    return MonthsAbEs[parseInt(date.split("-")[1]) - 1];
  }

  const cleanStartTime = start_time.slice(0, 5);

  const cleanDate = `${getDayFromData(start_date)} ${getMonthFromData(
    start_date
  )}`;

  return (
    <li>
      <Link to={noLink ? `#` : `/${event_subdomain}`} className="c-event-card">
        <figure
          className="c-event-card__image"
          style={{ backgroundImage: `url(${event_banner})` }}
        ></figure>
        <div className="c-event-card__time">
          <span>{cleanDate}</span>
          <span>{cleanStartTime} hrs</span>
        </div>
        <div className="c-event-card__content">
          {is_time_to_access && (
            <div className="o-badge is-live">
              <figure className="o-badge__live"></figure>
              <span className="o-badge__text">Live</span>
            </div>
          )}

          <h4 className="c-event-card__title">{event_name}</h4>
        </div>
      </Link>
    </li>
  );
}

export default EventCard;
