import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  height = '100%',
  className = 'is-home',
  viewBox = '0 0 24 24'
}) => (
  <svg
    width={width}
    style={style}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path fill={fill} d='M19,21.9998 L16,21.9998 L16,16.9998 C16,15.8958 15.103,14.9998 14,14.9998 L10,14.9998 C8.897,14.9998 8,15.8958 8,16.9998 L8,21.9998 L5,21.9998 L5,7.7258 L12,1.9978 L19,7.7258 L19,21.9998 Z M23.633,8.9328 L13.266,0.4498 C12.533,-0.1502 11.468,-0.1492 10.733,0.4498 L0.367,8.9328 C-0.061,9.2828 -0.124,9.9128 0.226,10.3398 C0.576,10.7678 1.205,10.8298 1.633,10.4808 L3,9.3628 L3,21.9998 C3,23.1028 3.897,23.9998 5,23.9998 L8,23.9998 C9.103,23.9998 10,23.1028 10,21.9998 L10,16.9998 L14,16.9998 L14,21.9998 C14,23.1028 14.897,23.9998 16,23.9998 L19,23.9998 C20.103,23.9998 21,23.1028 21,21.9998 L21,9.3628 L22.367,10.4808 C22.553,10.6328 22.777,10.7068 23,10.7068 C23.289,10.7068 23.576,10.5818 23.774,10.3398 C24.124,9.9128 24.061,9.2828 23.633,8.9328 L23.633,8.9328 Z' id='Fill-1' />
  </svg>
)

export default SVG
