import React, { useState, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";

import InputField from "components/shared/InputField";

import { useAdminPollContext } from "contexts/adminPoll";

import { createPoll } from "api/polls";
import { useAuth } from "contexts/authContext";

import { pollContainerStates } from "consts/pollContainerStates";

import { switchPoll } from "../store/actions";
import reducer, { initialPoll } from "../store/reducer";

function FormPoll(props) {
  const { t } = useTranslation(["watch"]);

  const {
    setAdminPollNavigation,
    eventSubdomain,
    pollsNumber,
    setLivePoll,
    setPollData,
  } = useAdminPollContext();

  const { authToken } = useAuth();
  const [pollState, dispatchEvent] = useReducer(reducer, initialPoll);
  const [ans1, setAns1] = useState("");
  const [ans2, setAns2] = useState("");
  const [ans3, setAns3] = useState("");
  const [ans4, setAns4] = useState("");
  const [ans5, setAns5] = useState("");
  const [pollName, setPollName] = useState("");
  const [loadingCreate, setLoadingCreate] = useState(false);

  const [hasCreationError, setHasCreationError] = useState(false);

  function handleCancel(event) {
    event.preventDefault();
    if (pollsNumber === 0) {
      setAdminPollNavigation(pollContainerStates.EMPTY);
    } else {
      setAdminPollNavigation(pollContainerStates.LIST);
    }
  }

  function generateCreatePayload(eventSubdomain, answers) {
    const validAns = [];

    answers.forEach((element) => {
      validAns.push({ option_label: element });
    });

    return {
      name: pollName,
      answers: validAns,
      event_subdomain: eventSubdomain,
    };
  }

  function handleSubmit(event) {
    event.preventDefault();

    const validAns = [ans3, ans4, ans5].filter((ans) => ans.length > 0);
    const answers = [ans1, ans2, ...validAns];
    const payload = generateCreatePayload(eventSubdomain, answers);
    setLoadingCreate(true);
    createPoll(authToken, payload).then((response) => {
      setLoadingCreate(false);
      const { data } = response;
      if (data.success) {
        const { new_poll } = data;
        setPollData();
        const payload = {
          active: true,
        };
        switchPoll({ pollID: new_poll.id, payload }, dispatchEvent);
        setLivePoll(new_poll);
      } else {
        setHasCreationError(true);
      }
    });
  }

  useEffect(() => {
    if (pollState.switchPoll.success) {
      setAdminPollNavigation(pollContainerStates.LIVE);
    }
  }, [pollState.switchPoll, setAdminPollNavigation]);

  return (
    <form onSubmit={handleSubmit} className="c-poll__form">
      <h3>{t("poll.new_poll")}</h3>
      {hasCreationError && (
        <div className="o-alert is-error">
          <span>{t("poll.error")}</span>
        </div>
      )}
      <InputField
        label={t("poll.question")}
        classLabel="c-poll__label"
        name="question"
        type="text"
        placeholder=""
        maxLength="100"
        errorText={t("poll.empty_error")}
        required
        onChange={(event) => setPollName(event.target.value)}
      />

      <div className="o-field" id="mail">
        <label className="c-poll__label">{t("poll.answers")}</label>
        <input
          type="text"
          className="o-field__input"
          placeholder={t("poll.option_1")}
          onChange={(event) => setAns1(event.target.value)}
          required
        />
        <input
          type="text"
          className="o-field__input"
          placeholder={t("poll.option_2")}
          onChange={(event) => setAns2(event.target.value)}
          required
        />
        <input
          type="text"
          className="o-field__input"
          placeholder={t("poll.option_3")}
          onChange={(event) => setAns3(event.target.value)}
        />
        <input
          type="text"
          className="o-field__input"
          placeholder={t("poll.option_4")}
          onChange={(event) => setAns4(event.target.value)}
        />
        <input
          type="text"
          className="o-field__input"
          placeholder={t("poll.option_5")}
          onChange={(event) => setAns5(event.target.value)}
        />
      </div>
      <div className="o-field__actions">
        <a
          href="/"
          onClick={handleCancel}
          className="o-button--bs o-button--link"
        >
          {t("poll.cancel")}
        </a>
        <button
          type="submit"
          disabled={loadingCreate}
          className="o-button--bs o-button--green"
        >
          {loadingCreate ? "Sending..." : t("poll.launch")}
        </button>
      </div>
    </form>
  );
}

export default FormPoll;
