import React from "react";
import { useTranslation } from 'react-i18next';

import IconBack from "components/shared/icon/back";

function SinglePoll(props) {
  const { t } = useTranslation(['watch']);
  const { pollData } = props;
  const { id, name, answers } = pollData;

  const totaVotes = answers.reduce((a, b) => +a + +b.total, 0);
  const mostVotedAnswer = answers.sort((a, b) => b.total - a.total)[0];

  function AnswerList(props) {
    const { answers } = props;

    return answers.map((ans, idx) => {
      return (
        <li className="c-poll__stats" key={idx}>
          <span>{ans.option_label}</span>
          <h4>{ans.total}</h4>
        </li>
      );
    });
  }

  return (
    <>
      <input className="c-poll__checkbox" type="checkbox" id={id} />
      <label htmlFor={id} className="c-poll__single">
        <IconBack width={12} />
        <h5 className="c-poll__title">{name}</h5>
        <h4 className="c-poll__winner">{mostVotedAnswer.option_label}</h4>

        <div className="c-poll__dropdown">
          <div className="c-poll__summary">
            <h5>{t ("poll.interactions")}</h5>
            <h4>{totaVotes}</h4>
          </div>
          <ul>
            <AnswerList answers={answers} />
          </ul>
        </div>
      </label>
    </>
  );
}
export default SinglePoll;
