
const elements = [
  {
    name: 'Artist managers',
    title: 'Keep your artists on top of the conversation',
    desc: 'From creating hype to monetizing from it. Get all the moving pieces of your artists’ strategy actually moving, here’s your opportunity to create a new pillar on the artist’s master plan.',
    link: '/whos-it-for/artist-managers',
    cta: 'Learn how other artist managers use Neerme',
    poster: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManager.jpg',
    webm_1: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManager.webm',
    webm_2: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManagerLow.webm',
    mp4: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManager.mp4'
  },
  {
    name: 'Content Creators',
    title: 'Create a whole new revenue stream out of your content',
    desc: 'From remarkable recorded content to a whole new live experience. Bringing your content to Neerme will enable new income resources for your project. ',
    link: '/whos-it-for/content-creators',
    cta: 'Learn how other content creators use Neerme',
    poster: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ContentCreator.jpg',
    webm_1: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ContentCreator.webm',
    webm_2: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ContentCreatorLow.webm',
    mp4: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/ContentCreator.mp4'
  },
  {
    name: 'Musicians',
    title: 'Create new experiences to make your music transcend',
    desc: 'From getting closer to your fans, to engaging new ideas. The perfect place to showcase your music in new ways that you haven’t tried before. That’s Neerme.',
    link: '/whos-it-for/musicians',
    cta: 'Learn how other musicians use Neerme',
    poster: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Musician.jpg',
    webm_1: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Musician.webm',
    webm_2: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/MusicianLow.webm',
    mp4: 'https://boletia-v2.s3.amazonaws.com/uploads/neerme/Musician.mp4'
  },
  {
    name: 'Comedians',
    title: 'Comedy thrives in Neerme',
    desc: 'From having limited audiences, to engaging with thousands. Exceed your expectations in ways you could have never imagined.',
    link: '/whos-it-for/comedians',
    cta: 'Learn how other comedians use Neerme',
    poster: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Comedian.jpg',
    webm_1: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Comedian.webm',
    webm_2: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/ComedianLow.webm',
    mp4: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Comedian.mp4'
  },
  {
    name: 'Theater Producers',
    title: 'Get your play watched by more people than ever',
    desc: 'In a world where theater productions don’t get the recognition they deserve, Neerme allows you to go from having space limited audiences, to engaging with thousands',
    link: '/whos-it-for/theater-producers',
    cta: 'Learn how other theater producers use Neerme',
    poster: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Theater.jpg',
    webm_1: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Theater.webm',
    webm_2: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/TheaterLow.webm',
    mp4: 'https://s3.amazonaws.com/boletia-v2/uploads/neerme/Theater.mp4'
  }
]

export default elements;