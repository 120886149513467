import React, {Component} from "react";
import Slider from "react-slick";
import elementsEn from './elements_en';
import elementsEs from './elements_es';
import ArrowIcon from '../../../shared/icon/arrow';
import { withTranslation } from 'react-i18next';

class Carousel extends Component {
  state = {
    carousel_elements: elementsEn,
    current_language: "en"
  }

  elements = this.state.carousel_elements
  settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 7000
  }

  componentDidUpdate() {
    const {i18n} = this.props;

    if (this.state.current_language !== i18n.language) {
      if (i18n.language==="en") {
        this.setState({
          carousel_elements: elementsEn,
          current_language: i18n.language
        })
      }

      else {
        this.setState({
          carousel_elements: elementsEs,
          current_language: i18n.language
        })
      }
    }
  }

  render () {
    return (
      <div className='SponsoredCarousel'>
        <Slider customPaging={(i) => {
          return(
            <div className='c-home-carousel__dots'>
              <h6>{this.state.carousel_elements[i].name}</h6>
            </div>
          )
          }
        } {...this.settings}>
          {
            this.state.carousel_elements.map((s, i) => (
              <div key={i}>
                <div className="c-home-carousel__wrapper">
                  <div className='c-home-carousel__content'>
                    <h6 className='c-landing__category'>{s.name}</h6>
                    <h2 className='c-home-carousel__subtitle'>{s.title}</h2>
                    <p className='c-home-carousel__text'>{s.desc}</p>
                    <a href={s.link} className='c-home-carousel__link'>
                      <ArrowIcon width={12} />
                      {s.cta}
                    </a>
                  </div>
                  <div className='c-home-carousel__video'>
                    <video 
                      muted 
                      loop 
                      autoPlay 
                      playsInline
                      poster={s.poster}
                    >
                      <source src={s.webm_1} type="video/webm"/>
                      <source src={s.webm_2} type="video/mp4"/>
                      <source src={s.mp4} type="video/mp4"/>
                    </video>
                  </div>
                </div>
              </div>
            ))
          }
        </Slider>
      </div>
    );
  }
}

export default withTranslation("translation")(Carousel);