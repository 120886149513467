import React, { useState, useEffect, useRef } from "react";

import Message from "../Message";
import ChatButton from "../ChatButton";
import ChatAlert from "../ChatAlert";
import { MESSAGE_TYPES } from "../consts";

function MessageList(props) {
  const { messages } = props;
  const [chatIsAtBottom, setChatIsAtBottom] = useState(true);

  const messageListRef = useRef();

  function scrollTop() {
    const messageListEl = messageListRef.current;
    messageListEl.scrollTop =
      messageListEl.scrollHeight - messageListEl.clientHeight;
  }

  function onScrollChat(event) {
    const messageListEl = messageListRef.current;
    const isAtBottom =
      messageListEl.scrollTop + messageListEl.clientHeight >=
      messageListEl.scrollHeight;
    setChatIsAtBottom(isAtBottom);
  }

  function scrollOnMessage(event) {
    const { currentTarget: target } = event;
    if (chatIsAtBottom) {
      try {
        target.scroll({ top: target.scrollHeight, behaivor: "smooth" });
      } catch (error) {
        try {
          scrollTop();
        } catch (error) {}
      }
    }
  }

  useEffect(() => {
    const messageListEl = messageListRef.current;
    if (messageListRef) {
      messageListEl.addEventListener("DOMNodeInserted", scrollOnMessage);
    }
    return () => {
      if (messageListRef) {
        messageListEl.removeEventListener("DOMNodeInserted", scrollOnMessage);
      }
    };
    // eslint-disable-next-line
  }, [chatIsAtBottom]);

  return (
    <div className="c-chat__container">
      <div
        onScroll={onScrollChat}
        className="c-chat__list"
        ref={messageListRef}
      >
        {messages.map((message, i) => {
          if (message.type === MESSAGE_TYPES.NOTIFICATION) {
            return (
              <ChatAlert
                key={message.level_name + message.nickname}
                {...message}
              />
            );
          } else {
            return (
              <Message key={message.id} {...message} />
            );
          }
        })}
      </div>
      {!chatIsAtBottom && <ChatButton clickHandler={scrollTop} />}
    </div>
  );
}
export default MessageList;
