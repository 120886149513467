import React from "react";

import { useAuth } from "contexts/authContext";

import WaterMark from "./WaterMark";

function CustomOverlay(props) {
  const { getUserID } = useAuth();
  return (
    <>
      <WaterMark userID={getUserID()} />
    </>
  );
}

export default CustomOverlay;
