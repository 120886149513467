import React, { useEffect, useState } from "react";

import IconEye from "components/shared/icon/eye";

import { useChannelWebsocketContext } from "contexts/channelWebsocket";
import { useWatchEventContext } from "contexts/WatchEventContext";
import { setMessage } from "utils/webSocketHelper";

const ROUTE_CHANNEL_SUBSCRIBERCOUNT = "v1/channel/subscriberscount";
const ACTION_CHANNEL_USER_CONNECTIONS = "channelSubscribersCount";

const SEND_MESSAGE_INTERVAL_TIME = 30000;

function ViewersCount(props) {
  const { showCounter } = props;
  const { eventSubdomain } = useWatchEventContext();
  const [toggleVerbose, setToggleVerbose] = useState(false);
  const {
    userConnections,
    sendMessage,
    lastMessage,
    setUserConnections,
  } = useChannelWebsocketContext();

  function getViewerCount() {
    const streamJoinUserMessage = setMessage(ROUTE_CHANNEL_SUBSCRIBERCOUNT, {
      event_subdomain: eventSubdomain,
    });
    sendMessage(streamJoinUserMessage);
  }

  useEffect(() => {
    getViewerCount();
    const sendMessageInterval = setInterval(() => {
      getViewerCount();
    }, SEND_MESSAGE_INTERVAL_TIME);
    return () => clearInterval(sendMessageInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastMessage !== null) {
      const { action, data } = JSON.parse(lastMessage.data);
      switch (action) {
        case ACTION_CHANNEL_USER_CONNECTIONS:
          if (data.users_online === 0) return;
          setUserConnections(data.users_online);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const complete = `${userConnections}`.toLowerCase();
  const verbose = `${userConnections}`.toLowerCase();

  return (
    <>
      {showCounter && (
        <li className="o-badge is-visitors">
          <IconEye width={18} />
          <span
            className="c-badge__text"
            onClick={() => {
              setToggleVerbose((b) => !b);
            }}
          >
            {toggleVerbose ? complete : verbose}
          </span>
        </li>
      )}
    </>
  );
}

export default ViewersCount;
