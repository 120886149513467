import React from "react";
import { useWatchEventContext } from "contexts/WatchEventContext";

import StripeProcess from "./StripeProcess"
import XsollaProcess from "./XsollaProcess"

const PAYMENT_PROCESSORS = {
  STRIPE: "stripe",
  XSOLLA: "xsolla",
};

function Payment(props) {
  const { eventData } = useWatchEventContext();
  const provider = eventData?.monetization_info?.provider;

  switch (provider) {
    case PAYMENT_PROCESSORS.STRIPE: {
      return <StripeProcess />;
    }
    case PAYMENT_PROCESSORS.XSOLLA: {
      return <XsollaProcess />;
    }
    default: {
      return <XsollaProcess />;
    }
  }
}

export default Payment;
