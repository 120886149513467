import Login from "components/pages/Login";
import ActivateAccount from "components/pages/ActivateAccount";

const authRoutes = [
  {
    name: "login",
    route: "/login",
    component: Login,
  },
  {
    name: "loginActivateAccount",
    route: "/login/activate/:emailToken",
    component: ActivateAccount,
  },
  {
    name: "activateAccount",
    route: "/activate/:emailToken",
    component: ActivateAccount,
  },
];

export default authRoutes;
