import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'is-lock',
  viewBox = '0 0 20 24'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id="Artboard" fill={fill}>
      <path d='M10,0 C13.859,0 17,3.141 17,7 L17,7 L17,8.142 C18.721,8.589 20,10.142 20,12 L20,12 L20,20 C20,22.206 18.206,24 16,24 L16,24 L4,24 C1.794,24 0,22.206 0,20 L0,20 L0,12 C0,10.142 1.279,8.589 3,8.142 L3,8.142 L3,7 C3,3.141 6.141,0 10,0 Z M16,10 L4,10 C2.897,10 2,10.897 2,12 L2,12 L2,20 C2,21.103 2.897,22 4,22 L4,22 L16,22 C17.103,22 18,21.103 18,20 L18,20 L18,12 C18,10.897 17.103,10 16,10 L16,10 Z M10,13 C11.104,13 12,13.896 12,15 C12,15.738 11.596,16.376 11,16.723 L11,16.723 L11,18 C11,18.553 10.553,19 10,19 C9.447,19 9,18.553 9,18 L9,18 L9,16.723 C8.404,16.376 8,15.738 8,15 C8,13.896 8.896,13 10,13 Z M10,2 C7.243,2 5,4.243 5,7 L5,7 L5,8 L15,8 L15,7 C15,4.243 12.757,2 10,2 Z'></path>
    </g>
  </svg>
)

export default SVG
