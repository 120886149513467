import React, { useEffect, useRef } from "react";

import useScript from "hooks/useScript";

function JwPlayer(props) {
  const videoNode = useRef();
  // eslint-disable-next-line
  const [loadedJw, loadJwError] = useScript(
    "https://cdn.jwplayer.com/libraries/2SNTg2KD.js"
  );
  const { src } = props;

  useEffect(() => {
    if (loadedJw) {
      const videoElement = videoNode.current;
      // eslint-disable-next-line
      jwplayer(videoElement).setup({
        playlist: [
          {
            file: src,
          },
        ],
      });
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [loadedJw]);
  return (
    <>
      <div ref={videoNode}></div>
    </>
  );
}

export default JwPlayer;
