import {
  GET_XSOLLA_ORDER_STATUS_REQUESTED,
  GET_XSOLLA_ORDER_STATUS_FULFILLED,
  GET_XSOLLA_ORDER_STATUS_REJECTED,
  SET_XSOLLA_ORDER_STATUS_REQUESTED,
  SET_XSOLLA_ORDER_STATUS_FULFILLED,
  SET_XSOLLA_ORDER_STATUS_REJECTED,
} from "./types";

export const initialXsollaOrderStatus = {
  getXsollaOrderStatus: {
    loading: false,
    success: false,
    data: [],
    error: false,
  },
  setXsollaOrderStatus: {
    loading: false,
    success: false,
    data: {},
    error: false,
  },
};

export default function reducer(state, action) {
  switch (action.type) {
    case GET_XSOLLA_ORDER_STATUS_REQUESTED: {
      const { getXsollaOrderStatus } = action;
      return { ...state, getXsollaOrderStatus };
    }
    case GET_XSOLLA_ORDER_STATUS_FULFILLED: {
      const { getXsollaOrderStatus } = action;
      return { ...state, getXsollaOrderStatus };
    }
    case GET_XSOLLA_ORDER_STATUS_REJECTED: {
      const { getXsollaOrderStatus } = action;
      return { ...state, getXsollaOrderStatus };
    }
    case SET_XSOLLA_ORDER_STATUS_REQUESTED: {
      const { setXsollaOrderStatus } = action;
      return { ...state, setXsollaOrderStatus };
    }
    case SET_XSOLLA_ORDER_STATUS_FULFILLED: {
      const { setXsollaOrderStatus } = action;
      return { ...state, setXsollaOrderStatus };
    }
    case SET_XSOLLA_ORDER_STATUS_REJECTED: {
      const { setXsollaOrderStatus } = action;
      return { ...state, setXsollaOrderStatus };
    }
    default: {
      return state;
    }
  }
}
