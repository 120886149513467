import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

import { useHistory } from "react-router-dom";

import LiveIcon from "components/shared/icon/stream";
import useQuery from "hooks/useQuery";

// import Loading from "../Loading/Loading";

function EventAccess(props) {
  const { t } = useTranslation(['event_site']);
  const history = useHistory();
  const queryParams = useQuery();
  const { eventSubdomain } = props;
  const [accessCode, setAccessCode] = useState("");
  const hasError = queryParams.get("access") === "error";
  function handleSubmit(event) {
    event.preventDefault();
    if (accessCode.length === 0) return;
    history.push(`/${eventSubdomain}/${accessCode}`);
  }

  return (
    <div className="c-event-page__block is-access">
      <h4>{t ("access.title")}</h4>
      <form onSubmit={handleSubmit} className="c-event-page__form">
        <input type="text" onChange={(e) => setAccessCode(e.target.value)} />
        <button type="submit" className="o-button--lg o-button--outline is-light">
          <LiveIcon width={16} />
          {t ("access.go")}
        </button>
        {hasError && (
          <span className="o-field__error">{t ("access.error")}</span>
        )}
      </form>
    </div>
  );
}

export default EventAccess;
