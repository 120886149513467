import React from "react";

import { useAuth } from "contexts/authContext";
import { useChannelWebsocketContext } from "contexts/channelWebsocket";
import { AdminPollContextProvider } from "contexts/adminPoll";

import AdminRouterPoll from "./AdminRouterPoll";

function AdminPoll(props) {
  const { authToken } = useAuth();
  const { connectionData } = useChannelWebsocketContext();

  return (
    <AdminPollContextProvider
      requestData={{
        authToken,
        eventSubdomain: connectionData.event_subdomain,
      }}
    >
      <AdminRouterPoll />
    </AdminPollContextProvider>
  );
}

export default AdminPoll;
