import React from "react";
import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";

function TermsConditions(props) {
  return (
    <>
      <Header stylePage={"is-gradient"} />
      <div className="c-layout">
        <div className="c-legal">
          <h1>Términos y Condiciones</h1>
          <p>Términos de servicio</p>
          <p>Boletia.com o Boletia es una plataforma digital como se describe en las definiciones, operada por Boletia México S.A de C.V.</p>
          <p>En lo sucesivo encontrarás los Términos y Condiciones aplicables a todos los Organizadores registrados que realicen operaciones y organización de eventos, salvo la condición de que Boletia considere necesaria la firma de un contrato para el perfeccionamiento de la relación jurídica. El presente describe los Términos y Condiciones generales (“Términos y Condiciones o TyC”) aplicables al uso de los servicios ofrecidos de operaciones de organización de eventos. Boletia podrá en cualquier momento solicitar al Organizador la firma de un contrato para la organización eventos en la página, el cual regirá en lo conducente los eventos de dicho Organizador y derogará lo dispuesto por estos Términos y Condiciones. Cualquier persona que desee acceder y/o usar la plataforma digital y sus servicios podrá hacerlo sujetándose a los Términos y Condiciones generales, junto con todas las demás políticas y principios que rigen a Boletia y que son incorporados al presente por referencia.</p>
          <p>Favor de leer los siguientes Términos y Condiciones de servicio (en lo sucesivo TyC), pues contienen información de contundencia respecto a tus derechos y obligaciones como Organizador de eventos; el desconocimiento de lo aquí contenido no exime de responsabilidad ni excluye de alcance.</p>
          <p>En lo sucesivo encontrarás los Términos y Condiciones que aceptas al efectuar las siguientes acciones (i) Al registrarte como Usuario, (ii) Al publicar un evento por medio de boletia.com tomando en consideración lo dispuesto en el artículo 1803 del Código Civil Federal, los presentes Términos y Condiciones son consentidos de forma tácita en el entendido que de conformidad al citado artículo “el consentimiento puede ser expreso o tácito, para ello se estará a lo siguiente:</p>
          <ul>
            <li>I.- Será expreso cuando la voluntad se manifiesta verbalmente, por escrito, por medios electrónicos, ópticos, o por cualquier otra tecnología, o por signos inequívocos y</li>
            <li>II.- El tácito resultará de hechos o de actos que lo presupongan o que autoricen a presumirlo, excepto en los casos en que por ley o por convenio la voluntad te va a manifestarse expresamente”, por lo que en el entendido de que lleves a cabo la generación de un Usuario Registrado, será suficiente para que tu consentimiento se dé de forma expresa y el uso de la plataforma digital será una manifestación tácita de tu consentimiento.</li>
            <li>Cualquier persona que no acepte estos Términos y Condiciones generales, los cuales tienen un carácter obligatorio y vinculante deberá abstenerse de utilizar el sitio y o los servicios. El uso del sitio y la aceptación a estos Términos y Condiciones tendrá la misma fuerza legal que una firma autógrafa y podrá ser elegible como prueba judicial en caso de controversia.</li>
          </ul>
          <h3>PRIMERA. - Documentos necesarios para dar de alta un evento en la plataforma digital de boletia.com</h3>
          <ol>
            <li>CÉDULA DEL RFC.</li>
            <li>IDENTIFICACIÓN OFICIAL: Credencial de elector o en su defecto, pasaporte.</li>
            <li>COMPROBANTE DE DOMICILIO: No mayor a 3 meses.</li>
            <li>CARÁTULA DEL ESTADO DE CUENTA: No mayor a 3 meses, debe coincidir con el domicilio del comprobante de domicilio.</li>
            <li>Teléfono de contacto.</li>
          </ol>
          <p>Dichos documentos deberán ser enviados a contratos@boletia.com para proceder con la realización del contrato. </p>
          <p>Cuando Boletia lo requiera podrá pedir más información relativa al cliente o al evento en cuestión.</p>
          <h3>SEGUNDA. - Definiciones</h3>
          <p>Las siguientes palabras deberán de ser interpretadas como se describe para efectos de comprensión de los presentes Términos y Condiciones.</p>
          <ul>
            <li>a. Generación de Eventos: El Organizador tendrá acceso a la plataforma digital en donde podrá organizar sus eventos teniendo que establecer como mínimo: Nombre, nombre para subdominio que será asignado por Boletia de conformidad a estos Términos y Condiciones, asistencia estimada al evento, fecha de inicio y conclusión del evento, ubicación en caso de eventos físicos, descripción del evento, cuenta bancaria, RFC, datos fiscales, patrocinadores, promociones, tipos de boleto, tipo de comisión y establecer precio de boletos, cualquier modificación a dichos elementos dará lugar a que Boletia pueda modificar los Términos y Condiciones con los que se contrató.</li>
            <li>b. Características de los eventos y boletos que Boletia maneja: Los eventos organizados podrán realizarse con asistencia física o plataforma digital de una marca tercera o la propia: Neerme. Los boletos deberán indicar, como mínimo; nombre, costo, cantidad a la venta, máximo de boletos por venta y rango de fechas de disponibilidad.</li>
            <li>c. Requisitos de los Eventos: Todos los Organizadores deberán de designar: un nombre y subdominio de ventas para el evento, lugar (en su caso), fecha, descripción, tipo (s) de boleto (s) y forma (s) de pago. En caso de que la descripción del evento o demás elementos no resulten claros, Boletia podrá solicitar al Organizador la corrección del mismo, así como suspender el servicio de venta de boletos hasta en tanto no se haya corregido la petición.</li>
            <li>d. Métodos de pago por parte de compradores: Los Organizadores podrán elegir que los compradores les paguen con tarjeta de crédito o débito, en tiendas Oxxo, PayPal, boleto físico, depósito no referenciado, depósito a Boletia o efectivo mismos que podrán limitarse según el evento que se trate.</li>
            <li>e. Contraprestación: El Organizador por uso de la plataforma digital pagará a Boletia una contraprestación de conformidad al tipo de vertical a la que pertenezca como se describe más adelante.</li>
            <li>f. General: El Organizador reconoce que Boletia podrá actuar en su nombre para cobrar el monto total del boleto adquirido incluyendo los impuestos aplicables, a su vez, acepta que fungirá como un agente de ventas por comisión. Boletia, en el desempeño de su encargo, recibirá la totalidad de los montos pagados por parte de los compradores y entregará al Organizador el remanente después de retener las contraprestaciones acordadas. Esta última con los impuestos correspondientes a la regulación fiscal aplicable.</li>
            <li>g. Facturación: Toda la facturación relativa a la venta de boletos a los compradores será efectuada por el Organizador directamente a los compradores. Independientemente de lo anterior Boletia facturará al Organizador el monto que éste llegue a retener y que los terceros hayan retenido por cada uno de los métodos de pago por concepto de comisión, más los impuestos aplicables. Las facturas por concepto de comisión se hacen llegar el último día de cada mes que el evento esté a la venta. La última factura por concepto de comisión se envía 5 días hábiles después del corte final y cierre del evento. Únicamente para los casos de eventos de live streaming a través de Neerme, los pagos de efectuarán una vez que el evento fue llevado a cabo.</li>
            <li>h. Limitaciones del servicio: El servicio se limita a proporcionar las herramientas y permitir el uso de la plataforma digital en las modalidades que permite Boletia. El servicio que Boletia presta, no implica la prestación de servicios de promoción, mercadotecnia, organización, publicidad, ni cualquier otro servicio no descrito en los presentes Términos y Condiciones, mismos que podrán ser contratados por el Organizador de manera específica con Boletia en términos de los presentes Términos y Condiciones. Boletia no es una institución bancaria ni financiera por lo que ninguno de los servicios aquí contenidos debe de entenderse como tal. Todas las operaciones efectuadas por conducto de Boletia se tendrán como realizadas en México. Boletia únicamente aceptará operaciones en pesos mexicanos.</li>
            <li>j. Compradores: Se refiere a los Usuarios que utilizan la plataforma digital para comprar boletos de un evento, de algún Organizador, en donde Boletia les asignará un Usuario registrado que solamente será activado a su discreción, no obstante, bastará la compra-venta, adquisición o reserva de un boleto para que un Usuario sea tácitamente considerado comprador.</li>
            <li>k. Contracargos: Un contracargo es un mecanismo creado por el Banco de México, que permite solucionar cualquier disputa sobre cargos hechos a una tarjeta de crédito o débito. Es una manera de poder proteger al tarjetahabiente (titular de una tarjeta) de posibles cargos hechos sin su consentimiento.</li>
            <li>l. Plataforma Digital: Se refiere al sistema para la compra venta de boletos alojada en https://www.boletia.com y/o plataforma digital de live streaming de una marca tercera o la propia: Neerme. </li>
            <li>m. Usuarios: Cualquier persona que utilice y/o navegue la Plataforma Digital ya sea Organizador, comprador o turista Cibernético.</li>
            <li>n. Usuario registrado: Los Organizadores y/o Compradores que dieron de alta un nombre de Usuario y contraseña para acceder a la Plataforma Digital.</li>
            <li>o. Servicio: Sistema de organización de eventos controlado por el Organizador y ofrecido mediante la Plataforma Digital, el cual permite a los Organizadores tener un control directo sobre su boletaje, precios, administración financiera de eventos, y facilita a los Usuarios la cobranza y adquisición de boletos, guardar boletos de forma digital, agilizar el registro de asistentes a eventos y promoción de eventos. También, en los casos en que fue acordado, el acceso a la plataforma de live streaming: Neerme en dónde el usuario podrá visualizar los contenidos que el Organizador defina.</li>
          </ul>
          <h3>TERCERA. Personalidad, Domicilio, Descripción y Ámbito del servicio.</h3>
          <p>(i) Boletia México S.A de C.V. es una es una Sociedad Anónima de Capital Variable(ii) con domicilio en Leibnitz 20, Colonia Anzures, C.P. 11590, Delegación Miguel Hidalgo, Ciudad de México, (iii) que opera los servicios prestados por conducto de la Plataforma Digital(iv) en donde está alojado un sistema que facilita la publicación de eventos, así como la compraventa de boletos.</p>
          <p>Por virtud de los presentes Términos y Condiciones Boletia realizará las actividades de un agente comercial, cuyo servicio será únicamente el contratado por el Organizador, sin que de ninguna manera se entienda que el servicio que realizará Boletia es una comisión mercantil.</p>
          <p>Esto es, de ninguna manera se entenderá que Boletia realiza actividades por nombre y a cuenta del Organizador, motivo por el cual cuando un comprador realice la contratación de un evento realizado con el Organizador, el contrato se entenderá realizado entre ambos, y de ninguna manera existirá contrato entre Boletia y el comprador.</p>
          <h3>CUARTA. Elegibilidad</h3>
          <p>La Plataforma Digital, tiene el objetivo de ser utilizada únicamente por personas mayores de edad, cualquier uso realizado por un menor de edad queda estrictamente prohibido. Los Usuarios al acceder al Sitio afirman y garantizan tener edad legal para contratar y estar en pleno uso de sus facultades y capacidades. Por el simple hecho de ser un Usuario de la Plataforma Digital se manifiesta que es mayor de edad y con capacidad para contratar.</p>
          <h3>QUINTA. Territorio</h3>
          <p>Los Servicios prestados por Boletia deberán de entenderse como servicios prestados en la República Mexicana sin importar en donde fueron originados o solicitados.</p>
          <h3>SEXTA. General</h3>
          <ul>
            <li>a. Estos Términos y Condiciones le son aplicables a todos los Usuarios Registrados en la modalidad de Organizador. Los Usuarios, aceptan todos los términos de privacidad y seguridad de Boletia.</li>
          </ul>
          <h3>SÉPTIMA. Aceptación de los términos de condición.</h3>
          <ul>
            <li>a. Los Usuarios aceptan los Términos y Condiciones aquí contenidos.</li>
            <li>b. Los Términos y Condiciones son consentidos al momento de generar un Usuario Registrado, independientemente de que sea Usuario Organizador.</li>
            <li>c. Los Usuarios reconocen la formalidad de este escrito y el alcance de las obligaciones a pesar de que sean de naturaleza digital.</li>
            <li>d. Los presentes Términos y Condiciones son temporales, por lo que a los 21 días de que el evento sea activado, Boletia podrá, para continuar el servicio bajo estos Términos y Condiciones, o bien, proponer la firma de un Anexo al Organizador, en cuyo caso el Anexo derogará lo previsto por estos Términos y Condiciones en lo que se contravengan sus disposiciones.<br/>Si el Organizador se niega a la firma del anexo se cancelará el evento, sin ninguna responsabilidad para Boletia.</li>
            <li>e. Para efectos del supuesto anterior, (a) Boletia generará un documento para descarga y/o envío de correo electrónico que deberá de ser firmado por el Organizador y enviado a la dirección que se describe en el punto 2 de éstos Términos y Condiciones o de forma digital a la cuenta contratos@boletia.com (b) Boletia se reserva el derecho a detener la venta de boletos y reembolsos al Organizador hasta que se formalice el documento en mención.</li>
          </ul>
          <h3>OCTAVA. Términos y Condiciones de Uso.</h3>
          <ul>
            <li>a. El Organizador, por este conducto recibe una licencia de uso de la Plataforma Digital únicamente para navegar en las páginas de la Plataforma Digital para su propio conocimiento, revisar información de eventos y publicidad y, en su caso, generar eventos de conformidad a la descripción del servicio.</li>
            <li>b. El Usuario reconoce que el uso de la Plataforma Digital no le otorga ningún derecho de propiedad sobre el mismo, cualquiera de sus elementos o contenidos, así como ninguno de los derechos de explotación que existen o puedan existir sobre la Plataforma Digital o los Contenidos.</li>
            <li>c. Todos los Contenidos de la Plataforma Digital con expeción de los contenidos que se ofrezcan a través del Live Streaming Neerme, son propiedad de Boletia o de terceros colaboradores, sin que pueda entenderse que el uso o acceso a la Plataforma Digital, le atribuyan al Usuario derecho alguno sobre los mismos.</li>
            <li>d. Los Contenidos y/o la Plataforma Digital no pueden ser copiados, reproducidos, republicados, enviados, transmitidos, transcritos, traducidos, almacenados, alterados, descargados o distribuidos en ningún medio inventado o por inventar, ni por ningún motivo en cuyo caso se tendrá como pena convencional el pago en favor de Boletia, de 200,000.00 (DOSCIENTOS MIL PESOS M.N. 00/100).</li>
            <li>e. El Usuario se obliga a no utilizar técnicas de ingeniería inversa, descompilación o desensamblaje ni podrá modificar, traducir la Plataforma Digital, toda vez que el mismo goza de la protección que las leyes y tratados de propiedad industrial e intelectual establecen en cuyo caso se tendrá como pena convencional el pago en favor de Boletia, de 200,000.00 (DOSCIENTOS MIL PESOS M.N. 00/100).</li>
            <li>f. El Organizador se obliga a no utilizar ningún robot, araña, aparato automático, programación especial o procesamiento manual para monitorear o copiar la Plataforma Digital o los Contenidos, en cuyo caso se tendrá como pena convencional el pago en favor de Boletia, de 200,000.00 (DOSCIENTOS MIL PESOS M.N. 00/100).</li>
            <li>g. El Organizador acepta en no utilizar ningún tipo de aparato, software o rutina, existente o por inventar, que interfiera o entorpezca con el funcionamiento adecuado del La Plataforma Digital, en cuyo caso se tendrá como pena convencional el pago en favor de Boletia, de 100,000.00 (CIEN MIL PESOS M.N. 00/100).</li>
            <li>h. Todo uso no autorizado de la Plataforma Digital en virtud de estos Términos y Condiciones tendrá como pena convencional el pago en favor de Boletia, de 100,000.00 (CIEN MIL PESOS M.N. 00/100).</li>
            <li>i. Boletia se reserva el derecho de realizar las acciones que por derecho le correspondan para verificar el cumplimiento de los Términos y Condiciones.</li>
            <li>k. Los Contenidos y consejos expresados en la Plataforma Digital deben entenderse como orientativos; por lo que Boletia no responde de ninguna forma de la efectividad o exactitud de los mismos, quedando exenta de cualquier responsabilidad contractual o extracontractual con el Organizador.</li>
            <li>j. El Organizador entiende y es consciente de que al subir contenidos en la plataforma digital de Boletia está realizando la oferta de un servicio al público en general, motivo por el cual, al ser comprado un boleto, la relación jurídica será existente entre el comprador y el Organizador, siendo Boletia únicamente un agente.</li>
            <li>l. Para el caso de la aplicación de Boletia, y demás sistemas que Boletia ponga al alcance de los Organizadores, resultarán aplicables los presentes Términos y Condiciones, excepto cuando un documento establezca lo contrario.</li>
          </ul>
          <h3>NOVENA. Modelo de Trabajo y Métodos de pago.</h3>
          <ul>
            <li>a. El Organizador, por cada evento generado, o bien, según lo hubiera estipulado en el Contrato de servicios que formalicen por escrito con Boletia, tiene la obligación de elegir modelo de trabajo de acuerdo a la naturaleza del vertical a la que pertenece el evento en relación al cargo de las comisiones teniendo como opción:
              <ul>
                <li>i. Entretenimiento: Se entenderá por entretenimiento o esparcimiento, de forma enunciativa más no limitativa, aquellos eventos musicales, conciertos, festivales, espectáculos deportivos, partidos, eventos de combate, eventos culturales, exhibiciones, obras teatrales, ferias, eventos de comida, vendimias, exposiciones de cerveza, presentaciones cómicas o stand up, eventos de presentación o meet and greet. En estos casos la comisión de Boletia será del 9.5% del costo del boleto. Esto es independiente de la comisión de las formas de pago que corresponde al 3.5%.</li>
                <li>ii. Reuniones, expos y congresos: Se entenderá por estos las exposiciones en las que haya consumo, congresos profesionales o estudiantiles, conferencias, taller o curso, graduación, evento familiar, o evento religioso. En estos casos la comisión de Boletia será del 8% del costo del boleto. Esto es independiente de la comisión de las formas de pago que corresponde al 3.5%.</li>
                <li>iii. Evento Deportivo: Registro de Competidores para eventos deportivos, carreras de ciclistas, carreras de diversas distancias, triatlones, Trail, resistencia.En estos casos la comisión de Boletia será del 8% del costo del boleto. Esto es independiente de la comisión de las formas de pago que corresponde al 3.5%.</li>
                <li>iv. Evento en Live Streaming a través de Neerme: Se entenderá por evento en Live Streaming aquel en el que el Organizador utiliza la plataforma de venta de boletos de Boletia y pone a disposición de los compradores un contenido que será transmitido a través de la plataforma tecnológica de Live Streaming llamada Neerme. En estos casos la comisión será del 15% del costo del boleto.</li>
              </ul>
            </li>
          </ul>
          <h3>DÉCIMO. Pagos.</h3>
          <ul>
            <li>a. Para efectos de estos Términos y Condiciones las Partes acuerdan que todos los pagos en relación a la adquisición de boletos deberán de ser efectuados por parte de los Compradores en cualquiera de los modelos de pago contemplados para cada evento.</li>
            <li>b. En el caso de todos los métodos de pago establecidos en el punto 9 la totalidad del pago efectuado por el Comprador será depositado en la cuenta concentradora de Boletia quien únicamente retendrá la comisión pactada en cláusulas anteriores y depositará al Organizador semanalmente el remanente, a excepción del caso de evento live streaming que se realizará hasta finalizado el evento. El depósito al Organizador será el saldo de boletaje vendido menos comisiones; será responsabilidad del Organizador entregar a Boletia los datos de la cuenta bancaria para efectuar el depósito; Boletia contará con un periodo no menor a 5 días hábiles y no mayor a 10 días hábiles para efectuar al Organizador el depósito del reembolso que corresponde semanalmente o que haya llegado a su fin. Boletia, se reserva el derecho para retener del saldo de boletaje pagado de los Organizadores cualquier cantidad que hubiera sido pagada con Tarjeta de Crédito y/o de Debito esto con el fin de asegurar el resarcimiento de daño por cualquier Contracargo realizado con dicho método de pago. La retención nunca será superior al 15% de las ventas realizadas con Tarjeta de Crédito y/o Débito del remanente final. Boletia no retendrá el porcentaje por un periodo superior a 60 días naturales posteriores a partir de que dicha cantidad fue retenida. Los tiempos de reportes y depósitos podrán variar cuando Boletia considere necesario.</li>
          </ul>
          <p>En el caso de que se alteren los reportes que Boletia entrega al Organizador, o bien los reportes que Boletia solicite al Organizador, éste deberá pagar a Boletia una pena convencional de $200,000.00 (DOS CIENTOS MIL PESOS 00/100 M.N.), además de que Boletia podrá dar de baja el Usuario y reservarse el derecho de negar el servicio para eventos subsecuentes a dicho Organizador.</p>
          <p>Asimismo, Boletia podrá retener los pagos de las últimas 5 semanas, para realizar la compensación que se deberá hacer para aquellos casos en los que los boletos comprados vayan a la cuenta del Organizador, debiendo presentar el Organizador un estado de cuenta final que refleje la compensación.</p>
          <h3>DÉCIMO PRIMERO. Obligaciones del Organizador.</h3>
          <ul>
            <li>a. El Organizador reconoce y acepta que estos Términos y Condiciones generan obligaciones de éste con Boletia.</li>
            <li>b. El Organizador manifiesta que reconoce el alcance de estos Términos y Condiciones y que toda la información provista es veraz y que no hay impedimentos legales para contratar.</li>
            <li>c. El Organizador afirma haber llenado el formulario de registro de Usuarios de Boletia de forma veraz y real, por lo se obliga a mantener su información actualizada y completa.</li>
            <li>d. El Organizador reconoce que, si por algún motivo hubiere proveído información falsa, inexacta, desactualizada o incompleta, Boletia podrá unilateralmente suspender o cerrar la cuenta, así como negarle el uso futuro de la Plataforma Digital, emprendiendo las acciones legales correspondientes.</li>
            <li>e. Queda estrictamente prohibida la reventa de cualquier Servicio que Boletia preste a favor del Organizador y/o la venta del nombre de Usuario y/o subdominio.</li>
            <li>f. Imagen. El Organizador acepta usar la identificación de que el sitio donde se deberán comprar los boletos es en Boletia, ya sea mediante la identificación de la página de Boletia, o bien, a través de todos los aparatos de difusión que utilice para su evento; tanto en línea como fuera de línea, siempre y cuando la decisión de publicar o no la imagen de Boletia esté en su poder.</li>
            <li>g. En caso de no utilizar la imagen de la marca de Boletia, o de negarse a utilizarla, Boletia se reserva el derecho a la suspensión del servicio sin previo aviso.</li>
            <li>h. Cortesías. El Organizador acepta otorgar a Boletia mínimo 10 cortesías las cuales podrían ser utilizadas para para uso comercial, interno y/o generación de contenido.</li>
            <li>i. Proceso de Validación. Para garantizar la seguridad de todos los Usuarios y participantes de los Eventos realizados, así como para evitar cualquier controversia relativo a usos indebidos de los métodos de pago circunstancia que pudiera generar acciones en contra de Boletia, los Organizadores y los Usuarios Registrados, el Organizador tiene la obligación de generar un proceso de validación de identidad de todos los asistentes a sus Eventos. En caso de ser necesario y previa petición de parte, el Organizador podrá solicitar apoyo a Boletia para generar el proceso de validación de Identidad dentro de la Plataforma Digital.</li>
            <li>j. En todo momento el Organizador permitirá a Boletia la revisión de cualquier información aportada para la realización del evento, de manera tal que le entregará a Boletia cualquier información que le sea requerida en un plazo no mayor a 3 días naturales.</li>
            <li>k. El organizador es responsable de cómo autoriza, distribuye o asigna códigos de promoción, cortesías, cupones, o equivalente.</li>
          </ul>
          <h3>DÉCIMO SEGUNDO. Deslinde de responsabilidad.</h3>
          <ul>
            <li>a. Boletia no se hace responsable por cancelación de eventos y/o por falta de Compradores de boletos para eventos, es responsabilidad de los Compradores validar el estado que guardan los eventos por los cuales adquirieron o adquirirán boletos.</li>
            <li>b. En el caso de cancelación de eventos o eventos que sean post fechados, el Organizador deberá seguir la política de cancelación o eventos pospuestos que se les hace llegar vía correo electrónico. La obligación de comunicar a los asistentes de dicha cancelación es del Organizador. </li>
            <li>c. Boletia no se hace responsable de ningún costo, daño o perjuicio que se ocasione por cancelación o aplazamiento de eventos, toda la responsabilidad estará a cargo de los Organizadores y operadores del recinto en donde el evento fuera a llevarse a cabo.</li>
            <li>d. Boletia no será responsable de las pérdidas o daños causados a los clientes de los eventos, ello derivado de que tal y como ya fue establecido en estos Términos y Condiciones, la contratación es directa entre los Compradores y el organizador, siendo Boletia sólo un agente.</li>
            <li>e. Ante una cancelación de evento, el Organizador tiene la obligación de notificar a Boletia dicha situación en un plazo no mayor a 3 días, el Organizador será el responsable del reembolso a Boletia. Dicho lo anterior Boletia es el responsable de efectuar el reembolso al comprador. No obstante que Boletia reembolse el costo del boleto, no reembolsará las comisiones a ninguna de las partes que se hubieren ocasionado por la venta del boleto. El valor de estas comisiones, si no está especificado como tal en el proceso de compra de boletos, será el resultado de lo pactado en el punto NOVENO.</li>
            <li>f. Será obligación del Organizador devolver todos los reembolsos de venta de boletos en un plazo no mayor a 30 días naturales que le fueron entregados para poder hacer efectivo el reembolso correspondiente a la totalidad de asistentes que compraron boletos.</li>
            <li>g. Boletia no se responsabiliza por la falta de liquidez del Organizador del evento para realizar tal devolución de fondos en caso de que el Organizador no cumpla con lo anterior se procederá con las acciones legales correspondientes.</li>
            <li>h. Boletia queda autorizado en este acto por el Organizador para proporcionar los datos de contacto con los asistentes y dar seguimiento antes las instancias y las autoridades competentes es caso de que se presente alguna reclamación y/o conflicto de intereses.</li>
            <li>i. El Organizador responderá frente a las autoridades competentes y sacará en paz y a salvo a Boletia de las demandas que se pudieran presentar.</li>
          </ul>
          <h3>DÉCIMO TERCERO. Contracargos.</h3>
          <p>De conformidad al Artículo 23 de la Ley para la Transparencia y Ordenamiento de los Servicios Financieros, un Contracargo se genera cuando un tarjetahabiente se pone en contacto directo con su banco para notificar que no reconoce un cargo hecho a su tarjeta de crédito o débito. En ese momento, el banco emisor (banco del tarjetahabiente) crea una solicitud de Contracargo hacia la transacción. Por este motivo, el Organizador reconoce y acepta que al momento de recibir una solicitud de Contracargo, Boletia procederá de la siguiente forma:</p>
          <ul>
            <li>a. Antes del evento toda transacción que tenga un estatus de Contracargo será cancelado de la cuenta del evento, Boletia y/o el Organizador no se harán responsables de dicha solicitud.</li>
            <li>b. Boletia no será responsable por Contracargos, por lo que regirán las reglas operativas correspondientes.</li>
            <li>c. Boletia tendrá la facultad de validar cualquier compra.</li>
            <li>d. En el supuesto donde el saldo de la cuenta del evento sea positivo a la fecha de terminación del mismo, Boletia podrá retener un porcentaje de seguridad el cual será entregado al Organizador en un plazo no mayor a 60 días naturales, menos los contracargos notificados por las instituciones financieras durante dicho periodo.</li>
          </ul>
          <h4>13.1 Responsabilidad en Contracargos.</h4>
          <p>El Organizador reconoce y acepta que Boletia tiene facultades para retener cualquier Saldo del boletaje vendido y disponible en la cuenta concentradora de Boletia por el monto total del Contracargo, o cualquier porcentaje de éste.</p>
          <h4>13.2 Prevención de Contracargos.</h4>
          <p>Boletia implementa sistemas anti-fraude en toda transacción con Tarjeta de Crédito o Débito, previo a la realización del evento Boletia podrá cancelar cualquier transacción unilateral que tenga inconsistencias en el pago o que exista una disputa de contracargo, sin embargo, Boletia no garantiza la ausencia de cargos fraudulentos, como consecuencia, se pudieran ocasionar Contracargos en los pagos.</p>
          <p>El Usuario acepta que si el 10% o más de las compras hechas para su evento, fueron realizadas con tarjetas con aviso de robo o extravío o bien con tarjetas que realizan contracargos, el evento se suspenda o cancele de la plataforma de Boletia, sin ninguna responsabilidad para Boletia.</p>
          <p>Boletia tendrá facultad de cerrar la venta de tarjeta hasta con 3 días de anticipación si así lo requiere</p>
          <h4>13.3. Veracidad.</h4>
          <p>Boletia no controla ni tiene injerencia en la existencia de Contracargos. Los únicos autorizados legalmente para emitir Contracargos son las Instituciones Bancarias, por lo tanto, El Organizador reconoce que cualquier notificación de Contracargo es emitida por Instituciones Bancarias, en caso de ser necesario Boletia proveerá al Organizador la documentación proporcionada por la instancia intermediaria que notificó a Boletia de dicho contracargo.</p>
          <h4>13.4 Retenciones de Seguridad.</h4>
          <p>En caso que se genere un Contracargo, el Organizador, reconoce y acepta que Boletia substraiga el monto correspondiente para cubrirlo.</p>
          <h4>13.5 Resolución</h4>
          <p>Boletia no tiene ningún tipo de autoridad ni participación en caso de Contracargo por lo que debe acatar cualquier resolución emitida por las Autoridades competentes. Boletia no se hace responsable por pérdidas ocasionadas al Organizador por fondos no recibidos por concepto de Contracargo.</p>
          <h3>DÉCIMO CUARTO. Impuestos.</h3>
          <p>Todos los impuestos que se generen por el uso de la Plataforma Digital serán cubiertos respectivamente por la Parte que dé pie al hecho generador. Boletia, para efectos de estos Términos y Condiciones, es un intermediario en la relación entre el Organizador y el Comprador por lo que, en el manejo de todos los fondos relativos a pagos efectuados por la compra de boletos, tendrá derecho de retener únicamente la cantidad que le corresponda por pago de comisiones, siendo esta su única contraprestación quedando el Organizador como obligado de pagar los impuestos correspondientes por la cantidad que le sea entregada.</p>
          <h3>DÉCIMO QUINTO. Cuenta, Contraseñas y seguridad</h3>
          <ul>
            <li>a. Para inscribirse como Usuario Registrado, este deberá proporcionar información real incluyendo: Nombre completo, teléfono, dirección, estado de cuenta (en caso de ser organizador) y correo electrónico. Dichos datos deberán coincidir con los datos con los cuales se dio de alta el Usuario.
              <ul>
                <li>I. Boletia enviará al correo electrónico proporcionado un correo de verificación de identidad. El Usuario deberá abrir dicho correo y seguir las instrucciones contenidas.</li>
                <li>II. El Usuario Registrado por este medio acepta y reconoce que cualquier notificación o contacto que Boletia necesite hacer con él, lo realizará mediante el correo electrónico proporcionado, por lo que Boletia se deslinda de cualquier responsabilidad derivada del mal uso de la cuenta de correo electrónico o la falta de seguimiento y revisión que el Usuario Registrado de la Cuenta de correo electrónico proporcionada.</li>
              </ul>
            </li>
            <li>b. Boletia asignará a los Usuarios Registrados un Nombre de Usuario, será responsabilidad del Usuario Registrado elegir su Contraseña.</li>
            <li>c. El Nombre de Usuario y Contraseña sirven de identificación en el sistema y son utilizados para autenticar la identidad del Usuario Registrado.</li>
            <li>d. Para efectos de estos Términos y Condiciones, el Usuario Registrado consiente utilizar su nombre de Usuario y contraseña como un equivalente a su firma autógrafa, por lo que, para todos los efectos, cada vez que el Usuario Registrado formalice la generación de un evento, o realice la compra de un boleto, mediante el uso de su nombre de Usuario y contraseña se tendrá como formalizado el modelo de trabajo elegido.</li>
            <li>e. El Usuario Registrado es responsable de todos los accesos a boletia.com con su Clave de Usuario y Contraseña por lo que cualquier acceso que se haga por cuenta del Usuario y Contraseña del Usuario Registrado se tendrá como efectuado por este sin perjuicio de que el acceso hubiera sido efectuado por un tercero que este autorizó.</li>
            <li>f. El Usuario Registrado es responsable de proteger y asegurar su Clave de Usuario y Contraseña. En caso de que el Usuario Registrado tenga conocimiento o crea que ha habido cualquier violación de seguridad, tal como el robo o uso no autorizado de su Clave de Usuario y Contraseña, o de cualquier información de su cuenta en Boletia deberá de notificarlo inmediatamente a Boletia.</li>
            <li>g. En caso de que Boletia considere pertinente manejar el acceso al evento con el esquema de seguridad pertinente, Boletia se reservará el derecho de acuerdo al contrato de Acceso de Taquillas que en su caso se celebrará con el Organizador.</li>
            <li>h. En caso en el que el evento sea realizado vía streaming y se aloje en alguna de las plataformas digitales que están bajo el dominio de Boletia, se deberá de firmar una contrato que regule la prestación del servicio correspondiente.</li>
          </ul>
          <h3>DÉCIMO SEXTO. Datos Personales.</h3>
          <p>Los datos provistos serán tratados de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, por lo anterior, al aceptar estos Términos y Condiciones aceptas nuestro Aviso de Privacidad: https://boletia.com/aviso-de-privacidad. La confidencialidad de los datos está garantizada y los mismos están protegidos por medidas de seguridad administrativas, técnicas y físicas, para evitar su daño, pérdida, alteración, destrucción, uso, acceso o divulgación indebida. Únicamente personas autorizadas por Boletia tendrán acceso a sus datos. Toda la información será resguardada en el domicilio mencionado de cada Parte y los datos estarán a cargo de los firmantes.</p>
          <h3>DÉCIMO SÉPTIMO. Subdominios asignados por Boletia.</h3>
          <ul>
            <li>a. Por cada evento generado, Boletia, asignará al Organizador un subdominio que siempre irá acompañado por boletia.com, el nombre del subdominio podrá ser elegido por el Organizador.</li>
            <li>b. El Organizador es el único responsable del contenido que incluya en el subdominio y libera a Boletia de cualquier responsabilidad por faltas e infracciones a las leyes; Boletia, a discreción podrá solicitar y/o modificar sin previo aviso, el contenido de los subdominios si estos fueran en contra de los Términos y Condiciones.</li>
            <li>c. El espacio asignado al Organizador, se mantendrá bajo la titularidad de Boletia y no puede ser transferido, vendido o cedido a ninguna persona ajena a estos Términos y Condiciones. No obstante, lo anterior, cualquier nombre, marca, aviso comercial, denominación o imagen propiedad del Organizador que se use en relación al subdominio se mantendrá como propiedad de éste.</li>
            <li>d. Boletia es una empresa que respeta los derechos de propiedad intelectual así como los valores, creencias y preferencias de las distintas personas; Será obligación de los Organizadores mantener sus eventos dentro del código de conducta de Boletia, aceptando que toda la información como lo puedan ser, textos, música, software, datos, fotografías, videos, sonidos mensajes que se utilicen en su subdominio, o para efectos de promoción de su evento son responsabilidad de ellos y mantendrán a salvo a Boletia de cualquier reclamo en relación al contenido.</li>
          </ul>
          <p>Expresamente Boletia prohíbe el uso de su Plataforma Digital a cualquier evento que tenga por objeto:</p>
          <ul>
            <li>i. Prácticas discriminatorias ya sea en razón de raza, creencia y/o preferencia sexual.</li>
            <li>ii. Dañe la integridad de los menores de edad.</li>
            <li>iii. Llevar a cabo prácticas ilegales.</li>
            <li>iv. Mal informar a los Compradores respecto a la fuente del evento mediante la falsificación de encabezados, imágenes y sonidos.</li>
            <li>v. Rifas, servicios relacionados con agencia de viajes, o cualquier actividad recaudatoria que no se encuentre dentro del mercado de un evento que se vaya a llevar a cabo.</li>
          </ul>
          <h3>DÉCIMO OCTAVO. Código de Conducta.</h3>
          <p>El Usuario acepta que está autorizado a utilizar la Plataforma Digital únicamente para los fines descritos en estos Términos y Condiciones. El Usuario acepta que es completamente responsable de sus actividades durante el uso de la Plataforma Digital y que se hará cargo de todos los riesgos con respecto al uso de la Plataforma Digital. El Usuario se compromete a no utilizar la Plataforma Digital para participar en cualquier conducta prohibida.</p>
          <ul>
            <li>i. Queda estrictamente prohibido acosar a cualquier Usuario, mediante spam, mensajes con contenido ofensivo, amenazas, sexismo, discriminación ya sea por cuestión de raza, género, lugar de procedencia.</li>
            <li>ii. Se prohíbe la difusión de información falsa o personificar o hacerse pasar por una persona distinta a la cual efectuó el registro de Usuario.</li>
            <li>iii. Se prohíbe cualquier conducta que incite a la violencia física, mental o emocional o a la comisión de algún delito o acto ilícito.</li>
            <li>iv. Se prohíbe la redistribución de anuncios publicados en otras páginas web, así como la publicación sin autorización, de material protegido por las leyes de  propiedad intelectual como lo son, marcas, derechos de autor y otra información y/o datos sin el previo consentimiento de los titulares.</li>
            <li>v. La información ingresada por un Organizador, no es responsabilidad de Boletia, siendo ellos los únicos responsables acerca de la veracidad por lo que se sugiere que nunca se provea información personal como lo son números de teléfono, direcciones, apellidos, URL’s o direcciones de correo electrónico que no sea donde se pidió explícitamente en el registro y/o la sección del perfil.</li>
            <li>vi. Queda prohibido enviar, introducir o ejecutar cualquier información, programa o secuencia que contenga virus, caballos de Troya, gusanos, bombas de tiempo, huevos de pascua u otras rutinas de programación informática que puedan dañar, interferir negativamente, interceptar o expropiar cualquier sistema, dato o información personal; Inyectar o introducir un enlace directo o indirectamente a cualquier otro sitio web salvo que sea explícitamente solicitado por algún formulario de Boletia.</li>
            <li>vii. Interferir o interrumpir el Servicio o la Plataforma Digital o los servidores o redes conectadas a los servicios, transmitir o introducir cualquier material que contenga virus de software o cualquier otro código informático, archivos o programas diseñados para interrumpir, destruir o limitar la funcionalidad de cualquier software o hardware o equipo de telecomunicaciones. Realizar una copia "marco" o "espejo" de cualquier parte del Servicio, o código sin nuestra autorización previa y por escrito; modificar, adaptar, sublicenciar, traducir, vender, realizar ingeniería inversa, descifrar, descompilar cualquier parte del Servicio, código o de cualquier software que se utiliza en la Plataforma Digital.</li>
            <li>viii. En general, Los Usuarios, no pueden utilizar ningún tipo de aparato, software o rutina, existente o por inventar, que interfiera o entorpezca con el funcionamiento adecuado de la Plataforma Digital y se obligan a no utilizar técnicas de ingeniería inversa, descompilación, o des ensamblaje, ni a modificar, traducir el SITIO WEB, toda vez que el mismo goza de la protección que las leyes y tratados de propiedad industrial e intelectual establecen.</li>
            <li>ix. Queda estrictamente prohibido hacer cualquier tipo de publicación con fines comerciales en el entendido de que Boletia presta un Servicio mas no es una página de clasificados en términos de la Descripción del Servicio.</li>
            <li>x. Los Usuarios, cooperarán con Boletia para denunciar a cualquier persona que incumpla el Código de Conducta. Boletia se reserva el derecho para denunciar ante las autoridades competentes cualquier conducta que ponga en riesgo la seguridad, salud y/o la vida de cualquier persona, Usuario o No Usuario.</li>
          </ul>
          <h3>DÉCIMO NOVENO. Derechos de Autor y Propiedad Intelectual.</h3>
          <p>El Contenido de la Plataforma Digital no puede ser copiado, reproducido, republicado, enviado, transmitido, transcrito, traducido, almacenado, alterado, descargado o distribuido en ningún medio inventado o por inventar, ni por ningún motivo, a excepción del material que Boletia expresamente provea al Usuario para este fin. Dicha información es propiedad de Boletia por lo que el Usuario deberá eliminarla de cualquier medio físico o electrónico una vez que haya cumplido con su uso. Boletia se compromete a cumplir con las leyes de Derechos de Autor y Propiedad Industrial que protegen la propiedad intelectual de terceros, así como los Tratados Internacionales de la Materia y requiere que todos los Usuarios cumplan con estas leyes. En consecuencia, el Usuario Registrado tiene prohibido almacenar, difundir y/o guardar cualquier material obtenido en el Sitio, de ninguna manera que constituya una infracción de los derechos de propiedad intelectual de Boletia y de terceros, incluidos los derechos otorgados por la Ley de Derecho de Autor de los Estados Unidos de América, así como los derechos otorgados por la Ley de Propiedad Industrial y Ley Federal de Derechos de Autor. Boletia se reserva el derecho de suspender el acceso a la Plataforma Digital a cualquier Usuario que haya infringido los derechos de autor o de propiedad intelectual de Boletia o de terceros y cualquier pago que el Usuario haya realizado no será reembolsado. Todo el contenido que se encuentre en la Plataforma Digital es propiedad de Boletia, a excepción de lo contenido en los Sub Dominios, y el Usuario tiene estrictamente prohibida su reproducción sin autorización expresa de Boletia.</p>
          <p>Lo anterior no será aplicable para eventos que se realicen por streaming, mismos que se regirán por el contrato que al efecto se celebre entre el Organizador y los Compradores.</p>
          <h3>VIGÉSIMO. Usos Internacionales.</h3>
          <p>Para todos los efectos cualquier uso que se le dé a la Plataforma Digital se tendrá por efectuado en territorio mexicano, cualquier uso fuera de la circunscripción territorial de la República Mexicana, será permitido, no obstante, se tendrá como efectuada en este territorio.</p>
          <h3>VIGÉSIMO PRIMERO. Modificación y Suspensión del Servicio.</h3>
          <ul>
            <li>a. Boletia se reserva el derecho a modificar en cualquier momento y sin previo aviso al Usuario los Contenidos, imagen, información, configuración y en general cualquier parte o aspecto relacionado directa o indirectamente con la Plataforma Digital.</li>
            <li>b. Boletia, sin previo aviso, podrá suspender el Servicio a los Organizadores que hubiesen violado los Términos y Condiciones.</li>
            <li>c. Boletia sin previo aviso podrá suspender el Servicio a los Organizadores para dar mantenimiento a la Plataforma Digital.</li>
            <li>d. Boletia sin previo aviso podrá adicionar y/o modificar los Términos y Condiciones de Servicio.</li>
            <li>e. Boletia, sin previo aviso, podrá cancelar eventos de la página o reservarse el derecho de prestar servicios a los Usuarios que hubiesen violado los Términos y Condiciones.</li>
          </ul>
          <h3>VIGÉSIMO SEGUNDO. Responsabilidad Limitada y exclusión de garantías.</h3>
          <p>Los Usuarios y Usuarios Registrados convienen en indemnizar y mantener en paz y a salvo a Boletia, sus empleados, funcionarios, consejeros y agentes, de y contra todas y cualesquiera reclamaciones, pérdidas, costos o gastos, impuestos incurridos por, determinados como resultado de, o relacionados con cualquier incumplimiento de su parte respecto de los Términos y Condiciones para el uso de la Plataforma Digital.</p>
          <p>La responsabilidad de Boletia se limita al monto de las comisiones pagadas por parte del Organizador.</p>
          <p>Boletia no será responsable por la cancelación de eventos por parte del Organizador, será responsabilidad del Organizador el efectuar la devolución del dinero previamente depositado de parte de Boletia al Organizador, para poder realizar los reembolsos correspondientes utilizando a Boletia como intermediario para tales efectos.</p>
          <p>El acceso a la Plataforma Digital no implica ningún tipo de garantía, que expresamente se rehúsa por Boletia, respecto a la calidad, veracidad, exactitud, licitud, actualidad o vigencia, así como la utilidad o adecuación a finalidad alguna de quien acceda a ella.</p>
          <p>Tanto el acceso a la Plataforma Digital como el uso que pueda hacerse de la información y contenidos incluidos en el mismo o que sea accesible desde el mismo, se efectúa bajo la exclusiva responsabilidad del Usuario.</p>
          <p>Boletia no responderá en ningún caso y en ninguna medida, ni por daños directos ni indirectos, ni por daño emergente ni por lucro cesante, por los eventuales perjuicios derivados del uso de la información y contenidos de la Plataforma Digital o accesibles desde o a través del mismo.</p>
          <p>Boletia NO garantiza en modo alguno la calidad, veracidad, exactitud, licitud, actualidad o vigencia, así como la utilidad o adecuación a finalidad alguna de los contenidos que integran la Plataforma Digital.</p>
          <p>Boletia excluye, con toda la extensión permitida por la Ley, cualquier responsabilidad de cualquier clase por la calidad, veracidad, exactitud, licitud, actualidad, vigencia o utilidad/adecuación a finalidad alguna que el Usuario pudiera haber atribuido a los contenidos que integran la Plataforma Digital.</p>
          <p>La Plataforma Digital podrá contener enlaces a páginas que, aún dentro de la Plataforma Digital se encuentran bajo responsabilidad de terceros. Asimismo, la Plataforma Digital podrá contener enlaces a páginas externas en todo caso al mismo. En ambos casos, Boletia no puede controlar o supervisar en modo alguno dicho contenido. Boletia excluye, con toda la extensión permitida por la Ley, cualquier responsabilidad de cualquier clase, derivada de la existencia o posibilidad de acceso a dichos contenidos.</p>
          <p>Asimismo, Boletia no será en ningún caso responsable, ni siquiera de forma indirecta o subsidiaria, por productos o servicios prestados u ofertados por otras personas o entidades, o por contenidos, informaciones, comunicaciones, opiniones o manifestaciones de cualquier tipo originados o vertidos por terceros y que resulten accesibles a través de la Plataforma Digital de Boletia.</p>
          <ul>
            <li>a) DEL SERVICIO OFRECIDO. La concertación de acuerdos entre Usuarios, Usuarios Registrados, Compradores y Organizadores dentro de la Plataforma Digitales es el resultado de acuerdos libres previos celebrados entre ellos, al ser mayores de edad, que actúan bajo su plena y sola responsabilidad. Los Usuarios, al utilizar la Plataforma Digital aceptan, reconocen y se comprometen a no iniciar acción legal en contra de Boletia, sus subsidiarias, accionistas, empleados, o cualquier persona física o moral que pertenezca al grupo por cualquier pérdida parcial o total de bienes materiales. Los Usuarios reconocen que Boletia se excluye expresamente de cualquier tipo de responsabilidad por cualesquiera consecuencias derivadas de los acuerdos celebrados entre los Usuarios, y reconocen que Boletia es solo una plataforma que ofrece a los Usuarios un servicio, mas no tiene responsabilidad alguna sobre el desarrollo, ejecución y perfección de dicho servicio. En el caso en que el evento se realice vía Streaming, el servicio por medio del cual Boletia realiza la transmisión del contenido, se regirá por contrato correspondiente.</li>
            <li>b) SEGURIDAD Y PRIVACIDAD: Boletia empleará razonablemente los medios a su alcance para proveer de sistemas de seguridad que protejan de forma razonable sus sistemas y los datos contenidos en los mismos contra ataques deliberados, software maligno, etc. No obstante, el Usuario entiende y acepta los aspectos que implica la prestación de servicios a través de Internet dado el carácter abierto, descentralizado y global de esta red de comunicaciones. Por este motivo Boletia NO garantiza la inexpugnabilidad de sus sistemas de seguridad ni la privacidad de la información alojada en los mismos. Boletia excluye, con toda la extensión permitida por la Ley, cualquier responsabilidad de cualquier clase por fallos de seguridad en la Plataforma Digital y las consecuencias que de ellos pudieran derivarse. Uso de ‘cookies’: La Plataforma Digital de Boletia emplea ‘cookies de sesión’ con finalidades publicitarias, así como para permitir y facilitar la interacción del Usuario con el mismo. Asimismo, los servicios de estadísticas –propios o ajenos-, empleados en la Plataforma Digital de Boletia podrán utilizar ‘cookies’ con la única finalidad de establecer métricas y patrones de uso de la Plataforma Digital. En ningún caso Boletia utiliza o accede a las ‘cookies’ para finalidades distintas de las enunciadas.</li>
            <li>c) INTERRUPCIONES Y/O SUSPENSIÓN DEL SISTEMA: Con carácter general los diferentes servicios estarán disponibles ininterrumpidamente en Internet. Sin embargo, el Usuario queda informado de que Boletia NO garantiza en modo alguno esta continuidad debido a la naturaleza del medio a través del que se presta. El Usuario entiende y acepta los aspectos que implica la prestación de servicios a través de Internet dado el carácter abierto, descentralizado y global de la red. Así, por ejemplo, a título enunciativo no exhaustivo, a continuación, se recogen algunas de las situaciones que pueden interrumpir temporalmente la prestación de dichos servicios: i. Tareas de mantenimiento en los servidores y/o líneas de datos ii. Averías en los servidores y/o líneas de datos iii. Ataques deliberados contra la seguridad e integridad de la Plataforma Digital iv. Virus informáticos o presencia de código maligno en las páginas que conforman o son accesibles desde la Plataforma Digital. Boletia excluye, con toda la extensión permitida por la Ley, cualquier responsabilidad de cualquier clase derivada de la interrupción temporal en la disponibilidad de la Plataforma Digital en la red Internet. El Usuario queda informado de que Boletia podrá en cualquier momento, a su solo criterio y sin obligación alguna de aviso previo, suspender con carácter definitivo la presencia de la Plataforma Digital en la red Internet y por tanto de los servicios prestados desde o a través del mismo, sin perjuicio de lo que se hubiere dispuesto al respecto en las correspondientes Condiciones Particulares. Boletia queda eximida de cualquier tipo de responsabilidad de cualquier clase derivada de las consecuencias que pudieran producirse por la suspensión definitiva en la prestación de dichos servicios.</li>
          </ul>
          <h3>VIGÉSIMO TERCERO. Propiedad Intelectual</h3>
          <ul>
            <li>a. Boletia y el Organizador se reconocen sus derechos de propiedad intelectual, estos Términos y Condiciones confieren una licencia de uso a los Organizadores para publicitar sus eventos e informar que sus boletos se pueden obtener por conducto de Boletia; la licencia que otorga Boletia al Organizador será únicamente durante la duración de estos Términos y Condiciones.</li>
            <li>b. El Organizador por este conducto otorga una licencia de uso a Boletia de todos los contenidos que publique en el subdominio, la licencia que otorga el Organizador a Boletia será únicamente durante la duración de éstos Términos y Condiciones.</li>
            <li>c. Boletia maneja políticas de propiedad intelectual en donde se prohíbe la distribución y/o publicación total o parcial de contenidos protegidos por las leyes de propiedad intelectual aplicables. Todos los Usuarios que acepten los Términos y Condiciones reciben una licencia, temporal y no exclusiva para utilizar la Plataforma Digital para los fines aquí descritos.</li>
          </ul>
          <h3>VIGÉSIMO CUARTO. Causales de Rescisión, Terminación y Penas.</h3>
          <ul>
            <li>a. Incumplimiento inherente a Boletia.
              <ul>
                <li>i. La falta de pericia en la realización de las tareas acordadas.</li>
                <li>ii. La falta de operatividad y funcionalidad de La Plataforma Digital por errores que no hayan sido subsanadas en un periodo superior a 3 días; para efectos de subsanar, será obligación del Organizador notificar la falla para que Boletia emita un informe respecto a las causas y tiempo estimado de reparación de la falla. Adicional al tiempo solicitado para reparación, Boletia contará con un periodo de 10 días al término solicitado.</li>
                <li>iii. El no entregar el remanente de los pagos después de comisiones al Organizador en el tiempo acordado.</li>
              </ul>
            </li>
            <li>b. Incumplimiento inherente a el Organizador
              <ul>
                <li>i. El que el Organizador no reembolse cantidad por cancelación de evento.</li>
                <li>ii. El proveer información falsa a Boletia.</li>
                <li>iii. El hacer uso de la Plataforma Digital para fines distintos a los contratados.</li>
                <li>iv. Incumplir alguna de las cláusulas de estos Términos y Condiciones.</li>
              </ul>
            </li>
            <li>c. No se considerará incumplimiento:
              <ul>
                <li>i. Cualquier falla no reportada por parte del Organizador a Boletia. Ante el incumplimiento, la Parte afectada deberá de solicitar a la Parte que incumplió que subsane las omisiones, si en un periodo superior a 10 días no se ha dado inicio con las tareas que para subsanar la falla el servicio se podrá dar por terminado.</li>
              </ul>
            </li>
          </ul>
          <p>En el caso de que exista una cancelación a un evento mayor de 100 personas, con menos de 30 días naturales contados de la cancelación al momento en que se deba de realizar el evento, el Organizador tendrá como pena convencional hacia Boletia la cantidad de $30,000.00 (TREINTA MIL PESOS 00/100 M.N.)</p>
          <h3>VIGÉSIMO QUINTO. Vigencia.</h3>
          <p>Las partes acuerdan que los Términos y Condiciones estarán vigentes a los 21 días de la creación del evento, plazo en el cual el personal de Boletia analizará la magnitud del evento y derivado de ello se decidirá si para el perfeccionamiento de los Términos y Condiciones es necesaria la firma de un contrato por ambas partes. La presente cláusula deberá ser entendida como una condición suspensiva para todos sus efectos.</p>
          <p>Las Partes acuerdan que estos Términos y Condiciones serán por tiempo indeterminado pudiendo cambiar sus Términos y Condiciones de conformidad a lo mencionado en estos Términos y Condiciones. Las Partes acuerdan que por mutuo consentimiento podrán terminar los Términos y Condiciones siempre y cuando se cubran los adeudos correspondientes.</p>
          <h3>VIGÉSIMO SEXTO. Notificaciones.</h3>
          <p>Todo aviso, solicitud, requerimiento, instrucción, consentimiento u otro tipo de comunicación dado o enviado en estos Términos y Condiciones, deberá de publicarse en la Plataforma Digital. Todos los avisos en relación al servicio prestado, Términos y Condiciones de uso deberán de ser enviados a hello@neerme.tv. En lo que se refiere a notificaciones a los Usuarios, Organizadores y Usuarios Registrados Boletia podrá notificarles en la cuenta de correo electrónico que hubieren designado (i) al hacer la compra, (ii) al crear un Usuario Registrado.</p>
          <h3>VIGÉSIMO SÉPTIMO. Modificación y Renuncias.</h3>
          <p>Los Términos y Condiciones podrán ser modificados por Boletia de forma unilateral y sin previo aviso.</p>
          <h3>VIGÉSIMO OCTAVO. Cesión.</h3>
          <p>Los Términos y Condiciones serán obligatorios para Boletia, Los Usuarios Registrados, Organizadores y Compradores (“las Partes”) Las Partes no podrán ceder total ni parcialmente sus derechos, obligaciones o intereses establecidos en los Términos y Condiciones sin consentimiento previo.</p>
          <h3>VIGÉSIMO NOVENO. Leyes Aplicables y Jurisdicción</h3>
          <p>El Servicio y la Plataforma Digital se sujetan a las leyes mexicanas en particular al Código Civil Federal. Las Partes sin importar sus domicilios presentes o futuros, por el simple hecho de aceptar estos Términos y Condiciones aceptan la jurisdicción y competencia de los tribunales del Distrito Federal.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermsConditions;
