export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);
export const isChromium = window.chrome;
export const winNav = window.navigator;
export const vendorName = winNav.vendor;
export const isOpera = typeof window.opr !== "undefined";
export const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
export const isIOSChrome = winNav.userAgent.match("CriOS");

export const isChrome =
  isChromium !== null &&
  typeof isChromium !== "undefined" &&
  vendorName === "Google Inc." &&
  isOpera === false &&
  isIEedge === false;