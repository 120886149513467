import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { useHistory, useParams } from "react-router-dom";

import { RebusContextProvider } from "contexts/rebusContext";

import useQuery from "hooks/useQuery";
import { getEventDetail } from "api/events";

import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";
import Loading from "components/shared/Loading";

import EventCountdown from "./EventCountdown";
import EventAccess from "./EventAccess";

const hideButtonEvents = [
  "purple-carpet-la-cotorrisa",
  "celulas-de-sucursal-2022",
];
const banorteCustomization = ["celulas-de-sucursal-2022"];

function EventPage(props) {
  const { t } = useTranslation(["event_site"]);
  const { eventSubdomain } = useParams();
  const showButton = !hideButtonEvents.includes(eventSubdomain);
  const isBanorteCustomization = banorteCustomization.includes(eventSubdomain);
  const history = useHistory();
  const query = useQuery();

  const [event, setEvent] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const rebus = query.get("rebus");
  const ConditionalRebusComponent =
    rebus === "true" ? RebusContextProvider : React.Fragment;

  // history.replace("/");
  useEffect(() => {
    let labels = ["detalle"];

    try {
      // eslint-disable-next-line
      window.zE("webWidget", "show");
      window.zE("webWidget", "helpCenter:setSuggestions", { labels });
    } catch (error) {
      console.log("Zendes undefined", error);
    }
    getEventDetail(eventSubdomain)
      .then((request) => {
        setEvent(request.data);
        setIsLoading(false);
      })
      .catch((error) => {
        history.replace("/");
      });
    // eslint-disable-next-line
  }, [eventSubdomain]);

  const {
    //event_id,
    event_name,
    //active,
    event_subdomain,
    start_date,
    start_time,
    end_date,
    end_time,
    event_banner,
    event_logo,
    //owner_id,
    //tennant_id,
    //users_online,
    //input_link,
    //output_link,
    //top_banner,
    is_time_to_access,
    sales_subdomain,
    left_time,
    time_zone,
  } = event;

  const dates = {
    start_date,
    start_time,
    end_date,
    end_time,
  };

  return (
    <ConditionalRebusComponent>
      <Helmet>
        <title>{event_name}</title>
        <meta
          name="description"
          content={`${t("meta.event_site")} ${event_name} ${t(
            "meta.event_site_2"
          )}`}
        />
        <meta
          name="og:description"
          content={`${t("meta.event_site")} ${event_name} ${t(
            "meta.event_site_2"
          )}`}
        />
        <meta
          property="og:title"
          content={`${event_name} ${t("meta.title")}`}
        />
        <meta property="og:url" content="http://neerme.tv" />
        <meta property="og:image" content={event_banner} />
      </Helmet>
      <Header stylePage={"is-gradient"} />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="c-layout">
          <figure className="c-event-page__banner">
            <div
              className="c-event-page__background"
              style={{ backgroundImage: `url(${event_banner})` }}
            ></div>
            <img src={event_banner} alt="" />
          </figure>
          <div className="c-event-page">
            <div className="c-event-page__content is-desktop">
              <figure className="c-event-page__icon">
                <img src={event_logo} alt="" />
              </figure>
              <div className="c-event-page__description">
                <div className="c-event-page__title">
                  {is_time_to_access && (
                    <div className="o-badge is-live">
                      <figure className="o-badge__live"></figure>
                      <span className="o-badge__text">
                        {t("event_site.live")}
                      </span>
                    </div>
                  )}
                  <h1>{event_name}</h1>
                </div>
                {!isBanorteCustomization && (
                  <div className="c-event-page__about">
                    <p>
                      {event_name} {t("event_site.about_1")}
                    </p>
                    <br />
                    <h3>{t("event_site.how_to")}</h3>
                    <ol>
                      {showButton && (
                        <>
                          <li>{t("event_site.about_2")}</li>
                          <li>{t("event_site.about_3")}</li>
                        </>
                      )}
                      <li>{t("event_site.about_4")}</li>
                    </ol>
                    {showButton && (
                      <p>
                        <strong>{t("event_site.disclaimer_title")} </strong>
                        {t("event_site.disclaimer")}
                      </p>
                    )}
                  </div>
                )}
                {isBanorteCustomization && (
                  <div className="c-event-page__about">
                    <p>
                      {event_name} {t("event_site.about_1")}
                    </p>
                    <br />
                    <h3>{t("event_site.how_to")}</h3>
                    <ol>
                      <li>
                        Tu código de acceso es tu número de empleado, ingresa
                        con este sin poner la leta "a".
                      </li>
                      <li>
                        Si tu número de empleado empieza con 0 (cero) elimínalo
                        y registra solo los números que le siguen al cero. (Ej.:
                        0016234 inicia con 16234).
                      </li>
                      <li>Tu número de acceso es personal e intransferible.</li>
                    </ol>
                    <p>
                      <strong>Importante: </strong> El código de acceso solo es
                      válido para una persona. En ningún momento será posible
                      que dos personas o más puedan usar el mismo código para
                      entrar.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="c-event-page__sidebar">
              {/* HIDE WHEN EVENT IS LIVE */}
              <EventCountdown
                dates={dates}
                showButton={showButton}
                sales_subdomain={sales_subdomain}
                left_time={left_time}
                time_zone={time_zone}
              />
              {/* END */}
              <EventAccess eventSubdomain={event_subdomain} />
            </div>
            <div className="c-event-page__content is-mobile">
              <div className="c-event-page__description">
                {!isBanorteCustomization && (
                  <div className="c-event-page__about">
                    <h3>{t("event_site.about_title")}</h3>

                    <br />
                    <h4>{t("event_site.how_to")}</h4>
                    <ol>
                      {showButton && (
                        <>
                          <li>{t("event_site.about_2")}</li>
                          <li>{t("event_site.about_3")}</li>
                        </>
                      )}
                      <li>{t("event_site.about_4")}</li>
                    </ol>
                    {showButton && (
                      <p>
                        <strong>{t("event_site.disclaimer_title")} </strong>
                        {t("event_site.disclaimer")}
                      </p>
                    )}
                  </div>
                )}
                {isBanorteCustomization && (
                  <div className="c-event-page__about">
                    <p>
                      {event_name} {t("event_site.about_1")}
                    </p>
                    <br />
                    <h3>{t("event_site.how_to")}</h3>
                    <ol>
                      <li>
                        Tu código de acceso es tu número de empleado, ingresa
                        con este sin poner la leta "a".
                      </li>
                      <li>
                        Si tu número de empleado empieza con 0 (cero) elimínalo
                        y registra solo los números que le siguen al cero. (Ej.:
                        0016234 inicia con 16234).
                      </li>
                      <li>Tu número de acceso es personal e intransferible.</li>
                    </ol>
                    <p>
                      <strong>Importante: </strong> El código de acceso solo es
                      válido para una persona. En ningún momento será posible
                      que dos personas o más puedan usar el mismo código para
                      entrar.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </ConditionalRebusComponent>
  );
}

export default EventPage;
