import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";
import Iframe from "react-iframe";

function RequestDemo(props) {
  const { i18n,t } = useTranslation("landing");

  return (
    <>
      <Header stylePage={"is-gradient"} />
      <div className="c-layout is-help">
        <section className="c-help is-demo">
          <div className="c-help__hero">
            <h1 className="c-landing-hero__title c-help__title">
              {t("get_demo.title")}
            </h1>
            <p className="c-home-hero__text">
              {t("get_demo.desc1")} {t("get_demo.desc2")}
            </p>
          </div>

          {i18n.language==="en" ?
            <Iframe 
              width="100%"
              height="100%" 
              title="Embedded Wufoo Form" 
              frameborder="0" 
              scrolling="yes" 
              className="inbound-iframe"
              url="https://boletia.wufoo.com/embed/w1s7fhoz1hzi91k/" 
              position="relative"
            />
            :
            <Iframe 
              width="100%"
              height="100%" 
              title="Embedded Wufoo Form" 
              frameborder="0" 
              scrolling="yes" 
              className="inbound-iframe"
              url="https://boletia.wufoo.com/forms/s1ajnn511kug5ok/" 
              position="relative"
            />
          }
          
          
        </section>
      </div>
      <Footer />
    </>
  );
}

export default RequestDemo;