import React from 'react'

const SVG = ({
  style = {},
  width = '100%',
  className = 'is-crown',
  viewBox = '0 0 24 18'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id='Crown'>
      <path d='M7,2 C7.553,2 8,2.447 8,3 C8,3.54176293 7.57098169,3.98178998 7.03357704,3.99944915 C7.37699095,4.0093459 7.71577317,4.1984116 7.895,4.5651 L7.895,4.5651 L9.013,6.8621 L7,10.9951 L4.646,7.5601 L6.105,4.5651 C6.28439916,4.19805901 6.62366071,4.00898247 6.96776205,3.99787038 L7,4 C6.447,4 6,3.553 6,3 C6,2.447 6.447,2 7,2 Z' id='Combined-Shape' fill='#F7941D'></path>
      <path d='M17,2 C17.553,2 18,2.447 18,3 C18,3.54185897 17.5708296,3.98194599 17.0332912,3.9994585 C17.3709551,4.01481034 17.7028233,4.20367519 17.879,4.5651 L17.879,4.5651 L19.345,7.5741 L17,10.9951 L14.979,6.8461 L16.09,4.5651 C16.2708872,4.1930032 16.6170377,4.00380736 16.9661027,3.99751249 L17,4 C16.447,4 16,3.553 16,3 C16,2.447 16.447,2 17,2 Z' id='Combined-Shape' fill='#F7941D'></path>
      <path d='M11.9997,1.9995 C12.3557,1.9995 12.7097,2.1885 12.8947,2.5665 L12.8947,2.5665 L16.9997,10.9955 L22.1807,3.4365 C22.7417,2.6185 23.9997,3.0235 23.9997,4.0215 L23.9997,4.0215 L23.9997,17.0035 C23.9997,17.5865 23.5017,18.0445 22.9197,17.9965 L22.9197,17.9965 L20.3047,17.7785 C14.7777,17.3185 9.2227,17.3185 3.6957,17.7785 L3.6957,17.7785 L1.0827,17.9965 C0.4997,18.0445 -0.0003,17.5845 -0.0003,16.9995 L-0.0003,16.9995 L-0.0003,4.0215 C-0.0003,3.0235 1.2587,2.6185 1.8197,3.4365 L1.8197,3.4365 L6.9997,10.9955 L11.1057,2.5665 C11.2897,2.1885 11.6447,1.9995 11.9997,1.9995 Z M23,1 C23.553,1 24,1.447 24,2 C24,2.553 23.553,3 23,3 C22.447,3 22,2.553 22,2 C22,1.447 22.447,1 23,1 Z M1,1 C1.553,1 2,1.447 2,2 C2,2.553 1.553,3 1,3 C0.447,3 -3.01980663e-13,2.553 -3.01980663e-13,2 C-3.01980663e-13,1.447 0.447,1 1,1 Z M11.9997,-0.0005 C12.5527,-0.0005 12.9997,0.4475 12.9997,0.9995 C12.9997,1.5515 12.5527,1.9995 11.9997,1.9995 L11.9997,1.9995 L11.8831315,1.99276822 C11.3859857,1.93496939 10.9997,1.51207143 10.9997,0.9995 C10.9997,0.4475 11.4477,-0.0005 11.9997,-0.0005 Z' id='Combined-Shape' fill='#FFBA44'></path>
      <path d='M21.7236,13.2764 C19.4916,13.1174 17.2456,13.0344 14.9986,12.9854 C14.9926,11.3374 13.6506,9.9984 11.9996,9.9984 C10.3496,9.9984 9.0076,11.3374 9.0006,12.9854 C6.7536,13.0344 4.5086,13.1174 2.2766,13.2764 L-0.0004,13.4384 L-0.0004,14.4414 L2.3466,14.2734 C4.6156,14.1124 6.8966,14.0284 9.1796,13.9814 C9.5896,15.1494 10.6926,15.9954 11.9996,15.9954 C13.3076,15.9954 14.4106,15.1494 14.8206,13.9814 C17.1036,14.0284 19.3846,14.1124 21.6536,14.2734 L23.9996,14.4414 L23.9996,13.4384 L21.7236,13.2764 Z' id='Fill-16' fill='#F7941D'></path>
      <path d='M12,11 C10.896,11 10,11.896 10,13 C10,14.104 10.896,15 12,15 C13.104,15 14,14.104 14,13 C14,11.896 13.104,11 12,11' id='Fill-19' fill='#E14C6C'></path>
    </g>
  </svg>
)

export default SVG
