import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollTrigger from '@terwanerik/scrolltrigger'
import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";

function WhyNeerme(props) {
  const { t } = useTranslation();
  useEffect(()=>{
    const trigger = new ScrollTrigger({
      trigger: {
        // If the trigger should just work one time
        once: true,
        offset: {
          element: {
            x: 0,
            y: (trigger, rect, direction) => {
              return 0.1
            }
          },
          viewport: {
            x: 0,
            y: (trigger, frame, direction) => {
              return trigger.visible ? 0 : 0.1
            }
          }
        }
      }
    })
    trigger.add('[data-slideleft]')
           .add('[data-slideright]')
           .add('[data-slidebottom]')
  },[])

  return (
    <>
      <Header stylePage={"is-gradient"} />
      <div className="c-landing c-whyneerme">
        <section className="c-landing-hero c-whyneerme-hero">
          <div className="c-landing-hero__wrapper c-whyneerme-hero__wrapper">
            <h1 className="c-landing-hero__title c-whyneerme-hero__title">
              {t("whyneerme.title1")} <br />
              {t("whyneerme.title2")}
            </h1>
            <p className="c-home-hero__text">{t("whyneerme.desc")}</p>
            <Link to="/request-demo" className="o-button--lg o-button--green">
              {t("home.main_cta")}
            </Link>
          </div>
        </section>
        <section className="c-whyneerme-image">
          <div className="c-whyneerme-image__wrapper">
            <img src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/WhyNeerme.png" alt="" width="100%"/>
          </div>
        </section>
        <section className="c-landing-slides">
          <article className="c-landing-slides__single">
            <div className="c-landing-slides__video" data-slideright>
              <video 
                muted 
                loop 
                autoPlay 
                playsInline
                poster="https://boletia-v2.s3.amazonaws.com/uploads/neerme/MarianaBo.jpg"
              >
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/MarianaBo.webm"
                  type="video/webm"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/MarianaBoLow.mp4"
                  type="video/mp4"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/MarianaBo.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="c-landing-slides__content" data-slideleft>
              <span className="c-landing-slides__emoji" role="img" aria-label="rocket">🚀</span>
              <h1 className="c-home__title">{t("whyneerme.why.title1")}</h1>
              <p className="c-home__text">{t("whyneerme.why.desc1")}</p>
            </div>
          </article>
          <article className="c-landing-slides__single">
            <div className="c-landing-slides__video" data-slideright>
              <video 
                muted 
                loop 
                autoPlay 
                playsInline
                poster="https://boletia-v2.s3.amazonaws.com/uploads/neerme/DrakeBell.jpg"
              >
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/DrakeBell.webm"
                  type="video/webm"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/DrakeBellLow.mp4"
                  type="video/mp4"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/DrakeBell.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="c-landing-slides__content" data-slideleft>
              <span className="c-landing-slides__emoji" role="img" aria-label="rocket">😎</span>
              <h1 className="c-home__title">{t("whyneerme.why.title2")}</h1>
              <p className="c-home__text">{t("whyneerme.why.desc2")}</p>
            </div>
          </article>
          <article className="c-landing-slides__single">
            <div className="c-landing-slides__video" data-slideright>
              <video 
                muted 
                loop 
                autoPlay 
                playsInline
                poster="https://boletia-v2.s3.amazonaws.com/uploads/neerme/VanGoghMonetize.jpg"
              >
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/VanGoghMonetize.webm"
                  type="video/webm"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/VanGoghMonetizeLow.mp4"
                  type="video/mp4"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/VanGoghMonetize.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="c-landing-slides__content" data-slideleft>
              <span className="c-landing-slides__emoji" role="img" aria-label="rocket">💰</span>
              <h1 className="c-home__title">{t("whyneerme.why.title3")}</h1>
              <ul className="c-landing-slides__list">
                <li>{t("whyneerme.why.list1")}</li>
                <li>{t("whyneerme.why.list2")}</li>
                <li>{t("whyneerme.why.list3")}</li>
                <li>{t("whyneerme.why.list4")}</li>
                <li>{t("whyneerme.why.list5")}</li>
              </ul>
            </div>
          </article>
        </section>
        <section className="c-whyneerme-benefits">
          <div className="c-whyneerme-benefits__wrapper" data-slidebottom>
            <h1 className="c-home__title">{t("whyneerme.benefits.title")}</h1>
            <p className="c-home__text">{t("whyneerme.benefits.text")}</p>

            <ul className="c-whyneerme-benefits__list">
              <li>
                <span className="c-whyneerme-benefits__emoji" role="img" aria-label="megaphone">📣</span>
                <h4>{t("whyneerme.benefits.title1")}</h4>
                <p>{t("whyneerme.benefits.desc1")}</p>
              </li>
              <li>
                <span className="c-whyneerme-benefits__emoji" role="img" aria-label="raised hands">🙌</span>
                <h4>{t("whyneerme.benefits.title2")}</h4>
                <p>{t("whyneerme.benefits.desc2")}</p>
              </li>
              <li>
                <span className="c-whyneerme-benefits__emoji" role="img" aria-label="raised hands">🎉</span>
                <h4>{t("whyneerme.benefits.title3")}</h4>
                <p>{t("whyneerme.benefits.desc3")}</p>
              </li>
              <li>
                <span className="c-whyneerme-benefits__emoji" role="img" aria-label="raised hands">🌎</span>
                <h4>{t("whyneerme.benefits.title4")}</h4>
                <p>{t("whyneerme.benefits.desc4")}</p>
              </li>
            </ul>
          </div>
        </section>
        <section className="c-landing-stories">
          <div className="c-landing-stories__wrapper" data-slideInRight>
            <div className="c-landing-stories__content">
              <h1 className="c-home__title">{t("whyneerme.steps.title")}</h1>
              <p className="c-home__text">{t("whyneerme.steps.desc")}</p>
              <a href="mailto:hello@neerme.tv" className="c-landing-stories__link o-button--green o-button--lg">
                Get a demo
              </a>
            </div>
          </div>
          <ul className="c-landing-stories__list">
            <li className="c-landing-stories__single" data-slideleft>
              <h3>1. {t("whyneerme.steps.type1")}</h3>
              <p>{t("whyneerme.steps.case1")}</p>
            </li>
            <li className="c-landing-stories__single" data-slideleft>
              <h3>2. {t("whyneerme.steps.type2")}</h3>
              <p>{t("whyneerme.steps.case2")}</p>
            </li>
            <li className="c-landing-stories__single" data-slideleft>
              <h3>3. {t("whyneerme.steps.type3")}</h3>
              <p>{t("whyneerme.steps.case3")}</p>
            </li>
            <li className="c-landing-stories__single" data-slideleft>
              <h3>4. {t("whyneerme.steps.type4")}</h3>
              <p>{t("whyneerme.steps.case4")}</p>
            </li>
          </ul>
        </section>
        <section className="c-whyneerme-features">
          <div className="c-whyneerme-features__wrapper">
            <h1 className="c-whyneerme-features__title c-home__title" data-slidebottom>
              {t("whyneerme.features.title")}
            </h1>
          </div>
          
          <div className="c-whyneerme-features__content">
            <ul className="c-whyneerme-features__list">
              <li>
                <span className="c-whyneerme-features__emoji" role="img" aria-label="raised hands">🎟</span>
                <h4>{t("whyneerme.features.type1")}</h4>
                <p>{t("whyneerme.features.desc1")}</p>
              </li>
              <li>
                <span className="c-whyneerme-features__emoji" role="img" aria-label="raised hands">💬</span>
                <h4>{t("whyneerme.features.type2")}</h4>
                <p>{t("whyneerme.features.desc2")}</p>
              </li>
              <li>
                <span className="c-whyneerme-features__emoji" role="img" aria-label="raised hands">🔒</span>
                <h4>{t("whyneerme.features.type3")}</h4>
                <p>{t("whyneerme.features.desc3")}</p>
              </li>
              <li>
                <span className="c-whyneerme-features__emoji" role="img" aria-label="raised hands">✍️</span>
                <h4>{t("whyneerme.features.type4")}</h4>
                <p>{t("whyneerme.features.desc4")}</p>
              </li>
              <li>
                <span className="c-whyneerme-features__emoji" role="img" aria-label="raised hands">💿</span>
                <h4>{t("whyneerme.features.type5")}</h4>
                <p>{t("whyneerme.features.desc5")}</p>
              </li>
              <li>
                <span className="c-whyneerme-features__emoji" role="img" aria-label="raised hands">💪</span>
                <h4>{t("whyneerme.features.type6")}</h4>
                <p>{t("whyneerme.features.desc6")}</p>
              </li>
              <li>
                <span className="c-whyneerme-features__emoji" role="img" aria-label="raised hands">🕵️‍♀️</span>
                <h4>{t("whyneerme.features.type7")}</h4>
                <p>{t("whyneerme.features.desc7")}</p>
              </li>
              <li>
                <span className="c-whyneerme-features__emoji" role="img" aria-label="monetization">😎</span>
                <h6 className="c-landing-stories__category">{t("whyneerme.features.soon")}</h6>
                <h4>{t("whyneerme.features.type8")}</h4>
                <p>{t("whyneerme.features.desc8")}</p>
              </li>
              
            </ul>
          </div>
          <div className="c-whyneerme-features__video">
            <video 
                muted 
                loop 
                autoPlay 
                playsInline
                poster="https://boletia-v2.s3.amazonaws.com/uploads/neerme/Solo.jpg"
              >
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/Solo.webm"
                  type="video/webm"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/SoloLow.mp4"
                  type="video/mp4"
                />
                <source
                  src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/Solo.mp4"
                  type="video/mp4"
                />
              </video>
          </div>
        </section>
        <section className="c-home-data">
          <ul className="c-home-data__wrapper">
            <li>
              <h2>100+</h2>
              <p>{t("home.data.desc1")}</p>
            </li>
            <li>
              <h2>200+</h2>
              <p>{t("home.data.desc2")}</p>
            </li>
            <li>
              <h2>250k</h2>
              <p>{t("home.data.desc3")}</p>
            </li>
            <li>
              <h2>96</h2>
              <p>{t("home.data.desc4")}</p>
            </li>
          </ul>
        </section>
        <section className="c-landing-magnet c-whyneerme-magnet">
          <div className="c-landing-magnet__wrapper" data-slideInBottom>
            <h2 className="c-landing-magnet__title">{t("home.magnet.title")}</h2>
            <p className="c-home__text">{t("home.magnet.desc")}</p>
            <Link to="/request-demo" className="o-button--green o-button--lg">
              {t("home.main_cta")}
            </Link>
          </div>  
        </section>
      </div>
      <Footer />
    </>
  );
}

export default WhyNeerme;
