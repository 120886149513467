import React from "react";

import { Link } from "react-router-dom";

function LiveCard(props) {
  const {
    noLink,
    isOwner,
    subdomain,
    src,
    name,
    is_live,
    start_day,
    start_month,
    time_start,
  } = props;
  const showLink = isOwner || noLink;
  const link = `/watch/${subdomain}`;
  const linkForceViewer = `/watch/${subdomain}?viewer=force`;
  return (
    <Link to={showLink ? `#` : link} className="c-live-card__container">
      <figure className="c-live-card__image">
        {src.length > 0 && <img src={src} alt={name} />}
      </figure>
      {is_live && (
        <div className="c-live-card__live">
          <figure className="o-badge__live"></figure>
          <span className="c-live-card__alert">¡Live!</span>
        </div>
      )}

      <div className="c-live-card">
        <div className="c-live-card__date">
          <span className="c-live-card__day">{start_day}</span>
          <span className="c-live-card__month">{start_month}</span>
        </div>
        <div className="c-live-card__detail">
          <h3 className="c-live-card__title">{name}</h3>
          <div className="c-live-card__hour">{time_start}hrs (CT)</div>
        </div>
      </div>
      {isOwner && (
        <div className="c-live-card__button">
          <Link
            to={linkForceViewer}
            className="o-button--sm o-button--outline is-light"
          >
            Espectador
          </Link>
          <Link to={link} className="o-button--sm o-button--outline is-light">
            Admin
          </Link>
        </div>
      )}
    </Link>
  );
}

export default LiveCard;
