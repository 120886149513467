import axios from "axios";

import {
  BASE_URL,
  POLL_LIST,
  POLL_CREATE,
  BASE_URL_POLLS,
  POLL_VOTE,
  POLL_SWITCH,
  POLL_DETAIL,
} from "../endpoints";

export async function getUserPolls(token, event_subdomain) {
  return axios.get(`${BASE_URL}${POLL_LIST(event_subdomain)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function createPoll(token, data) {
  return axios.post(`${BASE_URL}${POLL_CREATE}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export async function votePoll(pollID, data) {
  return axios.put(`${BASE_URL_POLLS}${POLL_VOTE(pollID)}`, data);
}
export async function switchPoll(pollID, data) {
  return axios.put(`${BASE_URL_POLLS}${POLL_SWITCH(pollID)}`, data);
}

export async function getPollDetail(pollID) {
  return axios.get(`${BASE_URL_POLLS}${POLL_DETAIL(pollID)}`);
}
