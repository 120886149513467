import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import MonthsAbEs from "consts/monthsAbbES";
import generateBoletiaURL from "utils/generateBoletiaURL";

import EventBuy from "../EventBuy";
import Loading from "components/shared/Loading";

function EventCountdown(props) {
  const { showButton, sales_subdomain, left_time, time_zone } = props;
  const { t } = useTranslation(["event_site"]);
  const { start_date, start_time } = props.dates || {};

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [showCountdown, setShowCountdown] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  function getDayFromData(date) {
    return date.split("-")[2];
  }
  function getMonthFromData(date) {
    return MonthsAbEs[parseInt(date.split("-")[1]) - 1];
  }

  const cleanStartTime = start_time.slice(0, 5);

  const cleanDate = `${getDayFromData(start_date)} ${getMonthFromData(
    start_date
  )}`;

  useEffect(() => {
    // const startDateTime = new Date(`${start_date}T${start_time}`).getTime();
    const nowDate = new Date();
    const startDateTime = nowDate.setSeconds(nowDate.getSeconds() + left_time);

    const interval = setInterval(() => {
      const nowTime = new Date().getTime();
      //now

      const distance = startDateTime - nowTime;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setCountdown({
        days,
        hours,
        minutes,
        seconds,
      });
      setIsLoading(false);

      if (distance < 0) {
        clearInterval(interval);
        setShowCountdown(false);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [left_time]);

  const { days, hours, minutes, seconds } = countdown;

  return (
    <>
      {isLoading ? (
        <Loading isSmall={true} />
      ) : showCountdown ? (
        <div className="c-event-page__block is-counter">
          <h4>{t("countdown.title")}</h4>
          <h2 className="c-event-page__time">
            {days}d {hours}h {minutes}m {seconds}s
          </h2>
          <div>
            <div>
              {cleanDate} - {cleanStartTime}hrs {time_zone}
            </div>
            {t("countdown.compare")}
            <a
              href="https://time.is/es/Mexico_City"
              target="_blank"
              rel="noopener noreferrer"
            >
              time.is
            </a>
          </div>
          {showButton && (
            <a
              href={generateBoletiaURL(sales_subdomain)}
              target="_blank"
              rel="noopener noreferrer"
              className="o-button--lg o-button--green"
            >
              {t("countdown.cta")}
            </a>
          )}
        </div>
      ) : (
        <EventBuy
          cleanDateTime={{ cleanStartTime, cleanDate }}
          showButton={showButton}
          sales_subdomain={sales_subdomain}
          time_zone={time_zone}
        />
      )}
    </>
  );
}

export default EventCountdown;
