import axios from "axios";

import { BASE_URL, USER_LOGIN, USER_LOGOUT } from "../endpoints";

export async function loginUser(data) {
  return axios.post(`${BASE_URL}${USER_LOGIN}`, data);
}

export async function logOut(token, data) {
  return axios.post(`${BASE_URL}${USER_LOGOUT}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
