import React, { useEffect, useState, useReducer } from "react";

import { useTranslation } from "react-i18next";
import mailgo from "mailgo";

import { tryDataLayerPush } from "utils/gtm";
import IconLoading from "components/shared/icon/loading";
import IconCheck from "components/shared/icon/check_filled";
import IconClose from "components/shared/icon/error";
import { useWatchEventContext } from "contexts/WatchEventContext";
import { useChatWebsocketContext } from "contexts/chatWebsocket";
import { useEventComponentsContext } from "contexts/EventComponentsContext";

import reducer, { initialXsollaOrderStatus } from "./store/reducer";
import { setXsollaOrderStatus, getXsollaOrderStatus } from "./store/actions";

const ACTION_PURCHASE_NOTIFICATION = "purchaseNotification";

const ALERT_TYPE = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  PENDING: "PENDING",
};

const ALERT_CLASS = {
  SUCCESS: "is-success",
  ERROR: "is-error",
  PENDING: "is-warning",
};

function PaymentAlert(props) {
  const [notification, setNotification] = useState({
    show: false,
    type: ALERT_TYPE.ERROR,
  });
  const { accessCode } = useWatchEventContext();
  const { lastJsonMessage: lastMessage } = useChatWebsocketContext();
  const { loadComponents } = useEventComponentsContext();

  const [xsollaOrderStatus, dispatch] = useReducer(
    reducer,
    initialXsollaOrderStatus
  );

  function handlePendingXsollaEvent(event) {
    const { tokenXsolla, xsolla } = event.detail;
    if (xsolla) {
      const payload = {
        token: tokenXsolla,
      };
      setXsollaOrderStatus({ payload }, dispatch);
    }
    setNotification({
      show: true,
      type: ALERT_TYPE.PENDING,
    });
  }

  function handeCloseSuccess(event) {
    event.preventDefault();
    setNotification((prev) => ({
      ...prev,
      show: false,
    }));
  }

  function handleXsollaMessageStatus(data) {
    const { status } = data;
    if (status === "completed") {
      loadComponents();
      setNotification({
        show: true,
        type: ALERT_TYPE.SUCCESS,
      });
      tryDataLayerPush({
        event: "finish_order_stage",
      });
    } else {
      setNotification({
        show: true,
        type: ALERT_TYPE.ERROR,
      });
    }
  }

  useEffect(() => {
    mailgo();
    window.addEventListener("xsollaPaymentCheck", handlePendingXsollaEvent);
  }, []);

  useEffect(() => {
    getXsollaOrderStatus({ token: accessCode }, dispatch);
  }, [accessCode]);

  useEffect(() => {
    if (
      xsollaOrderStatus.getXsollaOrderStatus.success &&
      xsollaOrderStatus.getXsollaOrderStatus.data.length > 0
    ) {
      setNotification({
        show: true,
        type: ALERT_TYPE.PENDING,
      });
    }
    if (xsollaOrderStatus.getXsollaOrderStatus.error) {
      setNotification({
        show: true,
        type: ALERT_TYPE.ERROR,
      });
    }
  }, [xsollaOrderStatus.getXsollaOrderStatus]);

  useEffect(() => {
    if (xsollaOrderStatus.setXsollaOrderStatus.error) {
      setNotification({
        show: true,
        type: ALERT_TYPE.ERROR,
      });
    }
  }, [xsollaOrderStatus.setXsollaOrderStatus]);

  useEffect(() => {
    if (lastMessage !== null) {
      const { action, data } = lastMessage;
      switch (action) {
        case ACTION_PURCHASE_NOTIFICATION:
          handleXsollaMessageStatus(data);
          break;

        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  const { t } = useTranslation(["watch"]);

  return (
    notification.show && (
      <>
        <div
          className={`c-notification__wrapper ${
            ALERT_CLASS[notification.type]
          }`}
        >
          {notification.type === ALERT_TYPE.SUCCESS && (
            <>
              <div className="c-notification">
                <figure className="c-notification__icon">
                  <IconCheck width={14} />
                </figure>
                <span className="c-notification__text">
                  {t("payment_alert.success.title")}
                </span>
              </div>
              <a
                href="/"
                onClick={handeCloseSuccess}
                className="o-button--xs o-button--outline is-dark"
              >
                {t("payment_alert.success.button")}
              </a>
            </>
          )}
          {notification.type === ALERT_TYPE.ERROR && (
            <>
              <div className="c-notification">
                <span className="c-notification__text">
                  {t("payment_alert.error.title")}
                </span>
              </div>
              <a
                href="#mailgo"
                data-address="hello"
                data-domain="neerme.tv"
                className="dark o-button--xs o-button--outline is-light"
              >
                {t("payment_alert.error.button")}
              </a>
              <a
                href="/"
                className="c-notification__close"
                onClick={handeCloseSuccess}
              >
                <IconClose width={16} />
              </a>
            </>
          )}
          {notification.type === ALERT_TYPE.PENDING && (
            <>
              <figure className="c-notification__icon">
                <IconLoading width={16} />
              </figure>
              <span className="c-notification__text">
                {t("payment_alert.pending.title")}
              </span>
            </>
          )}
        </div>
      </>
    )
  );
}

export default PaymentAlert;
