import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  className = 'is-check',
  viewBox = '0 0 24 16'
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >

    <g id="Artboard" fill={fill}>
      <path d='M4.1486,0.279 C4.5746,0.628 4.6346,1.255 4.2836,1.679 C2.8106,3.451 1.9996,5.695 1.9996,8 C1.9996,10.325 2.8256,12.588 4.3236,14.368 C4.6766,14.79 4.6206,15.417 4.1976,15.769 C4.0096,15.924 3.7826,16 3.5566,16 C3.2706,16 2.9866,15.879 2.7876,15.644 C0.9906,13.506 -0.0004,10.791 -0.0004,8 C-0.0004,5.235 0.9736,2.541 2.7406,0.413 C3.0916,-0.011 3.7206,-0.07 4.1486,0.279 Z M21.2123,0.3553 C23.0093,2.4943 24.0003,5.2083 24.0003,8.0003 C24.0003,10.7633 23.0263,13.4583 21.2593,15.5873 C21.0613,15.8243 20.7753,15.9473 20.4873,15.9473 C20.2633,15.9473 20.0383,15.8733 19.8513,15.7203 C19.4253,15.3713 19.3653,14.7443 19.7163,14.3213 C21.1893,12.5493 22.0003,10.3033 22.0003,8.0003 C22.0003,5.6743 21.1743,3.4123 19.6763,1.6313 C19.3233,1.2103 19.3793,0.5833 19.8023,0.2303 C20.2283,-0.1207 20.8583,-0.0637 21.2123,0.3553 Z M7.6883,3.7114 C8.1383,4.0314 8.2433,4.6554 7.9233,5.1054 C7.3193,5.9534 7.0003,6.9544 7.0003,8.0004 C7.0003,9.0484 7.3203,10.0524 7.9263,10.9014 C8.2483,11.3504 8.1433,11.9744 7.6943,12.2954 C7.5183,12.4214 7.3153,12.4824 7.1143,12.4824 C6.8023,12.4824 6.4953,12.3364 6.3003,12.0634 C5.4493,10.8744 5.0003,9.4684 5.0003,8.0004 C5.0003,6.5364 5.4473,5.1334 6.2943,3.9454 C6.6143,3.4944 7.2383,3.3904 7.6883,3.7114 Z M17.7006,3.9376 C18.5506,5.1266 18.9996,6.5326 18.9996,7.9996 C18.9996,9.4636 18.5526,10.8666 17.7056,12.0546 C17.5106,12.3286 17.2026,12.4746 16.8906,12.4746 C16.6906,12.4746 16.4876,12.4136 16.3116,12.2886 C15.8616,11.9686 15.7566,11.3446 16.0776,10.8946 C16.6806,10.0466 16.9996,9.0456 16.9996,7.9996 C16.9996,6.9516 16.6796,5.9486 16.0736,5.0996 C15.7516,4.6506 15.8566,4.0266 16.3056,3.7046 C16.7536,3.3836 17.3786,3.4886 17.7006,3.9376 Z M12,5.5 C13.381,5.5 14.5,6.619 14.5,8 C14.5,9.381 13.381,10.5 12,10.5 C10.619,10.5 9.5,9.381 9.5,8 C9.5,6.619 10.619,5.5 12,5.5 Z'></path>
    </g>
  </svg>
)

export default SVG
