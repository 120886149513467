import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import IconFacebook from "../../shared/icon/facebook";
import IconTwitter from "../../shared/icon/twitter";
import IconInstagram from "../../shared/icon/instagram";
import IconNeerme from "../../shared/icon/neerme_icon";

function Footer(props) {
  const { t } = useTranslation();

  return (
    <>
      <footer className="c-footer__wrapper">
        <div className="c-footer is-first">
          <div className="c-footer__logo">
            <IconNeerme width={40} />
            {/*<h6>{t ("title")}</h6>*/}
          </div>
          <ul className="c-footer__links">
            <li>
              <Link to="/why-neerme">
                {t ("menu.why")}
              </Link>
            </li>
            <li>
              <Link to="/whos-it-for/artist-managers">
                {t ("menu.whositfor")}
              </Link>
            </li>
            <li>
              <Link to="/case-studies/comedy">
                <span>{t ("menu.case_stories")}</span>
              </Link>
            </li>
            <li>
              <Link to="/about">
                {t ("menu.about")}
              </Link>
            </li>
            <li>
              <Link to="/event-types">
                {t("menu.event_types")}
              </Link>
            </li>
            <li>
              <Link to="/event-list">
                {t ("menu.events")}
              </Link>
            </li>
            <li>
              <Link to="/help">
                <span>{t ("menu.help")}</span>
              </Link>
            </li>
          </ul>
        </div>
        
        <div className="c-footer is-last">
          <div className="c-footer__legal">
            {t ("menu.copyright")}
            <br />
            <Link to="/codigo-de-conducta">
              {t ("menu.code_conduct")}
            </Link>
            <Link to="/terminos-y-condiciones">
              {t ("menu.terms_conditions")}
            </Link>
            <Link to="/terminos-de-compra">
              {t ("menu.terms_purchase")}
            </Link>
          </div>
          <ul className="c-footer__social">
            <li>
              <a 
                href="mailto:hello@neerme.tv" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                hello@neerme.tv</a>
            </li>
            <li>
              <a 
                href="https://facebook.com/neerme.tv" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <IconFacebook width={20} /></a>
            </li>
            <li>
              <a 
                href="https://instagram.com/neerme.tv" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <IconInstagram width={20} />
              </a>
            </li>
            <li>
              <a 
                href="https://twitter.com/NeermeTV" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <IconTwitter width={20} />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
}

export default Footer;
