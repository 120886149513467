import styled from "styled-components";

const LiveInfoStyled = styled.div`
  ${(props) => {
    if (props.theme.titleEvent.background.active) {
      return `background-image: url(${props.theme.titleEvent.background.src});
      background-size: cover;
      background-repeat: no-repeat;`;
    } else {
      return `background: ${props.theme.rootStyle.colors.body};`;
    }
  }}
  color: ${(props) => props.theme.rootStyle.colors.text}!important;
`;
export default LiveInfoStyled;
