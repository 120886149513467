import {
  GET_XSOLLA_ORDER_STATUS_REQUESTED,
  GET_XSOLLA_ORDER_STATUS_FULFILLED,
  GET_XSOLLA_ORDER_STATUS_REJECTED,
  SET_XSOLLA_ORDER_STATUS_REQUESTED,
  SET_XSOLLA_ORDER_STATUS_FULFILLED,
  SET_XSOLLA_ORDER_STATUS_REJECTED,
} from "./types";
import {
  getXsollaOrderStatus as getXsollaOrderStatusAPI,
  setXsollaOrderStatus as setXsollaOrderStatusAPIgetXsollaOrderStatusAPI,
} from "api/orders";
export function getXsollaOrderStatus({ token }, dispatch) {
  dispatch(getXsollaOrderStatusRequested());
  getXsollaOrderStatusAPI(token)
    .then((response) => {
      const data = response.data;
      dispatch(getXsollaOrderStatusFulfilled(data));
    })
    .catch((error) => {
      dispatch(getXsollaOrderStatusRejected(error));
    });
}

function getXsollaOrderStatusRequested() {
  return {
    type: GET_XSOLLA_ORDER_STATUS_REQUESTED,
    getXsollaOrderStatus: {
      loading: true,
      success: false,
      error: false,
      data: [],
    },
  };
}
function getXsollaOrderStatusFulfilled(data) {
  return {
    type: GET_XSOLLA_ORDER_STATUS_FULFILLED,
    getXsollaOrderStatus: {
      loading: false,
      success: true,
      error: false,
      data: data,
    },
  };
}
function getXsollaOrderStatusRejected(error) {
  return {
    type: GET_XSOLLA_ORDER_STATUS_REJECTED,
    getXsollaOrderStatus: {
      loading: false,
      success: false,
      error,
    },
  };
}
export function setXsollaOrderStatus({ payload }, dispatch) {
  dispatch(setXsollaOrderStatusRequested());
  setXsollaOrderStatusAPIgetXsollaOrderStatusAPI(payload)
    .then((response) => {
      const data = response.data;
      dispatch(setXsollaOrderStatusFulfilled(data));
    })
    .catch((error) => {
      dispatch(setXsollaOrderStatusRejected(error));
    });
}

function setXsollaOrderStatusRequested() {
  return {
    type: SET_XSOLLA_ORDER_STATUS_REQUESTED,
    setXsollaOrderStatus: {
      loading: true,
      success: false,
      error: false,
      data: [],
    },
  };
}
function setXsollaOrderStatusFulfilled(data) {
  return {
    type: SET_XSOLLA_ORDER_STATUS_FULFILLED,
    setXsollaOrderStatus: {
      loading: false,
      success: true,
      error: false,
      data: data,
    },
  };
}
function setXsollaOrderStatusRejected(error) {
  return {
    type: SET_XSOLLA_ORDER_STATUS_REJECTED,
    setXsollaOrderStatus: {
      loading: false,
      success: false,
      error,
    },
  };
}
