import React from 'react'

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  height = '100%',
  className = 'is-logout',
  viewBox = '0 0 24 24'
}) => (
  <svg
    width={width}
    style={style}
    viewBox={viewBox}
    xmlns='http://www.w3.org/2000/svg'
    className={`c-icon ${className || ''}`}
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <path fill={fill} d='M13,0 C14.654,0 16,1.307 16,2.913 L16,2.913 L16,7 C16,7.552 15.552,8 15,8 C14.448,8 14,7.552 14,7 L14,7 L14,2.913 C14,2.41 13.551,2 13,2 L13,2 L3,2 C2.449,2 2,2.41 2,2.913 L2,2.913 L2,21.087 C2,21.591 2.449,22 3,22 L3,22 L13,22 C13.551,22 14,21.591 14,21.087 L14,21.087 L14,17 C14,16.447 14.448,16 15,16 C15.552,16 16,16.447 16,17 L16,17 L16,21.087 C16,22.693 14.654,24 13,24 L13,24 L3,24 C1.346,24 1.77635684e-14,22.693 1.77635684e-14,21.087 L1.77635684e-14,21.087 L1.77635684e-14,2.913 C1.77635684e-14,1.307 1.346,0 3,0 L3,0 Z M18.7539,7.7962 C19.1419,7.4042 19.7759,7.4002 20.1679,7.7892 L20.1679,7.7892 L23.7039,11.2892 C23.7039,11.2902 23.7039,11.2912 23.7049,11.2912 C23.7969,11.3832 23.8709,11.4922 23.9219,11.6142 C23.9729,11.7352 23.9999,11.8652 23.9999,12.0002 C23.9999,12.1342 23.9729,12.2652 23.9219,12.3862 C23.8709,12.5082 23.7969,12.6172 23.7049,12.7082 C23.7039,12.7092 23.7039,12.7102 23.7039,12.7102 L23.7039,12.7102 L20.1679,16.2112 C19.9729,16.4032 19.7189,16.5002 19.4639,16.5002 C19.2069,16.5002 18.9489,16.4012 18.7539,16.2032 C18.3649,15.8102 18.3679,15.1782 18.7609,14.7892 L18.7609,14.7892 L20.5679,13.0002 L12.3969,13.0002 C11.8449,13.0002 11.3969,12.5522 11.3969,12.0002 C11.3969,11.4482 11.8449,11.0002 12.3969,11.0002 L12.3969,11.0002 L20.5679,11.0002 L18.7609,9.2102 C18.3679,8.8222 18.3649,8.1892 18.7539,7.7962 Z' id='Fill-1' />
  </svg>
)

export default SVG
