import React from "react";

import { WatchEventContextProvider } from "contexts/WatchEventContext";
import { EventComponentsContextProvider } from "contexts/EventComponentsContext";
import { LevelStoreContextProvider } from "contexts/LevelStoreContext";
import { ThemeContextProvider } from "contexts/ThemeContext";
import Livestream from "./Livestream";

function Watch(props) {
  React.useEffect(() => {
    try {
      // eslint-disable-next-line
      zE('messenger:set', 'zIndex', -999);
    } catch (error) {
      console.error("Zendes undefined", error);
    }
  }, []);

  return (
    <WatchEventContextProvider>
      <EventComponentsContextProvider>
        <LevelStoreContextProvider>
          <ThemeContextProvider>
            <Livestream />
          </ThemeContextProvider>
        </LevelStoreContextProvider>
      </EventComponentsContextProvider>
    </WatchEventContextProvider>
  );
}

export default Watch;
