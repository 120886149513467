import React from 'react';

const SVG = ({
  style = {},
  fill = 'currentColor',
  width = '100%',
  height = '100%',
  className = 'is-crown-filled',
  viewBox = '0 0 48 36'
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`c-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >

    <path fill={fill} d='M42.9018256,29.9361574 L41.9558143,34.3562101 C41.757812,35.2782211 40.9438023,35.936229 39.999791,35.936229 L39.999791,35.936229 L7.99940899,35.936229 C7.05539772,35.936229 6.24138801,35.2782211 6.04538567,34.3562101 L6.04538567,34.3562101 L5.09737435,29.9361574 L42.9018256,29.9361574 Z M22.3049798,0.876010457 C23.0349885,-0.292003486 24.9650115,-0.292003486 25.6950202,0.876010457 L25.6950202,0.876010457 L34.3631237,14.744176 L44.5852457,4.52205398 C45.2132532,3.89404649 46.1772647,3.75804486 46.955274,4.17804988 C47.7352833,4.60205494 48.1412882,5.48806551 47.955286,6.35607588 L47.955286,6.35607588 L43.7592359,25.9363096 L4.24076412,25.9363096 L0.044714033,6.35607588 C-0.141288187,5.48806551 0.264716659,4.60205494 1.04472597,4.17804988 C1.82473528,3.76004489 2.78674677,3.89404649 3.41275424,4.52205398 L3.41275424,4.52205398 L13.6368763,14.744176 Z'/>
  </svg>
)

export default SVG
