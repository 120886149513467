import React from "react";

import { useTranslation } from "react-i18next";
import { tryDataLayerPush } from "utils/gtm";

import { useLevelStoreContext } from "contexts/LevelStoreContext";

import { useWatchEventContext } from "contexts/WatchEventContext";

import IconCrownFilled from "components/shared/icon/crown_filled";

function LevelUpBadge(props) {
  const { t } = useTranslation(["watch"]);
  const { avatarEvent } = props;
  const { showLevels } = useLevelStoreContext();
  const { eventData } = useWatchEventContext();
  function handleShowLevels(event) {
    event.preventDefault();
    showLevels();
    tryDataLayerPush({
      event: avatarEvent
        ? "click_levelup_avatar"
        : "level-up-header-button-click",
    });
  }
  return (
    <>
      {eventData.marketable && (
        <div className="c-levelup">
          <a
            className="o-button--bs o-button--outline"
            href="/"
            onClick={handleShowLevels}
          >
            <IconCrownFilled width={16} />
            <span className="c-levelup__span">{t("livestream.levelup")}</span>
          </a>
        </div>
      )}
    </>
  );
}

export default LevelUpBadge;
