import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";

const STRIPE_TOKEN = process.env.REACT_APP_STRIPE_TOKEN;

const promise = loadStripe(STRIPE_TOKEN);

function StripeProcess(props) {
  return (
    <Elements stripe={promise}>
      <CheckoutForm />
    </Elements>
  );
}

export default StripeProcess;
