export const USER_BAN_CHECK_REQUESTED = "USER_BAN_CHECK_REQUESTED";
export const USER_BAN_CHECK_FULFILLED = "USER_BAN_CHECK_FULFILLED";
export const USER_BAN_CHECK_REJECTED = "USER_BAN_CHECK_REJECTED";

export const GET_USER_EVENTS_REQUESTED = "GET_USER_EVENTS_REQUESTED";
export const GET_USER_EVENTS_FULFILLED = "GET_USER_EVENTS_FULFILLED";
export const GET_USER_EVENTS_REJECTED = "GET_USER_EVENTS_REJECTED";

export const GET_USER_EVENT_OWNERSHIP_REQUESTED =
  "GET_USER_EVENT_OWNERSHIP_REQUESTED";
export const GET_USER_EVENT_OWNERSHIP_FULFILLED =
  "GET_USER_EVENT_OWNERSHIP_FULFILLED";
export const GET_USER_EVENT_OWNERSHIP_REJECTED =
  "GET_USER_EVENT_OWNERSHIP_REJECTED";

export const GET_EVENT_DETAIL_REQUESTED = "GET_EVENT_DETAIL_REQUESTED";
export const GET_EVENT_DETAIL_FULFILLED = "GET_EVENT_DETAIL_FULFILLED";
export const GET_EVENT_DETAIL_REJECTED = "GET_EVENT_DETAIL_REJECTED";

export const VALIDATE_ACCESS_CODE_REQUESTED = "VALIDATE_ACCESS_CODE_REQUESTED";
export const VALIDATE_ACCESS_CODE_FULFILLED = "VALIDATE_ACCESS_CODE_FULFILLED";
export const VALIDATE_ACCESS_CODE_REJECTED = "VALIDATE_ACCESS_CODE_REJECTED";

export const GET_TICKET_DATA_REQUESTED = "GET_TICKET_DATA_REQUESTED";
export const GET_TICKET_DATA_FULFILLED = "GET_TICKET_DATA_FULFILLED";
export const GET_TICKET_DATA_REJECTED = "GET_TICKET_DATA_REJECTED";

export const VALIDATE_CHAT_BLOCKED = "VALIDATE_CHAT_BLOCKED";
