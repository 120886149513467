import {
  SET_NICKNAME_BY_TOKEN_REQUESTED,
  SET_NICKNAME_BY_TOKEN_FULFILLED,
  SET_NICKNAME_BY_TOKEN_REJECTED,
} from "./types";

import { setNicknameByToken as setNicknameByTokenAPI } from "api/events";

export function setNicknameByToken({ token, payload }, dispatch) {
  dispatch(setNicknameByTokenRequested());
  setNicknameByTokenAPI(token, payload)
    .then((response) => {
      const data = response.data;
      dispatch(setNicknameByTokenFulfilled(data));
    })
    .catch((error) => {
      dispatch(setNicknameByTokenRejected(error));
    });
}

function setNicknameByTokenRequested() {
  return {
    type: SET_NICKNAME_BY_TOKEN_REQUESTED,
    nicknameByToken: {
      loading: true,
      success: false,
      error: false,
      data: [],
    },
  };
}
function setNicknameByTokenFulfilled(data) {
  return {
    type: SET_NICKNAME_BY_TOKEN_FULFILLED,
    nicknameByToken: {
      loading: false,
      success: true,
      error: false,
      data,
    },
  };
}
function setNicknameByTokenRejected(error) {
  console.error("couldnt set nickname");
  return {
    type: SET_NICKNAME_BY_TOKEN_REJECTED,
    nicknameByToken: {
      loading: false,
      success: false,
      error,
      data: [],
    },
  };
}
