import React, { createContext, useContext, useState, useEffect } from "react";

import Cookies from "js-cookie";

import { getUserData } from "api/user";
// import getRandomArbitraryNum from "utils/getRandomArbitraryNum";

export const AuthContext = createContext();

export function AuthContextProvider(props) {
  const { children } = props;

  const [authToken, setAuthToken] = useState(Cookies.get("token") || "");
  const [userData, setUserData] = useState({});
  const [chatUsername, setChatUsername] = useState(false
  );
  const [chatAvatar, setChatAvatar] = useState(false
  );

  function handleToken(token = undefined) {
    if (!token) {
      Cookies.remove("token");
    } else {
      Cookies.set("token", token);
    }
    setAuthToken(token);
  }

  function getUserID() {
    return String(userData.id);
  }

  function handleChatUsername(chatUsername) {
    if (chatUsername) {
      const id = getUserID();
      const curatedChatUsername = `${chatUsername}#${id}`;
      setChatUsername(curatedChatUsername);
    } else {
      setChatUsername(false);
    }
  }

  function handleChatAvatar(avatar) {
    if (avatar) {
      setChatAvatar(avatar);
    } else {
      setChatAvatar(false);
    }
  }

  function logOut() {
    handleChatUsername(false);
    handleToken();
  }

  function requestUserData() {
    return getUserData(authToken);
  }

  function generateID() {
    // const randomID = getRandomArbitraryNum(100000, 999999);
    const epoch = Date.now().toString().slice(6, Date.now().toString().length);
    return epoch;
  }

  useEffect(() => {
    if (!userData.id || !authToken) {
      const randomID = generateID();
      setUserData((old) => ({ ...old, id: randomID }));
    }
    // eslint-disable-next-line
  }, []);

  const context = {
    //getters
    authToken,
    userData,
    chatUsername,
    chatAvatar,
    getUserID,
    //setters
    handleChatUsername,
    handleChatAvatar,
    handleToken,
    requestUserData,
    setUserData,
    //acrtions
    logOut,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
