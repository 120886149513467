import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollTrigger from '@terwanerik/scrolltrigger'
import Footer from "components/layouts/Footer/";
import Header from "components/layouts/Header/";

function ArtistManagers(props) {
  const { t } = useTranslation("landing");
  useEffect(()=>{
    const trigger = new ScrollTrigger({
      trigger: {
        // If the trigger should just work one time
        once: true,
        offset: {
          element: {
            x: 0,
            y: (trigger, rect, direction) => {
              return 0.1
            }
          },
          viewport: {
            x: 0,
            y: (trigger, frame, direction) => {
              return trigger.visible ? 0 : 0.1
            }
          }
        }
      }
    })
    trigger.add('[data-slideleft]')
           .add('[data-slideright]')
           .add('[data-slidebottom]')
  },[])

  return (
    <>
      <Helmet>
        <title>{t ("meta.artist_managers.title")}</title>
        <meta name="description" content={t ("meta.artist_managers.description")} />
        <meta property="og:title" content={t ("meta.artist_managers.title")} />
        <meta property="og:description" content={t ("meta.artist_managers.description")} />
      </Helmet>
      <Header stylePage={"is-gradient"} />
      <div className="c-landing c-whositfor">
        <section className="c-whositfor-hero is-artist">
          <div className="c-whositfor-hero__wrapper">
            <h5 className="c-landing__category">{t("artist_managers.category")}</h5>
            <h1 className="c-whositfor-hero__title ">
              {t("artist_managers.title")}
            </h1>
            <p className="c-home__text">{t("artist_managers.desc")}</p>
            <Link to="/request-demo" className="o-button--lg o-button--green">
              {t("main_cta")}
            </Link>
          </div>
          <div className="c-whositfor-hero__video">
            <video 
              muted 
              loop 
              autoPlay 
              playsInline
              poster="https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManager.jpg"
            >
              <source
                src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManager.webm"
                type="video/webm"
              />
              <source
                src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManager.mp4"
                type="video/mp4"
              />
              <source
                src="https://boletia-v2.s3.amazonaws.com/uploads/neerme/ArtistManager.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </section>
        <section className="c-whyneerme-benefits">
          <div className="c-whyneerme-benefits__wrapper" data-slidebottom>
            <h1 className="c-home__title">{t("artist_managers.benefits.title")}</h1>

            <ul className="c-whyneerme-benefits__list">
              <li>
                <span className="c-whyneerme-benefits__emoji" role="img" aria-label="megaphone">📣</span>
                <h4>{t("artist_managers.benefits.title1")}</h4>
                <p>{t("artist_managers.benefits.desc1")}</p>
              </li>
              <li>
                <span className="c-whyneerme-benefits__emoji" role="img" aria-label="raised hands">🙌</span>
                <h4>{t("artist_managers.benefits.title2")}</h4>
                <p>{t("artist_managers.benefits.desc2")}</p>
              </li>
              <li>
                <span className="c-whyneerme-benefits__emoji" role="img" aria-label="money">💰</span>
                <h4>{t("artist_managers.benefits.title3")}</h4>
                <p>{t("artist_managers.benefits.desc3")}</p>
              </li>
              <li>
                <span className="c-whyneerme-benefits__emoji" role="img" aria-label="fest">🎉</span>
                <h4>{t("artist_managers.benefits.title4")}</h4>
                <p>{t("artist_managers.benefits.desc4")}</p>
              </li>
            </ul>
            <p className="c-home__text c-whositfor__benefits">
              <strong>{t("artist_managers.block1")}</strong> 
              {t("artist_managers.block2")}
              <strong>{t("artist_managers.block3")}</strong>
              {t("artist_managers.block4")}
            </p>
            <p className="c-home__text c-whositfor__benefits">
              {t("artist_managers.block5")}
            </p>
          </div>
        </section>

        <section className="c-whositfor__block">
          <div className="c-whositfor__block-wrapper" data-slidebottom>
            <span className="c-whositfor__block-emoji" role="img" aria-label="ticket">
              🎟 
            </span>
            <h1>
              {t("artist_managers.benefits.text1")}
              <strong> 250,000 </strong>
              {t("artist_managers.benefits.text2")}
            </h1>
            <br />
          </div>
        </section>
        
        <section className="c-landing-magnet c-whyneerme-magnet">
          <div className="c-landing-magnet__wrapper" data-slideInBottom>
            <h2 className="c-landing-magnet__title">{t("magnet.title")}</h2>
            <p className="c-home__text">{t("magnet.desc")}</p>
            <Link to="/request-demo" className="o-button--green o-button--lg">
              {t("main_cta")}
            </Link>
          </div>  
        </section>
      </div>
      <Footer />
    </>
  );
}

export default ArtistManagers;
