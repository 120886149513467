import React, { useEffect, useRef } from "react";

import { Helmet } from "react-helmet";
import axios from "axios";

import useQuery from "hooks/useQuery";
import useMultiScripts from "hooks/useMultiScripts";

import MuteAlert from "components/shared/MuteAlert";
// import isMobile from "utils/isMobile";

import CustomOverlay from "../PlyrPlayer/CustomOverlay";

const flowScripts = [
  // main player
  "//cdn.flowplayer.com/releases/native/stable/flowplayer.min.js",
  //plugins
  "//cdn.flowplayer.com/releases/native/stable/plugins/hls.min.js", // hls support
  // "//cdn.flowplayer.com/releases/native/stable/plugins/dash.min.js", //dash support
  "//cdn.flowplayer.com/releases/native/stable/plugins/qsel.min.js", // quality selection
  "//cdn.flowplayer.com/releases/native/stable/plugins/chromecast.min.js", // chrome cast
  "//cdn.flowplayer.com/releases/native/stable/plugins/airplay.min.js", // air play
  // "//cdn.flowplayer.com/releases/native/stable/plugins/message.min.js", // message
];

const waitScreen =
  "https://boletia-v2.s3.amazonaws.com/uploads/neerme/WaitingScreenHLS/WaitingScreenPlaylist.m3u8";

function FlowPlayer(props) {
  const { banner, src } = props;
  // const src =
  //   "https://d1ygcsmzrx9s2z.cloudfront.net/out/v1/2f6d34efc9e04885b025493a6ca08507/index.m3u8";

  const videoNode = useRef();
  const videoSource = useRef();
  const query = useQuery();
  const debug = query.get("debug");

  // eslint-disable-next-line
  const [loaded, scriptsLoadded] = useMultiScripts(flowScripts);

  function testm3u8() {
    return axios.get(src);
  }

  useEffect(() => {
    if (loaded) {
      const videoElement = videoNode.current;

      // eslint-disable-next-line
      flowplayer(function (config, root, video) {
        // eslint-disable-next-line
        video.on(flowplayer.events.MOUNT, function () {
          const header = root.ui.header;
          const controls = root.controls;

          setInterval(() => {
            if (videoSource.current === src) return;
            //===waitScreen
            testm3u8()
              .then((response) => {
                videoSource.current = src;
                video.setSrc(src);
              })
              .catch((error) => {
                if (videoSource.current === waitScreen) return;
                videoSource.current = waitScreen;
                video.setSrc(waitScreen);
              });
          }, 1000);

          header &&
            controls &&
            [].forEach.call(header.children, function (child) {
              controls.append(child);
            });
        });
      });

      // eslint-disable-next-line
      const flowplayerAPI = flowplayer(videoElement, {
        loop: true,
        autoplay: true,
        live: true,
        auto_orient: true,
        poster: banner,
        token:
          "eyJraWQiOiJYQUNiN2MwZVlhQTMiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NCxcImlkXCI6XCJYQUNiN2MwZVlhQTNcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.AkhldRbt4X5MRSKq6ZYg1016URGf-gMkSidPXm2PXoFdTsVtecr2-tSBXyVVA0nJ40WKhyBXR0K3tRPOBNVkzw",
      });

      // eslint-disable-next-line
      flowplayerAPI.on(flowplayer.events.ERROR, function (ev) {
        // eslint-disable-next-line
        flowplayerAPI.emit(flowplayer.events.RECOVER);
        setTimeout(function () {
          flowplayerAPI.setSrc(src);
          flowplayerAPI.load();
        }, 3000);
      });

      flowplayerAPI.on("ended", function (err) {
        console.info(err);
      });

      if (debug === "true") {
        window.flowAPI = flowplayerAPI;
      }
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [loaded]);
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="//cdn.flowplayer.com/releases/native/stable/style/flowplayer.css"
        />
      </Helmet>
      <MuteAlert />
      <div ref={videoNode}>
        <CustomOverlay />
      </div>
    </>
  );
}

export default FlowPlayer;
