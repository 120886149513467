import React, { useEffect, useRef } from "react";

import axios from "axios";
import videojs from "video.js";
import "videojs-hls-quality-selector";
import "videojs-contrib-quality-levels";
import chromeCastPlugin from "@silvermine/videojs-chromecast";

import { useParams } from "react-router-dom";

import isMobile from "utils/isMobile";
import { isChrome } from "utils/browser";
import MuteAlert from "components/shared/MuteAlert";
import CustomOverlay from "../PlyrPlayer/CustomOverlay";
import useScript from "hooks/useScript";

import "video.js/dist/video-js.css";
import "@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css";

const waitScreen =
  "https://boletia-v2.s3.amazonaws.com/uploads/neerme/WaitingScreenHLS/WaitingScreenPlaylist.m3u8";

const chromeCastJs =
  "https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1";

chromeCastPlugin(videojs);

const customEvent = ["purple-carpet-la-cotorrisa"];

function VideoJsPlayer(props) {
  const { src } = props;

  const { eventSubdomain } = useParams();
  const isCustomSub = customEvent.includes(eventSubdomain);

  const videoNode = useRef();
  const videoJSInit = useRef(null);
  const shouldDisposeVideojs = useRef(false);
  const curatedSrc = useRef(src);
  const checkerInterval = useRef();
  // eslint-disable-next-line
  const [loadedChrome, loadedChromeError] = useScript(chromeCastJs);

  function testm3u8() {
    const epoch = Date.now();
    return new Promise((resolve, reject) => {
      axios
        .get(curatedSrc.current)
        .then(resolve)
        .catch((err) => {
          curatedSrc.current = `${src}?v=${epoch}`;
          reject();
        });
    });
  }

  function setIntervalChecker() {
    checkerInterval.current = setInterval(() => {
      testm3u8()
        .then(() => {
          videoNode.current.src = curatedSrc.current;
          videoNode.current.loop = false;
          clearIntervalChecker();
        })
        .catch((err) => {});
    }, 7000);
  }

  function clearIntervalChecker() {
    clearInterval(checkerInterval.current);
  }

  function initializePlayer(settings) {
    try {
      videoJSInit.current = videojs(videoNode.current, settings);
    } catch (error) {
      shouldDisposeVideojs.current = false;
      return shouldDisposeVideojs.current;
    }
    videoJSInit.current.hlsQualitySelector({ displayCurrentQuality: true });
    if (videoJSInit.current.paused()) {
      videoJSInit.current.play().catch((error) => {
        new Promise((resolve, reject) => {
          videoJSInit.current.muted(true);
          resolve();
        }).then(() => {
          videoJSInit.current.play().catch(console.log);
        });
      });
    }
    // window.apivideojs = player;
    return shouldDisposeVideojs.current;
  }

  useEffect(() => {
    if (!loadedChrome) return;

    const settings = {
      techOrder: ["chromecast", "html5"], // You may have more Tech, such as Flash or HLS
      plugins: {
        chromecast: {},
      },
      autoplay: true,
      preload: "auto",
      controls: true,
      responsive: true,
      muted: isMobile,
      fluid: true,
      liveui: true,
      controlBar: {
        liveDisplay: false,
        remainingTimeDisplay: false,
        pictureInPictureToggle: false,
        captionsButton: false,
        durationDisplay: false,
        currentTimeDisplay: false,
      },
    };
    if (isChrome) {
      settings.html5 = {
        hls: {
          overrideNative: true,
        },
        nativeAudioTracks: false,
        nativeVideoTracks: false,
      };
    }

    testm3u8()
      .then((respone) => {
        settings.sources = {
          src: curatedSrc.current,
          type: "application/x-mpegURL",
        };
        initializePlayer(settings);
      })
      .catch((err) => {
        settings.sources = {
          src: waitScreen,
          type: "application/x-mpegURL",
        };
        initializePlayer(settings);
        if (shouldDisposeVideojs.current) {
          videoNode.current.loop = true;
          setIntervalChecker();
        }
      });

    return () => {
      if (shouldDisposeVideojs.current) {
        videoJSInit.current.dispose();
      }
      clearIntervalChecker();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedChrome]);

  useEffect(() => {
    function oriantationChanged(event) {
      if (window.orientation === 90) {
        try {
          videoJSInit.current.requestFullscreen();
        } catch (error) {
          console.log(error);
        }
      }
    }
    window.addEventListener("orientationchange", oriantationChanged, false);
    return () => {
      window.removeEventListener("orientationchange", oriantationChanged);
    };
  }, []);

  return (
    <div className="video-js__wrapper">
      <MuteAlert />
      <div data-vjs-player>
        {!isCustomSub && <CustomOverlay />}
        <video playsInline ref={videoNode} className="video-js"></video>
      </div>
    </div>
  );
}

export default VideoJsPlayer;
