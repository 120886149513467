import {
  VOTE_POLL_REQUESTED,
  VOTE_POLL_FULFILLED,
  VOTE_POLL_REJECTED,
} from "./types";

import { votePoll as votePollAPI } from "api/polls";

export function votePoll({ pollID, payload }, dispatch) {
  dispatch(votePollRequested());
  votePollAPI(pollID, payload)
    .then((response) => {
      const data = response.data;
      dispatch(votePollFulfilled(data));
    })
    .catch((error) => {
      dispatch(votePollRejected(error));
    });
}

function votePollRequested() {
  return {
    type: VOTE_POLL_REQUESTED,
    votePoll: {
      loading: true,
      success: false,
      error: false,
      data: [],
    },
  };
}
function votePollFulfilled(data) {
  return {
    type: VOTE_POLL_FULFILLED,
    votePoll: {
      loading: false,
      success: true,
      error: false,
      data: data,
    },
  };
}
function votePollRejected(error) {
  return {
    type: VOTE_POLL_REJECTED,
    votePoll: {
      loading: false,
      success: false,
      error,
    },
  };
}
