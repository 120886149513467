import React from "react";

import { isObject } from "utils/dataStructures";

function AvatarList(props) {
  const { properties } = props;

  const avatars = properties?.avatars;
  return (
    <ul className="c-upgrade__list is-avatar">
      {isObject(avatars) &&
        Object.keys(avatars).map((avatar, idx) => (
          <li className="c-avatar" key={idx}>
            <img src={avatars[avatar]} alt={avatar} />
          </li>
        ))}
    </ul>
  );
}

export default AvatarList;
